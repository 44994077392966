import FieldTypesModel from '#model/FieldTypesModel'
import ItemTypesModel from '#model/ItemTypesModel'
import ItemTypeFieldsModel from '#model/ItemTypeFieldsModel'
import ItemTypeFieldValidatorsModel from '#model/ItemTypeFieldValidatorsModel'
import { useApiClient } from '#context/ApiContext'
import { useNotifications } from '#context/NotificationContext'
import { useInstanceOf } from 'eilmer-mvvm/react'

export default class ItemTypeFieldsViewModel {

  constructor(apiClient, notificationsProvider, itemTypeId) {
    this.model = new ItemTypeFieldsModel(apiClient)
    this.fieldTypesModel = new FieldTypesModel(apiClient)
    this.itemTypesModel = new ItemTypesModel(apiClient)
    this.validatorsModel = new ItemTypeFieldValidatorsModel(apiClient)
    this.notifications = notificationsProvider
    this.itemTypeId = Number(itemTypeId)
    this.entity = new ItemTypeFieldsModel.ItemTypeField()
    this.editing = false
    this.loadEntities()
  }

  async loadEntities() {
    if (this.itemTypeId) {
      try {
        this.entities = await this.model.getItemTypeFields(this.itemTypeId)
        this.itemType = await this.itemTypesModel.getItemType(this.itemTypeId)
      } catch (err) {
        console.error(err)
        this.notifications.error(err.message)
      }
    }
  }

  async getFieldType(entity) {
    if (entity) {
      return await this.fieldTypesModel.getFieldType(entity.field_type_id)
    }
  }

  doEditEntity(entity) {
    const editingEntity = entity || {item_type_id: this.itemTypeId, required: 1}
    this.entity = new ItemTypeFieldsModel.ItemTypeField(editingEntity)
    this.editing = true
  }

  doCancelEdit() {
    this.editing = false
  }

  async doSaveEntity(entity) {
    try {
      if (entity.isNew()) {
        const result = await this.model.createItemTypeField(entity)
        this.editing = false
        this.loadEntities()
        return result
      } else {
        const result = await this.model.updateItemTypeField(entity)
        this.editing = false
        this.loadEntities()
        return result
      }
    } catch (err) {
      console.error(err)
      this.notifications.error(err.message)
    }
  }

  async doDeleteEntity(entity) {
    if (await this.notifications.confirm('Are you sure you\'d like to delete this field?')) {
      try {
        await Promise.all(entity.item_type_field_validators.map(async (each) => {
          await this.validatorsModel.deleteItemTypeFieldValidator(each)
        }));
        const result = await this.model.deleteItemTypeField(entity)
        this.loadEntities()
        return result
      } catch (err) {
        console.error(err)
        this.notifications.error(err.message)
      }
    }
  }

  get fields() {
    return [
      { key: 'item_type_field_id', label: 'ID', _style: { width: '6%'}, _classes: 'bg-light text-dark' },
      { key: 'sequence', label: 'Seq', _style: { width: '5%'} },
      { key: 'field_type', label: 'Field Type', _style: { width: '10%'} },
      { key: 'name', _style: { width: '20%'} },
      { key: 'description' },
      { key: 'key_name', label: 'Key', _style: { width: '10%'} },
      { key: 'required', _style: { width: '5%'} },
      { key: 'private', _style: { width: '5%'} },
      {
        key: 'actions',
        label: '',
        _style: { width: '90px' },
        filter: false
      }
    ]
  }

}

ItemTypeFieldsViewModel.useInstance = function(itemTypeId) {
  return useInstanceOf(ItemTypeFieldsViewModel, useApiClient(), useNotifications(), itemTypeId)
}
