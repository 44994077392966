import { Converter } from 'eilmer-mvvm/converters'

export default class UserRolesConverter extends Converter {

    constructor(permissions) {
      super()
      this.permissions = permissions
    }
  
    convertFrom(viewModelValue, bindingContext) {
      return viewModelValue?.filter(userRole => this.permissions?.hasPrecedence(userRole, true) || false)
    }
  
  }