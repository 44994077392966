import React from 'react'

export const useLocalState = (key, defaultValue) => {
    const [ state, setState ] = React.useState(() => (
      localStorage.getItem(key) || defaultValue
    ))
    
    return [ state, (value) => {
      localStorage.setItem(key, value)
      setState(value)
    }]
}