import FieldTypesModel from '#model/FieldTypesModel'
import ItemTypesModel from '#model/ItemTypesModel'
import UserFieldsModel from '#model/UserFieldsModel'
import UserFieldValidatorsModel from '#model/UserFieldValidatorsModel'
import { useApiClient } from '#context/ApiContext'
import { useNotifications } from '#context/NotificationContext'
import { useInstanceOf } from 'eilmer-mvvm/react'

export default class UserFieldsViewModel {

  constructor(apiClient, notificationsProvider) {
    this.model = new UserFieldsModel(apiClient)
    this.fieldTypesModel = new FieldTypesModel(apiClient)
    this.validatorsModel = new UserFieldValidatorsModel(apiClient)
    this.notifications = notificationsProvider
    this.entity = new UserFieldsModel.UserField()
    this.editing = false
    this.loadEntities()
  }

  async loadEntities() {
    try {
      this.entities = await this.model.getUserFields()
    } catch (err) {
      console.error(err)
      this.notifications.error(err.message)
    }
  }

  async getFieldType(entity) {
    if (entity) {
      return await this.fieldTypesModel.getFieldType(entity.field_type_id)
    }
  }

  doEditEntity(entity) {
    const editingEntity = entity || {required: 1}
    this.entity = new UserFieldsModel.UserField(editingEntity)
    this.editing = true
  }

  doCancelEdit() {
    this.editing = false
  }

  async doSaveEntity(entity) {
    try {
      if (entity.isNew()) {
        const result = await this.model.createUserField(entity)
        this.editing = false
        this.loadEntities()
        return result
      } else {
        const result = await this.model.updateUserField(entity)
        this.editing = false
        this.loadEntities()
        return result
      }
    } catch (err) {
      console.error(err)
      this.notifications.error(err.message)
    }
  }

  async doDeleteEntity(entity) {
    if (await this.notifications.confirm('Are you sure you\'d like to delete this item?')) {
      try {
        await Promise.all(entity.user_field_validators.map(async (each) => {
          await this.validatorsModel.deleteUserFieldValidator(each)
        }));
        const result = await this.model.deleteUserField(entity)
        this.loadEntities()
        return result
      } catch (err) {
        console.error(err)
        this.notifications.error(err.message)
      }
    }
  }

  get fields() {
    return [
      { key: 'user_field_id', label: 'ID', _style: { width: '6%'}, _classes: 'bg-light text-dark' },
      { key: 'sequence', label: 'Seq', _style: { width: '5%'} },
      { key: 'field_type', label: 'Field Type', _style: { width: '15%'} },
      { key: 'name', _style: { width: '20%'} },
      { key: 'description' },
      { key: 'required', _style: { width: '5%'} },
      { key: 'private', _style: { width: '5%'} },
      {
        key: 'actions',
        label: '',
        _style: { width: '90px' },
        filter: false
      }
    ]
  }

}

UserFieldsViewModel.useInstance = function() {
  return useInstanceOf(UserFieldsViewModel, useApiClient(), useNotifications(), 10)
}
