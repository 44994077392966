import ItemsModel from '#model/ItemsModel'
import { useApiClient } from '#context/ApiContext'
import { useNotifications } from '#context/NotificationContext'
import { useInstanceOf } from 'eilmer-mvvm/react'
import { Converter } from 'eilmer-mvvm/converters'
import ItemTypes from '#utils/ItemTypes.js'

// This is the item type field for the file content
const MEDIA_ITEM_FILE_FIELD_ID = 14

export default class MediaViewModel {

  constructor(apiClient, notificationsProvider, websiteId, loadMedia) {
    this.model = new ItemsModel(apiClient, true)
    this.notifications = notificationsProvider
    this.mediaItemConverter = new MediaItemConverter()
    this.mediaFolderConverter = new MediaFolderConverter(this.mediaItemConverter)
    this.mediaConverter = new MediaConverter(this.mediaFolderConverter)
    this.websiteId = websiteId
    this.media = null
    if (loadMedia) {
      this.doLoadMedia()
    }
  }

  async doLoadMedia() {
    try {
      const media = await this.model.getItems(ItemTypes.MEDIA_FOLDER, this.websiteId)
      this.media = this.mediaConverter.convertFrom(media)
      return this.media
    } catch (err) {
      console.error(err)
      this.notifications.error(err.message)
    }
  }

  async doCreateMediaObject(mediaFolder, mediaObject) {
    const item = new ItemsModel.Item(this.mediaItemConverter.convertTo(mediaObject))
    Object.assign(item, { website_id: this.websiteId, parent_item_id: mediaFolder.folderId})
    try {
      await this.model.createItem(item)
      await this.doLoadMedia()
    } catch (err) {
      console.error(err)
      this.notifications.error(err.message)
    }
    return this.media
  }

  async doDeleteMediaObject(mediaObject) {
    const item = new ItemsModel.Item(this.mediaItemConverter.convertTo(mediaObject))
    try {
      await this.model.deleteItem(item)
      await this.doLoadMedia()
    } catch (err) {
      console.error(err)
      this.notifications.error(err.message)
    }
    return this.media
  }

  async doSaveMediaObjects(mediaObjects) {
    try {
      for (let i = 0; i < mediaObjects.length; i++) {
        const mediaObject = mediaObjects[i]
        const item = new ItemsModel.Item(this.mediaItemConverter.convertTo(mediaObject))
        if (item.sequence !== i) {
          item.sequence = i
          await this.model.updateItem(item)
        }
      }
    } catch (err) {
      console.error(err)
      this.notifications.error(err.message)
    }
  }

  async getMediaObject(mediaItemId) {
    const mediaItem = await this.model.getItem(mediaItemId)
    return this.mediaItemConverter.convertFrom(mediaItem)
  }

}

class MediaConverter extends Converter {

  constructor(mediaFolderConverter) {
    super()
    this.mediaFolderConverter = mediaFolderConverter
  }

  convertFrom(viewModelValue) {
    return viewModelValue?.map(each => {
      return this.mediaFolderConverter.convertFrom(each)
    })
  }

  convertTo(viewValue) {
    return viewValue.map(each => {
      return this.mediaFolderConverter.convertTo(each)
    })
  }
}

class MediaFolderConverter extends Converter {

  constructor(mediaItemConverter) {
    super()
    this.mediaItemConverter = mediaItemConverter
  }

  convertFrom(viewModelValue) {
    viewModelValue.content_items.sort((a, b) => {
      if (a.sequence === b.sequence) {
        return b.item_id - a.item_id;
      }
      return a.sequence - b.sequence;
    })
    return {
      record: viewModelValue,
      folderId: viewModelValue.item_id,
      folderName: viewModelValue.name,
      // folderIcon: 'image',
      mediaObjects: viewModelValue.content_items.map(each => {
        return this.mediaItemConverter.convertFrom(each)
      })
    }
  }

  convertTo(viewValue) {
    // return viewValue
    throw new Error('Conversion from MediaFolder not implemented')
  }
}

class MediaItemConverter extends Converter {

  convertFrom(viewModelValue) {
    try {
      const contentItemValue = viewModelValue.content_item_values[0]
      const mediaObject = JSON.parse(contentItemValue.json_value)
      mediaObject.record = viewModelValue
      mediaObject.name = viewModelValue.name || mediaObject.name
      mediaObject.url = `/filevalue/${contentItemValue.value_id}`
      mediaObject.id = viewModelValue.item_id
      return mediaObject
    } catch (err) {
      console.error('Error with media library item:', viewModelValue)
      throw err;
    }
  }

  convertTo(viewValue) {
    const contentItem = viewValue.record || {}
    contentItem.item_type_id = ItemTypes.MEDIA_ITEM
    contentItem.name = viewValue.name || contentItem.name
    contentItem.content_item_values = contentItem.content_item_values || [{}]
    contentItem.content_item_values[0].field_id = MEDIA_ITEM_FILE_FIELD_ID
    contentItem.content_item_values[0].text_value = viewValue.contentType
    if (viewValue.content) {
      contentItem.content_item_values[0].base64_value = viewValue.content
    }
    const json = JSON.parse(contentItem.content_item_values[0].json_value || '{}')
    contentItem.content_item_values[0].json_value = JSON.stringify({
      size: json.size || viewValue.size,
      filename: json.name || viewValue.name,
      contentType: json.contentType || viewValue.contentType,
      imageWidth: json.imageWidth || viewValue.imageWidth,
      imageHeight: json.imageHeight || viewValue.imageHeight,
    })
    return contentItem
  }
}

MediaViewModel.useInstance = function(websiteId, loadMedia) {
  return useInstanceOf(MediaViewModel, useApiClient(), useNotifications(), websiteId, loadMedia)
}
