import UsersModel from '#model/UsersModel'
import UserRolePermissionsModel from '#model/UserRolePermissionsModel'

import { useApiClient } from '#context/ApiContext'
import { useNotifications } from '#context/NotificationContext'
import { useInstanceOf } from 'eilmer-mvvm/react'

import Privileges from '#primitives/Privileges'

export default class AuthViewModel {

  constructor(apiClient, notificationsProvider) {
    this.apiClient = apiClient
    this.usersModel = new UsersModel(apiClient)
    this.permissionsModel = new UserRolePermissionsModel(apiClient)
    this.notifications = notificationsProvider
    this.credentials = {emailAddress: '', password: ''}
    this.setUser(JSON.parse(sessionStorage.getItem('user')))
  }

  async setUser(user) {
    if (user?.id) {
      this.user = await this.usersModel.getUser(user.id)
      this.setPermissions(this.user.getUserRole())
    }
  }

  async doLogin() {
    try {
      const password = this.credentials.password
      const username = this.credentials.emailAddress
      let user = { username, id: 2, user_role_id: 0 }
      if (process.env.NODE_ENV !== 'development') {
        user = await this.apiClient.login(username, password)
      }
      if (user) {
        sessionStorage.setItem('user', JSON.stringify(user))
        this.setUser(user)
      } else {
        this.errorMessage = 'User not found or password incorrect'
      }
    } catch (err) {
      if (err.code === 1012) {
        this.errorMessage = 'User not found or password incorrect'
      } else {
        console.error(err)
        this.notifications.error(err.message)
      }
    }
  }

  async doLogout() {
    sessionStorage.removeItem('user')
    try {
      await this.apiClient.logout()
    } finally {
      this.user = null
      this.setPermissions(null)
    }
  }

  async setPermissions(userRole) {
    let permissions = []
    if (userRole) {
      permissions = await this.permissionsModel.getUserRolePermissions(userRole.user_role_id)
    }
    this.permissions = new Permissions(userRole, permissions)
  }
}

AuthViewModel.useInstance = function() {
  return useInstanceOf(AuthViewModel, useApiClient(), useNotifications())
}

class Permissions {

  constructor(userRole, permissions) {
    this.userRole = userRole
    this.permissions = permissions
  }

  hasPrivilege(privilegeId, itemType = null) {
    const permission = this.getPermission(privilegeId, itemType)
    return typeof permission !== 'undefined'
  }

  hasPrecedence(userRole, orEqual = false) {
    if (this.userRole.precedence === 0) {
      return true
    } else if (userRole && orEqual) {
      return userRole && this.userRole.precedence <= userRole.precedence
    } else if (userRole && !orEqual) {
      return userRole && this.userRole.precedence < userRole.precedence
    }
    return false
  }

  getPermission(privilegeId, itemType = null) {
    if (!itemType?.getUserRole() || this.hasPrecedence(itemType?.getUserRole(), true)) {
      const permission = this.permissions?.find(each => {
        if (each['privilege_id'] === Privileges.ALL) {
          return true
        }
        if (each['privilege_id'] === Number(privilegeId)) {
          if (itemType === null || each['item_type_id'] === null) {
            return true
          } else if (each['item_type_id'] === Number(itemType.item_type_id)) {
            return true
          } else if (each['item_type_id'] === Number(itemType.parent_type_id)) {
            // TODO: This needs to support a recursive heirrachy BUT 
            // this would need to be async, which will probably screw up
            // how I am using it in the view code so needs thinking about.
            return true
          }
        }
        return false
      })
      return permission
    }
  }
}
