import React from 'react'
import { useProperty, useBinding, useInstanceOf } from 'eilmer-mvvm/react'

import * as Core from '@coreui/react'
import CIcon from '@coreui/icons-react'

import * as Layout from '#components/misc/DynamicLayout'
import * as Forms from '#components/widgets/Forms'
import * as Items from '#components/widgets/Items'

import { useAppViewModel } from '#context/AppContext'

import MenusViewModel from '#viewmodel/MenusViewModel'
import MenuItemsConverter from '#converters/MenuItemsConverter'

export default function Menus({ appViewModel }) {
  appViewModel.breadcrumb = 'Website / Menus'
  const viewModel = MenusViewModel.useInstance()
  const [ selectedMenu, setSelectedMenu ] = useBinding(viewModel, 'selectedMenu')
  const menus = useProperty(viewModel, 'menus')

  const selectMenu = async (menu) => {
    // TODO: AppViewModel.confirmUnsavedChanges() IS NOT IMPLEMENTED YET
    // if (await appViewModel.confirmUnsavedChanges()) {
      setSelectedMenu(menu)
    // }
  }

  /*
    Ok, so this *is* a good point as a carry over from the Listawood
    menu editor: when there is a lot of menu items, loading all of the
    related items (ie pages) for the form for every menu item carries
    a lot of overhead, and so there, I use a MenuContext object that
    stores a single viewmodel with the list of pages and other selectable
    things. But if I want do use a generic field editor then that's going
    to break other places where I don't have or want that context, so
    the solution may be a lot more difficult.

    For now, I'm going to ignore it just to get the base functionality in
    place - this is just a reminder that it needs consideration sooner or
    later....
  */

  return (
    <Core.CRow style={{display:'flex',flex:1}}>
      <Core.CCol md="4">
        <Core.CCard>
          <Core.CCardHeader>
            Menus
          </Core.CCardHeader>
          <Core.CCardBody className="p-0">
            <Core.CListGroup flush>
              {menus?.map(each =>
                <Core.CListGroupItem key={each.item_id} href="#" color={selectedMenu === each ? 'primary' : 'light'} onClick={() => selectMenu(each)}>
                  {each.name}
                </Core.CListGroupItem>
              )}
            </Core.CListGroup>
          </Core.CCardBody>
        </Core.CCard>
      </Core.CCol>
      <Core.CCol md="8" style={{display:'flex',flex:1}}>
        <MenuEditor viewModel={viewModel}/>
      </Core.CCol>
    </Core.CRow>
  )
}

function MenuEditor({ viewModel }) {
  const ref = React.useRef()
  const appViewModel = useAppViewModel()
  const menu = useProperty(viewModel, 'selectedMenu')
  const converter = useInstanceOf(MenuItemsConverter, menu)
  const menuItems = useProperty(viewModel, 'menuItems', converter)
  const subMenuSupported = useProperty(viewModel, 'subMenuSupported')

  const addMenu = () => {
    const item = viewModel.newMenu(menu)
    ref.current.addItem(item)
  }

  const addMenuItem = () => {
    const item = viewModel.newMenuItem(menu)
    ref.current.addItem(item)
  }

  const saveMenuItems = async () => {
    const menuItems = ref.current.getItems()
    await viewModel.doSaveMenuItems(menuItems)
    appViewModel.unsavedChanges = false
  }

  const onLayoutChange = () => {
    appViewModel.unsavedChanges = true
  }

  return (
    <Forms.ValidatingForm onSubmit={saveMenuItems} style={{display:'flex',flex:1}}>
      <Core.CCard style={{display:'flex',flex:1}}>
        <Core.CCardHeader>
          {menu && menu.name}
          <div className="card-header-actions">
            {subMenuSupported && <Core.CButton color="primary" size="sm" className="mr-2" onClick={addMenu}>Add Sub Menu</Core.CButton>}
            <Core.CButton color="primary" size="sm" onClick={addMenuItem}>Add Menu Item</Core.CButton>
          </div>
        </Core.CCardHeader>
        <Core.CCardBody style={{display:'flex',flex:1}}>
          <div style={{position:'relative', flex: 1}}>
            <div style={{position: 'absolute', padding: '20px', top: 0, bottom: 0, left: 0, overflowY: 'auto', overflowX: 'hidden', width: '100%'}}>
              <Layout.DynamicLayout ref={ref} cols={20} defaultItems={menuItems} itemWidth={subMenuSupported ? 19 : 20} itemHeight={62} itemComponent={MenuItem} onChange={onLayoutChange}/>
            </div>
          </div>
        </Core.CCardBody>
        <Core.CCardFooter>
          <MenuEditorControls appViewModel={appViewModel}/>
        </Core.CCardFooter>
      </Core.CCard>
    </Forms.ValidatingForm>
  )
}

const MenuItem = function({ item, removeItemHandler, updateItemHandler }) {
  const appViewModel = useAppViewModel()
  const name = useProperty(item, 'name')
  const description = useProperty(item, 'description')

  const handleChanged = () => {
    appViewModel.unsavedChanges = true
  }

  return (
    <Layout.CollapsibleGridCard colour="primary" item={item} updateItem={updateItemHandler} title={name} subtitle={description}>
      <Items.ItemEditor item={item} changedHandler={handleChanged}/>
      <hr/>
      <Core.CButton color="danger" size="sm" onClick={removeItemHandler}>
        Delete
      </Core.CButton>
    </Layout.CollapsibleGridCard>
  )
}

function MenuEditorControls({ appViewModel }) {
  const changed = useProperty(appViewModel, 'unsavedChanges')

  return (
    <Core.CButton disabled={!changed} type="submit" color="success"><CIcon name="cil-save"/> Save Changes</Core.CButton>
  )
}
