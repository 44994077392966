import React from 'react'
import AuthViewModel from '#viewmodel/AuthViewModel'

const AuthContext = React.createContext()

function AuthProvider({ children }) {
  const viewmodel = AuthViewModel.useInstance()

  return (
    <AuthContext.Provider value={viewmodel}>
      {children}
    </AuthContext.Provider>
  )
}

export function useAuthContext() {
  return React.useContext(AuthContext)
}

export default AuthProvider
