import { useNavigate } from "react-router-dom";

import ItemsModel from '#model/ItemsModel'
import ItemTypesModel from '#model/ItemTypesModel'
import ItemTypeFieldsModel from '#model/ItemTypeFieldsModel'
import { useApiClient } from '#context/ApiContext'
import { useWebsiteId } from '#context/AppContext'
import { useAuthContext } from '#context/AuthContext'
import { useNotifications } from '#context/NotificationContext'
import { useInstanceOf } from 'eilmer-mvvm/react'
// import { Converter } from 'eilmer-mvvm/converters'

export default class ItemsViewModel {

  constructor(apiClient, authViewModel, notificationsProvider, navigate, websiteId, itemTypeId, parentItemId, showSummaryFields) {
    if (!websiteId) console.error(new Error('No website defined'))
    this.model = new ItemsModel(apiClient)
    this.itemTypesModel = new ItemTypesModel(apiClient)
    this.fieldsModel = new ItemTypeFieldsModel(apiClient)
    this.notifications = notificationsProvider
    this.fields = this.dynamicfields
    this.itemTypeId = itemTypeId
    this.parentItemId = parentItemId
    this.showSummaryFields = showSummaryFields
    this.navigate = navigate
    this.websiteId = websiteId
    this.auth = authViewModel
    this.item = new ItemsModel.Item()
    this.editing = false
    this.loadItems()
  }

  async loadItems() {
    try {
      this.itemType = await this.itemTypesModel.getItemType(this.itemTypeId)
      this.itemTypeFields = await this.fieldsModel.getItemTypeFields(this.itemTypeId)
      this.items = await this.model.getItems(this.itemTypeId, this.websiteId, this.parentItemId)
      this.parentItem = this.parentItemId !== null ? await this.model.getItem(this.parentItemId) : undefined
      this.fields = this.dynamicfields
    } catch (err) {
      console.error(err)
      this.notifications.error(err.message)
    }
  }

  async getItem(itemId) {
    try {
      return await this.model.getItem(itemId)
    } catch (err) {
      console.error(err)
      this.notifications.error(err.message)
    }
  }

  doEditItem(item) {
    const editingItem = item || {website_id: this.websiteId, item_type_id: this.itemTypeId, parent_item_id: this.parentItemId, created_user_id: this.auth.user.user_id}
    // We need to deep-copy the item to be edited as the field values are nested
    this.item = new ItemsModel.Item(JSON.parse(JSON.stringify(editingItem)))
    this.editing = true
  }

  doCancelEdit() {
    this.editing = false
  }

  async doSaveItem(item, edit) {
    try {
      let result = null
      if (item.isNew()) {
        result = await this.model.createItem(item)
      } else {
        result = await this.model.updateItem(item)
      }
      if (edit) {
        this.navigate(`/items/${this.itemType.getPk()}/${result}`)
        return result
      } else {
        this.editing = false
        this.loadItems()
        return result
      }
    } catch (err) {
      console.error(err)
      this.notifications.error(err.message)
    }
  }

  async doDeleteItem(item) {
    if (await this.notifications.confirm('Are you sure you\'d like to delete this item?')) {
      try {
        const result = await this.model.deleteItem(item)
        this.loadItems()
        return result
      } catch (err) {
        console.error(err)
        this.notifications.error(err.message)
      }
    }
  }

  get dynamicfields() {
    const fields = [], defaults = JSON.parse(this.itemType?.summary_fields || '[]')
    fields.push({key: 'item_id', label: 'ID', _style: {width: '6%'}, _classes: 'bg-light text-dark'})
    if (defaults.length === 0 || defaults.includes('name')) {
      fields.push({key: 'name', _style: { width: '20%'}})
    }
    if (defaults.includes('created_date')) {
      fields.push({key: 'created_date', _style: { width: '20%'}})
    }
    if (defaults.includes('description')) {
      fields.push({key: 'description'})
    }    
    if (this.showSummaryFields) {
      this.itemTypeFields?.forEach(each => {
        if (each.summary) {
          fields.push({key: each.name})
        }
      })
    }
    if (defaults.length === 0 && fields.length < 4) {
      fields.push({key: 'description'})
    }
    fields.push({
      key: 'actions',
      label: '',
      _style: {width: '120px'},
      filter: false
    })
    return fields
  }

}

ItemsViewModel.useInstance = function(itemTypeId, parentItemId = undefined, showSummaryFields = true) {
  return useInstanceOf(ItemsViewModel, useApiClient(), useAuthContext(), useNotifications(), useNavigate(), useWebsiteId(), itemTypeId, parentItemId, showSummaryFields)
}
