import TreeQLModel from './TreeQLModel'

export default class ContentBlocksModel extends TreeQLModel {

  constructor(apiClient) {
    super(apiClient)
  }

  async getContentBlocks(websiteId) {
    const options = {filter:'website_id,eq,' + websiteId,join:['blocks', 'websites']}
    return (await super.list('content_blocks', options)).map(each => {
      each.websites = [each.website_id]
      each.website_id = each.websites[0]?.website_id
      each.blocks = [each.block_id]
      each.block_id = each.blocks[0]?.block_id
      return new ContentBlocksModel.ContentBlock(each)
    })
  }

  async createContentBlock(contentBlock) {
    const entity = new ContentBlocksModel.ContentBlock(contentBlock)
    return await super.create('content_blocks', entity)
  }

  async updateContentBlock(contentBlock) {
    const entity = new ContentBlocksModel.ContentBlock(contentBlock, true)
    return await super.update('content_blocks', entity.getPk(), entity)
  }

  async deleteContentBlock(contentBlock) {
    const entity = new ContentBlocksModel.ContentBlock(contentBlock)
    return await super.delete('content_blocks', entity.getPk())
  }

}

ContentBlocksModel.ContentBlock = class extends TreeQLModel.Entity {

  constructor(fields, forUpdate) {
    super(fields, 'content_block_id', forUpdate)
    // this.block_id = this.block_id ? Number(this.block_id) : null
  }

}
