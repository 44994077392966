import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { useProperty, useBinding } from 'eilmer-mvvm/react'

import * as Core from '@coreui/react'
import CIcon from '@coreui/icons-react'

import ItemsViewModel from '#viewmodel/ItemsViewModel'
import * as Entities from '#components/widgets/Entities'
import * as Items from '#components/widgets/Items'

export default function ItemsPage({ appViewModel }) {
  const { item_type_id } = useParams()
  const viewModel = ItemsViewModel.useInstance(item_type_id, undefined, false)
  appViewModel.breadcrumb = 'Developer / Items'

  const handleAddItem = () => {
    viewModel.doEditItem(null)
  }

  return (
    <Core.CCard style={{display: 'flex', flex: '1 1 0%'}}>
      <Core.CCardHeader>
        <div className="card-header-actions">
          <Core.CButton color="primary" size="sm" onClick={() => handleAddItem()}>
            <CIcon name="cil-library-add"/>&nbsp;Add Item
          </Core.CButton>
        </div>
      </Core.CCardHeader>
      <Core.CCardBody>
        <ItemDataTable viewModel={viewModel}/>
      </Core.CCardBody>
      <Items.EditItemModal title="Add/Edit Item" viewModel={viewModel} useParentItem={false}/>
    </Core.CCard>
  )
}

function ItemDataTable({ viewModel }) {
  const items = useProperty(viewModel, 'items')
  const fields = useProperty(viewModel, 'fields')

  const handleEditItem = (item) => {
    viewModel.doEditItem(item)
  }

  const handleDeleteItem = (item) => {
    viewModel.doDeleteItem(item)
  }

  return (
    <Entities.DataTable entities={items} fields={fields} scopedSlots={{
      'actions':
        (item)=>(
          <td style={{textAlign:'right'}}>
            <a href="#" onClick={() => handleEditItem(item)}>
              <CIcon className="mr-2" title="Edit" name="cil-pencil"/>
            </a>
            <a href="#" onClick={() => handleDeleteItem(item)}>
              <CIcon title="Delete" name="cil-trash"/>
            </a>
          </td>
        ),
    }}/>
  )
}
