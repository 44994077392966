import { Converter } from 'eilmer-mvvm/converters'

export default class MenuItemsConverter extends Converter {

  constructor(menu) {
    super()
    this.menu = menu
  }
  
  convertFrom(viewModelValue, bindingContext) {
    if (Array.isArray(viewModelValue) && this.menu) {
      const levels = {[this.menu.getPk()]: 0}
      return viewModelValue?.map((each) => {
        if (each.item_type_id === 1) levels[each.getPk()] = levels[String(each.getParentPk())] + 1
        each.layout = {x: levels[String(each.getParentPk())]}
        return each
      })
    } else {
      return viewModelValue
    }
  }

  convertTo(viewValue, bindingContext) {
    throw new Error('Only one-way conversion supported')
  }
}
