export default class ItemTypes {

    static PAGE = 3

    static CONTENT_BLOCK = 4

    static MEDIA_FOLDER = 5

    static CONFIGURATION = 6

    static MEDIA_ITEM = 8
}