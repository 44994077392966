import React from 'react'
import { CContainer } from '@coreui/react'
import { useProperty } from 'eilmer-mvvm/react'

import { useAuthContext } from '#context/AuthContext'

import AdminSidebar from './AdminSidebar'
import AdminHeader from './AdminHeader'

// import AppProvider from '../../context/AppContext'

export default function AdminLayout({ mode, breadcrumbName, flex, children }) {
  const [ showing, setShowing ] = React.useState(true)
  const style = flex ? {display:'flex',flex:1,flexDirection:'column'} : {}
  const authViewModel = useAuthContext()
  const user = useProperty(authViewModel, 'user')

  // console.log(user)

  return (
    <>
        <AdminSidebar mode={mode}/>
        <div className="c-wrapper">
          <AdminHeader user={user} breadcrumbName={breadcrumbName}/>
          <div className="c-body">
            <main className="c-main" style={style}>
              <CContainer fluid style={style}>
                { children }
              </CContainer>
            </main>
          </div>
        </div>
    </>
  )
}
