import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { useProperty, useBinding, useInstanceOf } from 'eilmer-mvvm/react'

import * as Core from '@coreui/react'
import CIcon from '@coreui/icons-react'

import ItemTypesViewModel from '#viewmodel/ItemTypesViewModel'
import PrivilegesViewModel from '#viewmodel/PrivilegesViewModel'
import UserRolePermissionsViewModel from '#viewmodel/UserRolePermissionsViewModel'
import PrivilegesConverter from '#converters/PrivilegesConverter'

import { useAuthContext } from '#context/AuthContext'
import { useNotifications } from '#context/NotificationContext'

import * as Forms from '#components/widgets/Forms'
import * as Entities from '#components/widgets/Entities'

import Privileges from '#primitives/Privileges'
import ItemClasses from '#primitives/ItemClasses'

export default function UserRolePermissions({ appViewModel }) {
  const { user_role_id } = useParams()
  appViewModel.breadcrumb = 'Admin / User Role Permissions'
  const viewModel = UserRolePermissionsViewModel.useInstance(user_role_id)
  const permissions = useProperty(useAuthContext(), 'permissions')
  const userRole = useProperty(viewModel, 'userRole')  
  const notifications = useNotifications()

  const handleAddEntity = () => {
    viewModel.doEditEntity(null)
  }

  if (permissions?.hasPrecedence(userRole)) {
    return (
      <Core.CCard style={{display: 'flex', flex: '1 1 0%'}}>
        <Core.CCardHeader>
          <div className="card-header-actions">
            <Core.CButton color="primary" size="sm" onClick={() => handleAddEntity()}>
              <CIcon name="cil-library-add"/>&nbsp;Add Permission
            </Core.CButton>
          </div>
        </Core.CCardHeader>
        <Core.CCardBody>
          <EntityDataTable viewModel={viewModel}/>
        </Core.CCardBody>
        <EditItemModal entityName="Permission" viewModel={viewModel}/>
      </Core.CCard>
    )
  } else if (permissions && userRole) {
    notifications.error('You do not have permissions to edit this user role') 
  } else {
    return null
  }
}

function EntityDataTable({ viewModel }) {
  const entities = useProperty(viewModel, 'entities')

  const handleEditEntity = (entity) => {
    viewModel.doEditEntity(entity)
  }

  const handleDeleteEntity = (entity) => {
    viewModel.doDeleteEntity(entity)
  }

  return (
    <Entities.DataTable entities={entities} fields={viewModel.fields} scopedSlots={{
      'privilege':
        (entity)=>(
          <td>
            {entity.getPrivilege().name}
          </td>
        ),
      'description':
        (entity)=>(
          <td>
            {entity.getPrivilege().description}
          </td>
        ),
      'actions':
        (entity)=>(
          <td style={{textAlign:'right'}}>
            <a href="#" onClick={() => handleEditEntity(entity)}>
              <CIcon className="mr-2" title="Edit" name="cil-pencil"/>
            </a>
            <Link to={`/user_role_permission_constraints/${entity.user_role_permission_id}`}>
              <CIcon className="mr-2" title="Constraints" name="cil-check-circle"/>
            </Link>
            <a href="#" onClick={() => handleDeleteEntity(entity)}>
              <CIcon title="Delete" name="cil-trash"/>
            </a>
          </td>
        ),
    }}/>
  )
}

function EditItemModal({ viewModel, entityName }) {
  const open = useProperty(viewModel, 'editing')
  const [ privilegeId, setPrivilegeId ] = useBinding(viewModel.entity, 'privilege_id')
  const [ itemTypeId, setItemTypeId ] = useBinding(viewModel.entity, 'item_type_id')

  const handleCancel = () => {
    viewModel.doCancelEdit()
  }

  const handleSave = () => {
    viewModel.doSaveEntity(viewModel.entity)
  }

  return (
    <Core.CModal centered show={open}>
      <Forms.ValidatingForm onSubmit={handleSave} onReset={handleCancel}>
        <Core.CModalHeader>Add/Edit {entityName}</Core.CModalHeader>
        <Core.CModalBody>
          <Core.CFormGroup row>
            <Core.CCol md="3">
              <Core.CLabel>Privilege</Core.CLabel>
            </Core.CCol>
            <Core.CCol xs="12" md="9">
              <PrivilegeDropdown required={true} value={privilegeId || ''} onChange={e => setPrivilegeId(e.target.value)}/>
              <small><em>Applies the given privilege to the User Role</em></small>
            </Core.CCol>
          </Core.CFormGroup>
          {(privilegeId == Privileges.CONTENT || privilegeId == Privileges.DATA) &&
            <Core.CFormGroup row>
              <Core.CCol md="3">
                <Core.CLabel>Item Type</Core.CLabel>
              </Core.CCol>
              <Core.CCol xs="12" md="9">
                <ItemTypesDropdown privilegeId={privilegeId} required={false} value={itemTypeId || ''} onChange={e => setItemTypeId(e.target.value)}/>
                <small><em>Applies the privilege to the specific Item Type only</em></small>
              </Core.CCol>
            </Core.CFormGroup>
          }
        </Core.CModalBody>
        <Core.CModalFooter>
          <Core.CButton color="secondary" type="reset">Cancel</Core.CButton>
          &nbsp;
          <Core.CButton color="primary" type="submit">Save</Core.CButton>
        </Core.CModalFooter>
      </Forms.ValidatingForm>
    </Core.CModal>
  )
}

function PrivilegeDropdown({ required, value, onChange }) {
  const viewModel = PrivilegesViewModel.useInstance()
  const permissions = useProperty(useAuthContext(), 'permissions')
  const converter = useInstanceOf(PrivilegesConverter, permissions)

  return (
    <Entities.EntityDropdown required={required} value={value} onChange={onChange} entityViewModel={viewModel} converter={converter}/>
  )
}

function ItemTypesDropdown({ privilegeId, required, value, onChange }) {
  const itemClassId = ({[Privileges.CONTENT]: ItemClasses.CONTENT, [Privileges.DATA]: ItemClasses.DATA})[privilegeId]
  const viewModel = ItemTypesViewModel.useInstance(itemClassId)
  return (
    <Entities.EntityDropdown required={required} nullLabel="Any" value={value} onChange={onChange} entityViewModel={viewModel}/>
  )
}
