import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider, Outlet, useBlocker } from 'react-router-dom'
import { useProperty, useBinding } from 'eilmer-mvvm/react'
import * as Core from '@coreui/react'

import ApiProvider from '#context/ApiContext'
import AppProvider, { useAppViewModel } from '#context/AppContext'
import AuthProvider, { useAuthContext } from '#context/AuthContext'
import NotificationProvider from '#context/NotificationContext'
import MediaLibraryProvider from '#context/MediaLibraryContext'

import Privileges from '#primitives/Privileges'

import AdminLayout from '#components/layout/AdminLayout'
import * as Login from '#components/page/Login'

// Dashboard
import Dashboard from './routes/dashboard'

// Website pages
import Media from './routes/website/media'
import Menus from './routes/website/menus'
import Settings from './routes/website/settings'

// Items pages
import ContentItems from './routes/items/items'
import EditContent from './routes/items/edit'

// Admin Pages
import Users from './routes/admin/users'
import UserRoles from './routes/admin/userRoles'
import UserRolePermissions from './routes/admin/userRolePermissions'
import UserRolePermissionConstraints from './routes/admin/userRolePermissionConstraints'

// Developer pages
import Config from './routes/developer/config'
import Items from './routes/developer/items'
import Types from './routes/developer/types'
import Blocks from './routes/developer/blocks'
import Statuses from './routes/developer/statuses'
import PrivilegesPage from './routes/developer/privileges'
import Validators from './routes/developer/validators'
import Constraints from './routes/developer/constraints'
import Websites from './routes/developer/websites'
import FieldTypes from './routes/developer/fieldTypes'
import ItemClasses from './routes/developer/itemClasses'
import ItemTypes from './routes/developer/itemTypes'
import ItemTypeFields from './routes/developer/itemTypeFields'
import ItemTypeFieldValidators from './routes/developer/itemTypeFieldValidators'
import ContentBlocks from './routes/developer/contentBlocks'
import UserFields from './routes/developer/userFields'
import UserFieldValidators from './routes/developer/userFieldValidators'

import './index.css'
import '@coreui/coreui-pro/dist/css/coreui.min.css'

import {
  cilUser,
  cilContact,
  cilAccountLogout,
  cilBrowser,
  cilSettings,
  cilSpeedometer,
  cilList,
  cilLibrary,
  cilPeople,
  cilCode,
  cilStorage,
  cilFork,
  cilSitemap,
  cilBorderAll,
  cilCaretBottom,
  cilLibraryAdd,
  cilPencil,
  cilTextSquare,
  cilTrash,
  cilSave,
  cilArrowThickRight,
  cilCaretRight,
  cilCheckCircle,
  cilImage,
  cilDataTransferUp,
  cilSync,
  cilFolder,
  cilFile,
  cilLockUnlocked,
  cilChevronLeft,
  cilChevronRight
} from '@coreui/icons'

import {
  cidFileArchive,
  cidFileExcel,
  cidFilePowerpoint,
  cidFilePdf,
  cidFileDoc,
  cidFileImage,
  cidFileAudio,
  cidFileVideo,
  cidFile,
  cisMonitor,
  cisScreenSmartphone,
  cisTablet
} from '@coreui/icons-pro'

React.icons = {
  cilUser,
  cilContact,
  cilAccountLogout,
  cilBrowser,
  cilSettings,
  cilSpeedometer,
  cilList,
  cilLibrary,
  cilPeople,
  cilCode,
  cilStorage,
  cilFork,
  cilSitemap,
  cilBorderAll,
  cilCaretBottom,
  cilLibraryAdd,
  cilPencil,
  cilTextSquare,
  cilTrash,
  cilSave,
  cilArrowThickRight,
  cilCaretRight,
  cilCheckCircle,
  cilImage,
  cilDataTransferUp,
  cilSync,
  cilFolder,
  cilFile,
  cilLockUnlocked,
  cidFileArchive,
  cidFileExcel,
  cidFilePowerpoint,
  cidFilePdf,
  cidFileDoc,
  cidFileImage,
  cidFileAudio,
  cidFileVideo,
  cidFile,
  cilChevronLeft,
  cilChevronRight,
  cisMonitor,
  cisScreenSmartphone,
  cisTablet,
}

const root = ReactDOM.createRoot(document.getElementById('root'))

function Authentication({ children }) {
  const authViewModel = useAuthContext()
  const user = useProperty(authViewModel, 'user')

  if (!user) {
    return <Login.LoginModal authViewModel={authViewModel}/>
  } else {
    return children
  }
}

function UnsavedChangesPrompt({viewModel}) {
  const [ changed, setChanged ] = useBinding(viewModel, 'unsavedChanges')
  const blocker = useBlocker(changed)

  if (blocker.state === 'blocked') {
    return (
      <Core.CModal centered color="info" show={true}>
        <Core.CModalHeader>Confirmation Needed</Core.CModalHeader>
        <Core.CModalBody>
          You have unsaved changes. Press "Continue" if you would like to discard them.
        </Core.CModalBody>
        <Core.CModalFooter>
          <Core.CButton color="secondary" onClick={() => {
            blocker.reset()
          }}>
            Cancel
          </Core.CButton>
          <Core.CButton color="info" onClick={() => {
            setChanged(false)
            blocker.proceed()
          }}>
            Continue
          </Core.CButton>
        </Core.CModalFooter>
      </Core.CModal>
    )
  } else {
    return null
  }
}

function AdminPage({ children }) {
  const appViewModel = useAppViewModel()
  const authViewModel = useAuthContext()
  const permissions = useProperty(authViewModel, 'permissions')
  const routes = []

  routes.push({
    path: '/',
    element: <Dashboard appViewModel={appViewModel}/>
  })

  if (permissions?.hasPrivilege(Privileges.WEBSITE)) {
    routes.push(...[{
      path: '/media',
      element: <Media appViewModel={appViewModel}/>
    },{
      path: '/menus',
      element: <Menus appViewModel={appViewModel}/>
    },{
      path: '/settings',
      element: <Settings appViewModel={appViewModel}/>
    }])
  }

  if (permissions?.hasPrivilege(Privileges.APPLICATION)) {
    routes.push(...[{
      path: '/developer/config',
      element: <Config appViewModel={appViewModel}/>
    },{
      path: '/developer/types',
      element: <Types appViewModel={appViewModel}/>
    },{
      path: '/developer/blocks',
      element: <Blocks appViewModel={appViewModel}/>
    },{
      path: '/developer/statuses',
      element: <Statuses appViewModel={appViewModel}/>
    },{
      path: '/developer/privileges',
      element: <PrivilegesPage appViewModel={appViewModel}/>
    },{
      path: '/developer/validators',
      element: <Validators appViewModel={appViewModel}/>
    },{
      path: '/developer/constraints',
      element: <Constraints appViewModel={appViewModel}/>
    },{
      path: '/developer/websites',
      element: <Websites appViewModel={appViewModel}/>
    },{
      path: '/developer/content-blocks',
      element: <ContentBlocks appViewModel={appViewModel}/>
    },{
      path: '/developer/field-types',
      element: <FieldTypes appViewModel={appViewModel}/>
    },{
      path: '/developer/item-classes',
      element: <ItemClasses appViewModel={appViewModel}/>
    },{
      path: '/developer/item-types',
      element: <ItemTypes appViewModel={appViewModel}/>
    },{
      path: '/developer/items/:item_type_id',
      element: <Items appViewModel={appViewModel}/>
    },{
      path: '/developer/user-fields',
      element: <UserFields appViewModel={appViewModel}/>
    },{
      path: '/developer/user-field-validators/:user_field_id',
      element: <UserFieldValidators appViewModel={appViewModel}/>
    },{
      path: '/developer/item-type-fields/:item_type_id',
      element: <ItemTypeFields appViewModel={appViewModel}/>
    },{
      path: '/developer/item-field-validators/:item_type_field_id',
      element: <ItemTypeFieldValidators appViewModel={appViewModel}/>
    }])
  }

  if (permissions?.hasPrivilege(Privileges.USERS)) {
    routes.push(...[{
      path: '/users',
      element: <Users appViewModel={appViewModel}/>
    },{
      path: '/user-roles',
      element: <UserRoles appViewModel={appViewModel}/>
    },{
      path: '/user_role_permissions/:user_role_id',
      element: <UserRolePermissions appViewModel={appViewModel}/>
    },{
      path: '/user_role_permission_constraints/:user_role_permission_id',
      element: <UserRolePermissionConstraints appViewModel={appViewModel}/>
    }])
  }

  if (permissions?.hasPrivilege(Privileges.CONTENT) || permissions?.hasPrivilege(Privileges.DATA)) {
    routes.push(...[{
      path: '/items/:item_type_id',
      element: <ContentItems appViewModel={appViewModel}/>
    },{
      path: '/items/:item_type_id/children/:parent_item_id',
      element: <ContentItems appViewModel={appViewModel}/>
    },{
      path: '/items/:item_type_id/:item_id',
      element: <EditContent appViewModel={appViewModel}/>
    }])
  }

  if (permissions) {
    
    const router = createBrowserRouter([{
      element: <AdminLayout flex={true} mode="manage"><UnsavedChangesPrompt viewModel={appViewModel}/><Outlet/></AdminLayout>,
      children: routes
    }])
    
    return (
      <RouterProvider router={router}/>
    )
  } else {
    return null
  }
}

root.render(
  <div className="c-app c-default-layout c-legacy-theme">
    <NotificationProvider>
      <ApiProvider>
        <AuthProvider>
          <Authentication>
            <AppProvider>
              <MediaLibraryProvider>
                <AdminPage/>
              </MediaLibraryProvider>
            </AppProvider>
          </Authentication>
        </AuthProvider>
      </ApiProvider>
    </NotificationProvider>
  </div>
)
