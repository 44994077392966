import TreeQLModel from './TreeQLModel'

export default class WebsitesModel extends TreeQLModel {

  constructor(apiClient) {
    super(apiClient)
  }

  async getWebsite(websiteId) {
    const entity = await super.read('websites', websiteId)
    return new WebsitesModel.Website(entity)
  }

  async getWebsites() {
    return (await super.list('websites')).map(each => {
      return new WebsitesModel.Website(each)
    })
  }

  async createWebsite(website) {
    const entity = new WebsitesModel.Website(website)
    return await super.create('websites', entity)
  }

  async updateWebsite(website) {
    const entity = new WebsitesModel.Website(website, true)
    return await super.update('websites', entity.getPk(), entity)
  }

  async deleteWebsite(website) {
    const entity = new WebsitesModel.Website(website)
    return await super.delete('websites', entity.getPk())
  }

}

WebsitesModel.Website = class extends TreeQLModel.Entity {

  constructor(fields, forUpdate) {
    super(fields, 'website_id', forUpdate)
  }

}
