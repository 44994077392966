import TreeQLModel from './TreeQLModel'

export default class UsersModel extends TreeQLModel {

  constructor(apiClient) {
    super(apiClient)
  }

  async getUser(userId) {
    const entity = await super.read('users', userId, {join:['user_roles', 'user_field_values']})
    entity.user_roles = [entity.user_role_id]
    entity.user_role_id = entity.user_roles[0].user_role_id
    return new UsersModel.User(entity)
  }

  async getUsers() {
    return (await super.list('users', {join:['user_roles', 'user_field_values']})).map(each => {
      each.user_roles = [each.user_role_id]
      each.user_role_id = each.user_roles[0].user_role_id
      return new UsersModel.User(each)
    })
  }

  async createUser(user) {
    const entity = new UsersModel.User(user)
    delete entity.user_field_values
    try {
      const creates = []
      const item_id = await super.create('users', entity)
      user.user_field_values.forEach(each => {
        each.item_id = item_id
        creates.push(each)
      })
      if (creates.length) {
        await super.create('user_field_values', creates)
      }
      return item_id
    } catch (err) {
      // TODO: We *may* want to roll back any creates that succeeded here
      throw err
    }
  }

  async updateUser(user) {
    const entity = new UsersModel.User(user, true)
    delete entity.user_roles
    delete entity.user_field_values

    try {
      const creates = [], updates = [], ids = []
      await super.update('users', entity.getPk(), entity)
      // These will usually all be updates, but new fields might need creating
      user.user_field_values.forEach(each => {
        if (each.value_id) {
          ids.push(each.value_id)
          updates.push(each)
        } else {
          each[entity.pkField] = entity.getPk()
          creates.push(each)
        }
      })
      if (creates.length) {
        await super.create('user_field_values', creates)
      }
      if (updates.length) {
        await super.update('user_field_values', ids, updates)
      }
    } catch (err) {
      // TODO: Is there *any* way of rolling anything back?
      throw err
    }
  }


  async deleteUser(user) {
    const entity = new UsersModel.User(user)
    try {
      const values = user.user_field_values || []
      const deletes = values.map(each => each.value_id)
      // Values must be deleted first to avoid FK issues
      if (deletes.length) await super.delete('user_field_values', deletes)
      try {
        await super.delete('user_passwords', entity.getPk())
        return await super.delete('users', entity.getPk())
      } catch (err) {
        if (values.length) {
          // Recreate the values so the delete is rolled back
          await super.create('user_field_values', values)
        }
        throw err
      }
    } catch (err) {
      throw err
    }
  }

}

UsersModel.User = class extends TreeQLModel.Entity {

  constructor(fields, forUpdate) {
    super(fields, 'user_id', forUpdate)
    this.user_field_values = this.user_field_values || []
    this.user_role_id = Number(this.user_role_id)
    this.active = this.active ? 1 : 0
  }

  getUserRole() {
    return this.user_roles ? this.user_roles[0] : undefined
  }

}
