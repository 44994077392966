import ItemsModel from '#model/ItemsModel'
import ItemTypeFieldsModel from '#model/ItemTypeFieldsModel'
import { useApiClient } from '#context/ApiContext'
import { useNotifications } from '#context/NotificationContext'
import { useInstanceOf } from 'eilmer-mvvm/react'
import ItemTypes from '#utils/ItemTypes.js'
import Values from '#utils/Values.js'

export default class ConfigViewModel {
  constructor(apiClient, notificationsProvider, itemTypeId, websiteId) {
    this.itemsModel = new ItemsModel(apiClient)
    this.itemTypeFieldsModel = new ItemTypeFieldsModel(apiClient)
    this.notifications = notificationsProvider
    this.apiClient = apiClient
    this.websiteId = websiteId
    this.itemTypeId = itemTypeId
    this.keyFieldId = null
    this.valueFieldId = null
    this.loaded = false
    this.configs = []
    this.loadConfigs()
  }

  async loadConfigs() {
    this.loaded = false
    const itemTypeFields = await this.itemTypeFieldsModel.getItemTypeFields(
      this.itemTypeId
    )
    this.configs = await this.itemsModel.getItems(
      this.itemTypeId,
      this.websiteId,
      this.itemId
    )
    this.keyFieldId = itemTypeFields
      .find((each) => each.key_name === 'key')
      .getPk()
    this.valueFieldId = itemTypeFields
      .find((each) => each.key_name === 'value')
      .getPk()
    this.loaded = true
  }

  async doSave() {
    try {
      for (let i = 0; i < this.configs.length; i++) {
        let item = this.configs[i]
        if (item.isNew()) {
          item.item_id = await this.itemsModel.createItem(item)
        } else {
          await this.itemsModel.updateItem(item)
        }
      }
      this.loadConfigs()
      return true
    } catch (err) {
      // TODO: Error!
      return false
    }
  }

  getConfigItem(key, create = false) {
    const configItem = this.configs.find((each) => {
      return each.getFieldValue(this.keyFieldId, 'text_value') === key
    })
    if (create && !configItem) {
      const init = { name: key, description: `Configuration for '${key}'` }
      const item = new ItemsModel.Item({
        ...init,
        item_type_id: this.itemTypeId,
      })
      item.setFieldValue(this.keyFieldId, 'text_value', key)
      item.website_id = this.websiteId
      console.log(item)
      this.configs.push(item)
      return item
    }
    return configItem
  }

  getConfig(key) {
    const configItem = this.getConfigItem(key, false)
    return configItem?.getFieldValue(this.valueFieldId, 'json_value')
  }

  setConfig(key, value) {
    const configItem = this.getConfigItem(key, true)
    if (!Array.isArray(value)) {
      configItem.setFieldValue(this.valueFieldId, 'json_value', value)
    } else {
      let field = configItem.getField(this.valueFieldId)
      if (!field) {
        field = { field_id: this.valueFieldId }
        configItem.content_item_values.push(field)
      }
      Values.setFile(field, value)
    }
  }
}

ConfigViewModel.useInstance = function (websiteId) {
  return useInstanceOf(
    ConfigViewModel,
    useApiClient(),
    useNotifications(),
    ItemTypes.CONFIGURATION,
    websiteId
  )
}
