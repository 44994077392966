import FieldTypesModel from '#model/FieldTypesModel'
import { useApiClient } from '#context/ApiContext'
import { useNotifications } from '#context/NotificationContext'
import { useInstanceOf } from 'eilmer-mvvm/react'

export default class FieldTypesViewModel {

  constructor(apiClient, notificationsProvider) {
    this.model = new FieldTypesModel(apiClient)
    this.notifications = notificationsProvider
    this.entity = new FieldTypesModel.FieldType()
    this.editing = false
    this.loadEntities()
  }

  async loadEntities() {
    try {
      this.entities = await this.model.getFieldTypes()
    } catch (err) {
      console.error(err)
      this.notifications.error(err.message)
    }
  }

  doEditEntity(entity) {
    this.entity = new FieldTypesModel.FieldType(entity)
    this.editing = true
  }

  doCancelEdit() {
    this.editing = false
  }

  async doSaveEntity(entity) {
    try {
      if (entity.isNew()) {
        const result = await this.model.createFieldType(entity)
        this.editing = false
        this.loadEntities()
        return result
      } else {
        const result = await this.model.updateFieldType(entity)
        this.editing = false
        this.loadEntities()
        return result
      }
    } catch (err) {
      console.error(err)
      this.notifications.error(err.message)
    }
  }

  async doDeleteEntity(entity) {
    if (await this.notifications.confirm('Are you sure you\'d like to delete this item?')) {
      try {
        const result = await this.model.deleteFieldType(entity)
        this.loadEntities()
        return result
      } catch (err) {
        console.error(err)
        this.notifications.error(err.message)
      }
    }
  }

  get fields() {
    return [
      { key: 'field_type_id', label: "ID", _style: { width: '6%'}, _classes: 'bg-light text-dark' },
      { key: 'type', _style: { width: '15%'} },
      { key: 'name', _style: { width: '20%'} },
      { key: 'description' },
      {
        key: 'actions',
        label: '',
        _style: { width: '70px' },
        filter: false
      }
    ]
  }

}

FieldTypesViewModel.useInstance = function() {
  return useInstanceOf(FieldTypesViewModel, useApiClient(), useNotifications())
}
