import TreeQLModel from './TreeQLModel'

export default class UserRolePermissionsModel extends TreeQLModel {

  constructor(apiClient) {
    super(apiClient)
    this.apolloClient = apiClient
  }

  async getUserRolePermissions(userRoleId) {
    const options = {join: ['privileges', 'user_role_permission_constraints'], filter: `user_role_id,eq,${userRoleId}`}
    return (await super.list('user_role_permissions', options)).map(each => {
      each.privilege = each.privilege_id
      each.privilege_id = each.privilege?.privilege_id
      return new UserRolePermissionsModel.UserRolePermission(each)
    })
  }

  async getUserRolePermission(userRolePermissionId) {
    const entity = await super.read('user_role_permissions', userRolePermissionId)
    return new UserRolePermissionsModel.UserRolePermission(entity)
  }

  async createUserRolePermission(userRolePermission) {
    const entity = new UserRolePermissionsModel.UserRolePermission(userRolePermission)
    return await super.create('user_role_permissions', entity)
  }

  async updateUserRolePermission(userRolePermission) {
    const entity = new UserRolePermissionsModel.UserRolePermission(userRolePermission, true)
    return await super.update('user_role_permissions', entity.getPk(), entity)
  }

  async deleteUserRolePermission(userRolePermission) {
    const entity = new UserRolePermissionsModel.UserRolePermission(userRolePermission)
    return await super.delete('user_role_permissions', entity.getPk())
  }

}

UserRolePermissionsModel.UserRolePermission = class extends TreeQLModel.Entity {

  constructor(fields, forUpdate) {
    super(fields, 'user_role_permission_id', forUpdate)
  }

  getPrivilege() {
    return this.privilege
  }

  getConstraints() {
    return this.user_role_permission_constraints
  }

}
