import UsersModel from '#model/UsersModel'
import UserFieldsModel from '#model/UserFieldsModel'
import { useApiClient } from '#context/ApiContext'
import { useNotifications } from '#context/NotificationContext'
import { useInstanceOf } from 'eilmer-mvvm/react'

export default class UsersViewModel {

  constructor(apiClient, notificationsProvider) {
    this.apiClient = apiClient
    this.model = new UsersModel(apiClient)
    this.fieldsModel = new UserFieldsModel(apiClient)
    this.notifications = notificationsProvider
    this.entity = new UsersModel.User()
    this.settingPassword = false
    this.editing = false
    this.loadEntities()
  }

  async loadEntities() {
    try {
      this.entities = await this.model.getUsers()
      this.userFields = await this.fieldsModel.getUserFields()
    } catch (err) {
      console.error(err)
      this.notifications.error(err.message)
    }
  }

  doEditEntity(entity) {
    this.entity = new UsersModel.User(entity)
    this.editing = true
  }

  doCancelEdit() {
    this.editing = false
  }

  async doSaveEntity(entity) {
    try {
      if (entity.isNew()) {
        const result = await this.model.createUser(entity)
        this.editing = false
        this.loadEntities()
        return result
      } else {
        const result = await this.model.updateUser(entity)
        this.editing = false
        this.loadEntities()
        return result
      }
    } catch (err) {
      console.error(err)
      this.notifications.error(err.message)
    }
  }

  async doDeleteEntity(entity) {
    if (await this.notifications.confirm('Are you sure you\'d like to delete this user?')) {
      try {
        const result = await this.model.deleteUser(entity)
        this.loadEntities()
        return result
      } catch (err) {
        console.error(err)
        this.notifications.error(err.message)
      }
    }
  }

  doSetPassword(entity) {
    this.entity = new UsersModel.User(entity)
    this.settingPassword = true
  }

  doCancelSetPassword() {
    this.settingPassword = false
  }

  async doSavePassword(user, password) {
    try {
      const url = this.apiClient.endpoint + '/setpassword'
      const body = {user_id: user.user_id, password: password}
      const config = {credentials: 'include', method: 'POST', body: JSON.stringify(body)}
      const response = await fetch(url, config)
      const data = await response.json()
      if (response.status !== 200) {
        throw new Error(data.message || response.statusText)
      } else {
        this.settingPassword = false
      }
    } catch (err) {
      console.error(err)
      this.notifications.error(err.message)
    }
  }

  get fields() {
    return [
      { key: 'user_id', label: "ID", _style: { width: '6%'}, _classes: 'bg-light text-dark' },
      { key: 'email_address', _style: { width: '25%'} },
      { key: 'user_name', _style: { width: '25%'} },
      { key: 'role', _style: { width: '25%'} },
      { key: 'active', _style: { width: '5%'} },
      {
        key: 'actions',
        label: '',
        _style: { width: '70px' },
        filter: false
      }
    ]
  }

}

UsersViewModel.useInstance = function() {
  return useInstanceOf(UsersViewModel, useApiClient(), useNotifications())
}
