import TreeQLModel from './TreeQLModel'

export default class ItemTypesModel extends TreeQLModel {

  constructor(apiClient) {
    super(apiClient)
  }

  async getItemType(itemTypeId) {
    const options = {join: ['item_types']}
    const entity = await super.read('item_types', itemTypeId, options)
    entity.child_item_types = entity.item_types || []
    entity.child_item_type_id = entity.child_item_types[0]?.item_type_id
    entity.item_types = [entity.parent_type_id]
    entity.parent_type_id = entity.item_types[0]?.item_type_id
    return new ItemTypesModel.ItemType(entity)
  }

  async getItemTypes(itemClassId) {
    const options = {join: ['item_classes', 'item_types', 'item_type_fields,item_type_field_validators', 'user_roles']}
    if (typeof itemClassId === 'number') options.filter = `item_class_id,eq,${itemClassId}`
    return (await super.list('item_types', options)).map(each => {
      each.item_classes = [each.item_class_id]
      each.item_class_id = each.item_classes[0]?.class_id
      each.item_types = [each.parent_type_id]
      each.parent_type_id = each.item_types[0]?.item_type_id
      each.user_roles = [each.user_role_id]
      each.user_role_id = each.user_roles[0]?.user_role_id
      return new ItemTypesModel.ItemType(each)
    })
  }

  async createItemType(itemType) {
    const entity = new ItemTypesModel.ItemType(itemType)
    // TODO: This can be implemented in TreeQLModel.Entity by excluding array types in toJSON()
    delete entity.item_types
    delete entity.item_classes
    return await super.create('item_types', entity)
  }

  async updateItemType(itemType) {
    const entity = new ItemTypesModel.ItemType(itemType, true)
    delete entity.item_types
    delete entity.item_classes
    return await super.update('item_types', entity.getPk(), entity)
  }

  async deleteItemType(itemType) {
    const entity = new ItemTypesModel.ItemType(itemType)
    return await super.delete('item_types', entity.getPk())
  }

}

ItemTypesModel.ItemType = class extends TreeQLModel.Entity {

  constructor(fields, forUpdate) {
    super(fields, 'item_type_id', forUpdate)
  }

  getParentItemType() {
    return this.item_types ? this.item_types[0] : undefined    
  }

  getChildItemType() {
    return this.child_item_types ? this.child_item_types[0] : undefined    
  }

  getUserRole() {
    return this.user_roles ? this.user_roles[0] : undefined    
  }

}
