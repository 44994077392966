import { Converter } from 'eilmer-mvvm/converters'

export default class FieldValuesConverter extends Converter {

  convertFrom(viewModelValue, bindingContext) {
    const viewValue = {}
    bindingContext.setAttribute('original', viewModelValue)
    viewModelValue?.forEach(each => {
      viewValue[each.field_id] = each
    })
    return viewValue
  }

  convertTo(viewValue, bindingContext) {
    const viewModelValue = []
    const original = bindingContext.getAttribute('original')
    Object.entries(viewValue).forEach(([key, value]) => {
      const id = Number(key), field = original.find(each => each.field_id === id) || {}
      Object.assign(field, value)
      field.field_id = id
      viewModelValue.push(field)
    })
    return viewModelValue
  }
}
