import TreeQLModel from './TreeQLModel'

export default class PrivilegesModel extends TreeQLModel {

  constructor(apiClient) {
    super(apiClient)
  }

  async getPrivileges() {
    return (await super.list('privileges')).map(each => {
      return new PrivilegesModel.Privilege(each)
    })
  }

  async createPrivilege(privilege) {
    const entity = new PrivilegesModel.Privilege(privilege)
    return await super.create('privileges', entity)
  }

  async updatePrivilege(privilege) {
    const entity = new PrivilegesModel.Privilege(privilege, true)
    return await super.update('privileges', entity.getPk(), entity)
  }

  async deletePrivilege(privilege) {
    const entity = new PrivilegesModel.Privilege(privilege)
    return await super.delete('privileges', entity.getPk())
  }

}

PrivilegesModel.Privilege = class extends TreeQLModel.Entity {

  constructor(fields, forUpdate) {
    super(fields, 'privilege_id', forUpdate)
  }

}
