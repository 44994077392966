import TreeQLModel from './TreeQLModel'

export default class UserFieldValidatorsModel extends TreeQLModel {

  constructor(apiClient) {
    super(apiClient)
  }

  async getUserFieldValidators(userFieldId) {
    const options = {filter: `user_field_id,eq,${userFieldId}`, join: 'validators', order: 'sequence'}
    return (await super.list('user_field_validators', options)).map(each => {
      each.validators = [each.validator_id]
      each.validator_id = each.validators[0]?.validator_id
      return new UserFieldValidatorsModel.UserFieldValidator(each)
    })
  }

  async createUserFieldValidator(userFieldValidator) {
    const entity = new UserFieldValidatorsModel.UserFieldValidator(userFieldValidator)
    return await super.create('user_field_validators', entity)
  }

  async updateUserFieldValidator(userFieldValidator) {
    const entity = new UserFieldValidatorsModel.UserFieldValidator(userFieldValidator, true)
    return await super.update('user_field_validators', entity.getPk(), entity)
  }

  async deleteUserFieldValidator(userFieldValidator) {
    const entity = new UserFieldValidatorsModel.UserFieldValidator(userFieldValidator)
    return await super.delete('user_field_validators', entity.getPk())
  }

}

UserFieldValidatorsModel.UserFieldValidator = class extends TreeQLModel.Entity {

  constructor(fields, forUpdate) {
    super(fields, 'field_validator_id', forUpdate)
  }

}
