import { useState, useEffect, useRef } from 'react'
import * as Core from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {isMatch} from 'matcher'

import MediaObject from './MediaObject'
import DynamicList from './DynamicList'

export default function MediaLibrary({ media, onMediaObjectSelected, deleteMediaObjectHandler, uploadMediaObjectHandler, saveMediaObjectsHandler, refreshHandler }) {
  const [ mediaData, setMediaData ] = useState(media || [])
  const [ selectedMediaFolder, setSelectedMediaFolder ] = useState(mediaData[0])
  const [ selectedMediaObject, setSelectedMediaObject ] = useState(null)
  const [ showUploadDialog, setShowUploadDialog ] = useState(false)
  const [ uploadFeedback, setUploadFeedback ] = useState('')
  const uploadInput = useRef()

  const sortable = typeof saveMediaObjectsHandler === 'function'

  const selectMediaObject = (e, mediaObject) => {
    setSelectedMediaObject(mediaObject)
  }

  const selectMediaFolder = (mediaFolder) => {
    setSelectedMediaFolder(mediaFolder)
    if (selectedMediaFolder !== mediaFolder) {
      setSelectedMediaObject(null)
    }
  }

  const deleteMediaObject = async () => {
    if (typeof deleteMediaObjectHandler === 'function') {
      const mediaFolder = selectedMediaFolder
      const mediaObject = selectedMediaObject
      updateMediaData(await deleteMediaObjectHandler(mediaObject))
    }
  }

  const uploadMediaObject = async () => {
    if (typeof uploadMediaObjectHandler === 'function') {
      const mediaFolder = selectedMediaFolder
      const uploadFile = uploadInput.current.files[0]
      const fileReader = new FileReader();
      fileReader.onload = async function() {
        const mediaObject = {}, dataUrl = fileReader.result
        const base64Value = dataUrl.substr(dataUrl.indexOf(',') + 1)
        mediaObject.name = uploadFile.name
        mediaObject.contentType = uploadFile.type
        mediaObject.size = uploadFile.size
        mediaObject.content = base64Value
        if (isMatch(mediaObject.contentType, 'image/*')) {
          const image = new Image()
          image.onload = async () => {
            mediaObject.imageWidth = image.width
            mediaObject.imageHeight = image.height
            updateMediaData(await uploadMediaObjectHandler(mediaFolder, mediaObject))
            setShowUploadDialog(false)
            setUploadFeedback('')
          }
          image.src = fileReader.result
        } else {
          updateMediaData(await uploadMediaObjectHandler(mediaFolder, mediaObject))
          setShowUploadDialog(false)
          setUploadFeedback('')
        }
      }
      fileReader.readAsDataURL(uploadFile);
    }
  }

  const refresh = async () => {
    if (typeof refreshHandler === 'function') {
      updateMediaData(await refreshHandler())
    }
  }

  const updateMediaData = (freshMediaData) => {
    const currentMediaFolder = freshMediaData.find(each => each.folderId === selectedMediaFolder.folderId )
    setMediaData(freshMediaData)
    setSelectedMediaFolder(currentMediaFolder || freshMediaData[0])
    setSelectedMediaObject(null)
  }

  const handleUploadFileChange = (e) => {
    uploadInput.current = e.target
    const uploadFile = uploadInput.current.files[0]
    if (uploadFile.size > 4194304) {
      setUploadFeedback('File must not exceed 4 MB in size')
    } else {
      setUploadFeedback('')
    }
  }

  const onSort = (mediaObjects) => {
    if (typeof saveMediaObjectsHandler === 'function') {
      saveMediaObjectsHandler(mediaObjects)
      selectedMediaFolder.mediaObjects = mediaObjects
    }
  }

  useEffect(() => {
    if (typeof onMediaObjectSelected === 'function') {
      if (selectedMediaObject !== null) {
        onMediaObjectSelected(selectedMediaObject)
      }
    }
  }, [selectedMediaObject])

  return (
    <>
      <Core.CRow style={{flex:1,overflow:'auto'}}>
        <Core.CCol md="3" style={{flex:1,display:'flex'}}>
          <Core.CSidebar colorScheme="dark" fixed={false} size="sm" style={{display:'flex',flex:1}}>
            <Core.CSidebarNav>
              {mediaData.map(mediaFolder => {
                const linkClass = mediaFolder === selectedMediaFolder ? 'c-active' : ''
                return (
                  <Core.CSidebarNavItem
                    key={mediaFolder.folderId}
                    name={mediaFolder.folderName}
                    addLinkClass={linkClass}
                    icon={`cil-${mediaFolder.folderIcon || 'folder'}`}
                    onClick={() => selectMediaFolder(mediaFolder)}
                    style={{whiteSpace: "normal"}}
                  />
                )
              })}
            </Core.CSidebarNav>
            {/*
            <Core.CSidebarFooter>
              <Core.CButton color="dark" block>Create Folder...</Core.CButton>
            </Core.CSidebarFooter>
            */}
          </Core.CSidebar>
        </Core.CCol>
        <Core.CCol md="9" style={{display: 'flex', flex: 1, flexDirection: 'column'}}>
          <div className="text-right mb-3 pb-3 border-bottom">
            <Core.CButton color="light" size="sm" disabled={!selectedMediaObject} onClick={deleteMediaObject}>
              <CIcon name="cil-trash"/> Delete File
            </Core.CButton>
            <Core.CButton className="ml-2" color="light" size="sm" onClick={() => setShowUploadDialog(true)}>
              <CIcon name="cil-data-transfer-up"/> Upload File...
            </Core.CButton>
            <Core.CButton className="ml-2" color="light" size="sm" onClick={refresh}>
              <CIcon name="cil-sync"/> Refresh
            </Core.CButton>
          </div>
          <div style={{position:'relative', flex: 1}}>
            <div style={{position:'absolute', top: 0, bottom: 0, overflowY: 'auto'}}>
            {selectedMediaFolder && 
              <DynamicList draggable={sortable} items={selectedMediaFolder.mediaObjects} onItemClick={selectMediaObject} onChange={onSort} render={(mediaObject) => (
                <MediaObject key={mediaObject.id} selected={mediaObject == selectedMediaObject} mediaObject={mediaObject}/>
              )}/>
            }
            </div>
          </div>
        </Core.CCol>
      </Core.CRow>
      <Core.CModal centered show={showUploadDialog}>
        <Core.CModalHeader>Upload File</Core.CModalHeader>
        <Core.CModalBody>
          <Core.CInputFile onChange={handleUploadFileChange}/>
          <Core.CInvalidFeedback className={uploadFeedback ? 'd-block' : ''}>{uploadFeedback}</Core.CInvalidFeedback>
        </Core.CModalBody>
        <Core.CModalFooter>
          <Core.CButton color="secondary" onClick={() => setShowUploadDialog(false)}>Cancel</Core.CButton>
          &nbsp;
          <Core.CButton color="primary" disabled={!!uploadFeedback} onClick={uploadMediaObject}>Upload</Core.CButton>
        </Core.CModalFooter>
      </Core.CModal>
    </>
  )
}
