import TreeQLModel from './TreeQLModel'

export default class UserRolePermissionConstraintsModel extends TreeQLModel {

  constructor(apiClient) {
    super(apiClient)
    this.apolloClient = apiClient
  }

  async getUserRolePermissionConstraints(userRolePermissionId) {
    const options = {join: 'constraints', filter: `user_role_permission_id,eq,${userRolePermissionId}`}
    return (await super.list('user_role_permission_constraints', options)).map(each => {
      each.constraint = each.constraint_id
      each.constraint_id = each.constraint?.constraint_id
      return new UserRolePermissionConstraintsModel.UserRolePermissionConstraint(each)
    })
  }

  async createUserRolePermissionConstraint(userRolePermissionConstraint) {
    const entity = new UserRolePermissionConstraintsModel.UserRolePermissionConstraint(userRolePermissionConstraint)
    return await super.create('user_role_permission_constraints', entity)
  }

  async updateUserRolePermissionConstraint(userRolePermissionConstraint) {
    const entity = new UserRolePermissionConstraintsModel.UserRolePermissionConstraint(userRolePermissionConstraint, true)
    return await super.update('user_role_permission_constraints', entity.getPk(), entity)
  }

  async deleteUserRolePermissionConstraint(userRolePermissionConstraint) {
    const entity = new UserRolePermissionConstraintsModel.UserRolePermissionConstraint(userRolePermissionConstraint)
    return await super.delete('user_role_permission_constraints', entity.getPk())
  }

}

UserRolePermissionConstraintsModel.UserRolePermissionConstraint = class extends TreeQLModel.Entity {

  constructor(fields, forUpdate) {
    super(fields, 'user_role_permission_constraint_id', forUpdate)
  }

  getConstraint() {
    return this.constraint
  }
}
