import React from 'react'
import {isMatch} from 'matcher'
import { useInstanceOf, useBinding, useProperty } from 'eilmer-mvvm/react'
import Eilmer from 'eilmer-mvvm'

import * as Core from '@coreui/react'
import CIcon from '@coreui/icons-react'

import Types from '#primitives/Types'
import Validators from '#primitives/Validators'
// TODO: I'm only importing this for the converter -
// find a better place for it, dunno - I am using elsewhere now
import ItemsViewModel from '#viewmodel/ItemsViewModel'
import FieldValueConverter from '#converters/FieldValueConverter'
import SortByNameConverter from '#converters/SortByNameConverter'

import * as Forms from '#components/widgets/Forms'
import * as Inputs from '#components/widgets/Inputs'
import * as Entities from '#components/widgets/Entities'

// The itemTypeId for media items
// TODO: THIS DOESN'T BELONG HERE
const MEDIA_ITEM_TYPE_ID = 8

export function Field({ websiteId, item, viewModel, field, fieldValidators, visible, onChange }) {
  const fieldType = field.getFieldType()
  const converter = useInstanceOf(FieldValueConverter, fieldType)
  const [ value, setValue ] = useBinding(viewModel, String(field.getPk()), converter)
  const itemRef = React.useRef(), valueRef = React.useRef()
  const validators = []

  if (itemRef.current !== item) {
    itemRef.current = item
    valueRef.current = value
  }

  React.useEffect(() => {
    if (field.getParentPk()) {
      return Eilmer.Properties.addPropertyChangeListener(viewModel, String(field.getParentPk()), (defaultValue) => {
        if (!valueRef.current) {
          setValue(converter.convertFrom(defaultValue).trim())
        }
      })
    }
  }, [viewModel])

  // I may need to order these by sequence manually but we'll see
  field.getValidators().forEach(fieldValidator => {
    // Looks odd - it's how TreeQL pulls it out - this is a validators table record
    const validator = fieldValidator.validator_id
    if (validator.validator_id === Validators.REGULAR_EXPRESSION) {
      validators.push({
        test: (value) => new RegExp(fieldValidator.value).test(value),
        message: fieldValidator.message
      })
    } else if (validator.validator_id === Validators.MIME_TYPE) {
      validators.push({
        test: (value) => {
          if (!(fieldType.type_id === Types.ITEM && fieldType.item_type_id === MEDIA_ITEM_TYPE_ID)) {
            return isMatch(value?.type || 'none/none', fieldValidator.value) || false
          }
          return true
        },
        mimeType: fieldValidator.value,
        message: fieldValidator.message
      })
    } else if (validator.validator_id === Validators.MAX_LENGTH) {
      validators.push({
        test: (value) => Number(fieldValidator.value) > value?.length || false, 
        message: fieldValidator.message
      })
    } else if (validator.validator_id === Validators.MIME_REGEX) {
      validators.push({
        test: (value) => {          
          if (!(fieldType.type_id === Types.ITEM && fieldType.item_type_id === MEDIA_ITEM_TYPE_ID)) {
            return new RegExp(fieldValidator.value).test(value?.type || 'none/none')
          }
          return true
        },
        message: fieldValidator.message
      })
    }
  })

  if (fieldType.type_id === Types.FILE) {
    validators.push({
      test: (value) => value?.size <= 4194304,
      message: 'File must not exceed 4 MB in size'
    })
  }

  const changeHandler = (e) => {
    let triggerChange = true
    if (fieldType.type_id === Types.FILE) {
      if (e.target.files?.length) {
        const uploadFile = e.target.files[0]
        const fileReader = new FileReader()
        fileReader.onload = async () => {
          const dataUrl = fileReader.result
          if (isMatch(uploadFile.type, 'image/*')) {
            const image = new Image()
            image.onload = async () => {
              uploadFile.imageWidth = image.width
              uploadFile.imageHeight = image.height
              setValueInternal([uploadFile, dataUrl])
              if (typeof onChange === 'function') {
                onChange()
              }
            }
            image.src = dataUrl
          } else {
            setValueInternal([uploadFile, fileReader.result])
            if (typeof onChange === 'function') {
              onChange()
            }
          }
        }
        fileReader.readAsDataURL(uploadFile)
        triggerChange = false
      } else {
        setValueInternal(null)
      }
    } else if (fieldType.type_id === Types.BOOLEAN) {
      setValueInternal(e.target.checked)
    } else {
      setValueInternal(e.target.value)
    }
    if (triggerChange) {
      if (typeof onChange === 'function') {
        onChange()
      }
    }
  }

  const setValueInternal = (value) => {
    valueRef.current = value
    setValue(value)
  }

  const setDefaultValue = () => {
    // Ensures some value gets saved even if it's not edited
    const viewModelValue = viewModel[String(field.getPk())]
    if (typeof viewModelValue === 'undefined') {
      viewModel[String(field.getPk())] = converter.convertTo(value)
    }
  }
  setDefaultValue()

  if (visible) {
      return (
      <Forms.ValidatedFormControl>
        {({ ref, feedback }) => (
          <FieldInput innerRef={ref} websiteId={websiteId} fieldType={fieldType} validators={validators} feedback={feedback} required={field.required} value={value} onChange={changeHandler}/>
        )}
      </Forms.ValidatedFormControl>
    )
  } else {
    return null
  }
}

export function FieldInput({ innerRef, websiteId, fieldType, validators, feedback, required, value, onChange }) {
  // TODO: We *might* want to use uuids on the type object
  // so that this is *NOT* linked to a database id

  const mimeType = validators.find(each => each.mimeType)?.mimeType

  React.useEffect(() => {
    if (validators && validators.length) {
      const element = innerRef.current, handler = (e) => {
        const value = element.files ? element.files[0] : element.value
        const failed = validators.find(each => !each.test(value))
        element.setCustomValidity(failed ? failed.message : '')
      }
      element.addEventListener('input', handler)
      element.addEventListener('change', handler)
      return () => {
        element.removeEventListener('input', handler)
        element.removeEventListener('change', handler)
      }
    }
  }, [innerRef.current])

  if (fieldType.type_id === Types.TEXT) {
    return <>
      <Core.CInput innerRef={innerRef} required={required} type="text" value={value} onChange={onChange}/>
    </>
  } else if (fieldType.type_id === Types.DATE) {
    return (
      <Core.CInput innerRef={innerRef} required={required} type="date" value={value} onChange={onChange}/>
    )
  } else if (fieldType.type_id === Types.ITEM && fieldType.item_type_id === MEDIA_ITEM_TYPE_ID) {
    return (
      <Inputs.MediaItem innerRef={innerRef} mimeType={mimeType} required={required} value={value} onChange={onChange}/>
    )
  } else if (fieldType.type_id === Types.ITEM) {
    return (
      <ItemInput innerRef={innerRef} websiteId={websiteId} itemTypeId={fieldType.item_type_id} required={required} value={value} onChange={onChange}/>
    )
  } else if (fieldType.type_id === Types.TEXTAREA) {
    return (
      <Core.CTextarea innerRef={innerRef} required={required} value={value} onChange={onChange}/>
    )
  } else if (fieldType.type_id === Types.FILE) {
    return (
      <FileInput innerRef={innerRef} feedback={feedback} required={required} value={value} onChange={onChange}/>
    )
  } else if (fieldType.type_id === Types.URL) {
    return (
      <Core.CInput innerRef={innerRef} required={required} type="url" value={value} onChange={onChange}/>
    )
  } else if (fieldType.type_id === Types.BOOLEAN) {
    return (
      <div>
        <Core.CSwitch innerRef={innerRef} shape="pill" color="primary" checked={!!value} onChange={onChange}/>
      </div>
    )
  } else if (fieldType.type_id === Types.TIME) {
    return (
      <Core.CInput innerRef={innerRef} required={required} type="time" value={value} onChange={onChange}/>
    )
  } else if (fieldType.type_id === Types.HTML) {
    return (
      <Inputs.Html innerRef={innerRef} required={required} value={value} onChange={onChange}/>
    )
  } else if (fieldType.type_id === Types.SLUG) {
    return (
      <Inputs.Slug innerRef={innerRef} required={required} value={value} onChange={onChange}/>
    )
  } else {
    return <div ref={innerRef}>Unhandled Field type: {fieldType.type_id}</div>
  }
}

function ItemInput({ innerRef, websiteId, itemTypeId, required, value, onChange }) {
  const viewModel = ItemsViewModel.useInstance(itemTypeId)
  const converter = useInstanceOf(SortByNameConverter)
  return (
    <Entities.EntityDropdown innerRef={innerRef} required={required} value={value} onChange={onChange} entityViewModel={viewModel} property="items" converter={converter}/>
  )
}

function FileInput({ innerRef, feedback, required, value, onChange }) {

  return <>
    <Inputs.File innerRef={innerRef} required={required} value={value} onChange={onChange}/>
    <Core.CInvalidFeedback>{feedback}</Core.CInvalidFeedback>
  </>
}
