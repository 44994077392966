import TreeQLModel from './TreeQLModel'

export default class ValidatorsModel extends TreeQLModel {

  constructor(apiClient) {
    super(apiClient)
  }

  async getValidators() {
    return (await super.list('validators')).map(each => {
      return new ValidatorsModel.Validator(each)
    })
  }

  async createValidator(validator) {
    const entity = new ValidatorsModel.Validator(validator)
    return await super.create('validators', entity)
  }

  async updateValidator(validator) {
    const entity = new ValidatorsModel.Validator(validator, true)
    return await super.update('validators', entity.getPk(), entity)
  }

  async deleteValidator(validator) {
    const entity = new ValidatorsModel.Validator(validator)
    return await super.delete('validators', entity.getPk())
  }

}

ValidatorsModel.Validator = class extends TreeQLModel.Entity {

  constructor(fields, forUpdate) {
    super(fields, 'validator_id', forUpdate)
  }

}
