import TreeQLModel from './TreeQLModel'

export default class BlocksModel extends TreeQLModel {

  constructor(apiClient) {
    super(apiClient)
  }

  async getBlocks() {
    return (await super.list('blocks')).map(each => {
      return new BlocksModel.Block(each)
    })
  }

  async createBlock(block) {
    const entity = new BlocksModel.Block(block)
    return await super.create('blocks', entity)
  }

  async updateBlock(block) {
    const entity = new BlocksModel.Block(block, true)
    return await super.update('blocks', entity.getPk(), entity)
  }

  async deleteBlock(block) {
    const entity = new BlocksModel.Block(block)
    return await super.delete('blocks', entity.getPk())
  }

}

BlocksModel.Block = class extends TreeQLModel.Entity {

  constructor(fields, forUpdate) {
    super(fields, 'block_id', forUpdate)
  }

}
