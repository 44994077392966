import TreeQLModel from './TreeQLModel'

export default class ItemsModel extends TreeQLModel {

  constructor(apiClient, includeChildren) {
    super(apiClient)
    this.includeChildren = includeChildren
  }

  async getItem(itemId) {
    const options = {join: 'content_item_values'}
    // Don't ever exclude base64_value from here because it's needed by deleteItem() below
    if (this.includeChildren) options.join = [options.join, 'content_items,content_item_values']
    const entity = await super.read('content_items', itemId, options)
    return new ItemsModel.Item(entity)
  }

  async getItems(itemTypeId, websiteId, parentItemId = undefined) {
    const options = {join: 'content_item_values', order: 'sequence'}
    options.exclude = ['content_item_values.base64_value']
    options.filter = [`website_id,eq,${websiteId}`, `item_type_id,eq,${itemTypeId}`]
    if (typeof parentItemId !== 'undefined') options.filter.push(parentItemId ? `parent_item_id,eq,${parentItemId}` : 'parent_item_id,is')
    if (this.includeChildren) options.join = [options.join, 'content_items,content_item_values']
    return (await super.list('content_items', options)).map(each => {
      return new ItemsModel.Item(each)
    })
  }

  async createItem(item) {
    const entity = new ItemsModel.Item(item)
    delete entity.content_item_values
    try {
      const creates = []
      const item_id = await super.create('content_items', entity)
      item.content_item_values.forEach(each => {
        each.item_id = item_id
        creates.push(each)
      })
      if (creates.length) {
        const ids = await super.create('content_item_values', creates)
        ids.forEach((id, idx) => creates[idx].value_id = id)
      }
      return item_id
    } catch (err) {
      // TODO: We *may* want to roll back any creates that succeeded here
      throw err
    }
  }

  async updateItem(item) {
    const entity = new ItemsModel.Item(item, true)
    delete entity.item_id
    delete entity.content_item_values
    if (entity.created_date) {
      entity.created_date = entity.created_date.toISOString().substring(0, 19).replace('T', ' ');
    }
    try {
      const creates = [], updates = [], ids = []
      await super.update('content_items', item.item_id, entity)
      // These will usually all be updates, but new fields might need creating
      item.content_item_values.forEach(each => {
        if (each.value_id) {
          ids.push(each.value_id)
          updates.push(each)
        } else {
          each.item_id = item.item_id
          creates.push(each)
        }
      })
      if (creates.length) {
        const ids = await super.create('content_item_values', creates)
        ids.forEach((id, idx) => creates[idx].value_id = id)
      }
      if (updates.length) {
        await super.update('content_item_values', ids, updates)
      }
    } catch (err) {
      // TODO: Is there *any* way of rolling anything back?
      throw err
    }
  }

  async deleteItem(item) {
    try {
      // This is so that any base64_value fields are complete
      const freshItem = await this.getItem(item.item_id)
      const values = freshItem.content_item_values || []
      const deletes = values.map(each => each.value_id)
      // Values must be deleted first to avoid FK issues
      if (deletes.length) await super.delete('content_item_values', deletes)
      try {
        return await super.delete('content_items', item.item_id)
      } catch (err) {
        if (values.length) {
          // Recreate the values so the delete is rolled back
          await super.create('content_item_values', values)
        }
        throw err
      }
    } catch (err) {
      throw err
    }
  }

}

ItemsModel.Item = class extends TreeQLModel.Entity {

  constructor(fields, forUpdate) {
    super(fields, 'item_id', forUpdate)
    this.content_item_values = this.content_item_values || []
    this.parent_item_id = this.parent_item_id ? Number(this.parent_item_id) : undefined
    this.sequence = fields?.sequence || 0
    // The getItem(s) methods above can include values that *don't* actually
    // belong to this item - so here we filter them
    this.content_item_values = this.content_item_values.filter(each => {
      return !this.item_id || !each.item_id || this.item_id === each.item_id
    })
    if (this.isNew() || forUpdate) {
      delete this.created_date
    }
  }

  getField(fieldId) {
    return this.content_item_values.find(each => each.field_id == fieldId)
  }

  getFieldValue(fieldId, fieldField) {
    const field = this.getField(fieldId)
    if (field && fieldField === 'json_value') {
      return JSON.parse(field[fieldField])
    } else if (field) {
      return field[fieldField]
    }
  }

  setFieldValue(fieldId, fieldField, fieldValue) {
    const field = this.getField(fieldId)
    const value = fieldField === 'json_value' ? JSON.stringify(fieldValue) : fieldValue
    if (field) {
      field[fieldField] = value
    } else {
      this.content_item_values.push({field_id: fieldId, [fieldField]: value})
    }
  }

}
