import React from 'react'
import * as Core from '@coreui/react'
import CIcon from '@coreui/icons-react'

import styles from './Pager.module.scss'

export default function Pager({ pages, children }) {
    const [ activeIndex, setActiveIndex ] = React.useState(0)
  
    const handlePrevNext = (direction) => {
      if (direction === 'next') {
        setActiveIndex(Math.min(pages - 1, activeIndex + 1))
      } else if (direction === 'prev') {
        setActiveIndex(Math.max(0, activeIndex - 1))
      }
    }  
    return (
      <Core.CCarousel className={styles.formCarousel} animate={true} activeIndex={activeIndex}>
        <Core.CCarouselInner className="border p-3 mb-3">
          {React.Children.map(children, child =>
            <Core.CCarouselItem className="px-4">
              {child}
            </Core.CCarouselItem>
          )}
        </Core.CCarouselInner>
        <div style={{position: 'relative', height: '3em'}}>
          <Core.CButton id="prev" size="sm" color="secondary" onClick={() => handlePrevNext('prev')}>
            <CIcon name="cil-chevron-left"/>
          </Core.CButton>
          <Core.CButton id="next" className="float-right" size="sm" color="secondary" onClick={() => handlePrevNext('next')}>
            <CIcon name="cil-chevron-right"/>
          </Core.CButton>
          <Core.CCarouselIndicators/>
        </div>
      </Core.CCarousel>
    )
  }
  