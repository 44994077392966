export default class Types {

    static TEXT = 7

    static DATE = 8

    static ITEM = 9

    static TEXTAREA = 10

    static JSON = 11

    static FILE = 12

    static URL = 13

    static BOOLEAN = 14

    static TIME = 15

    static HTML = 16

    static SLUG = 17

    // static EMAIL = 18

}