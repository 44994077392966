import TreeQLModel from './TreeQLModel'

export default class ConstraintsModel extends TreeQLModel {

  constructor(apiClient) {
    super(apiClient)
  }

  async getConstraints() {
    return (await super.list('constraints')).map(each => {
      return new ConstraintsModel.Constraint(each)
    })
  }

  async createConstraint(constraint) {
    const entity = new ConstraintsModel.Constraint(constraint)
    return await super.create('constraints', entity)
  }

  async updateConstraint(constraint) {
    const entity = new ConstraintsModel.Constraint(constraint, true)
    return await super.update('constraints', entity.getPk(), entity)
  }

  async deleteConstraint(constraint) {
    const entity = new ConstraintsModel.Constraint(constraint)
    return await super.delete('constraints', entity.getPk())
  }

}

ConstraintsModel.Constraint = class extends TreeQLModel.Entity {

  constructor(fields, forUpdate) {
    super(fields, 'constraint_id', forUpdate)
  }

}
