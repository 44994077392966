import { Converter } from 'eilmer-mvvm/converters'

export default class EntityFilterConverter extends Converter {

  constructor(filter) {
    super()
    this.filter = filter
  }
  
  convertFrom(viewModelValue, bindingContext) {
    if (Array.isArray(viewModelValue)) {
      return viewModelValue.filter(this.filter)
    }
    return viewModelValue
  }
  
  convertTo(viewValue, bindingContext) {
    throw new Error('Only one-way conversion supported')
  }
}
  