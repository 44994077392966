import React from 'react'
import { useProperty } from 'eilmer-mvvm/react'

import AppViewModel from '#viewmodel/AppViewModel'
import ConfigViewModel from '#viewmodel/ConfigViewModel'

const AppContext = React.createContext()

function AppProvider({ children }) {
  const appViewModel = AppViewModel.useInstance()
  const websiteId = useProperty(appViewModel, 'websiteId')
  const configViewModel = ConfigViewModel.useInstance(websiteId)

  return (
    <AppContext.Provider value={{appViewModel, configViewModel}}>
      {children}
    </AppContext.Provider>
  )
}

export function useAppViewModel() {
  return React.useContext(AppContext).appViewModel
}

export function useConfigViewModel() {
  return React.useContext(AppContext).configViewModel
}

export function useWebsiteId() {
  return useProperty(useAppViewModel(), 'websiteId')
}

export function useConfigField(key) {
  const configViewModel = useConfigViewModel()
  const loaded = useProperty(configViewModel, 'loaded')
  const item = configViewModel.getConfigItem(key)
  return loaded ? item?.getField(configViewModel.valueFieldId) || null : undefined
}

export function useConfig(key) {
  const configViewModel = useConfigViewModel()
  const loaded = useProperty(configViewModel, 'loaded')
  return loaded ? configViewModel.getConfig(key) : undefined
}

export default AppProvider
