import TreeQLModel from './TreeQLModel'

export default class ItemTypeFieldValidatorsModel extends TreeQLModel {

  constructor(apiClient) {
    super(apiClient)
  }

  async getItemTypeFieldValidators(itemTypeFieldId) {
    const options = {filter: `item_type_field_id,eq,${itemTypeFieldId}`, join: 'validators', order: 'sequence'}
    return (await super.list('item_type_field_validators', options)).map(each => {
      each.validators = [each.validator_id]
      each.validator_id = each.validators[0]?.validator_id
      return new ItemTypeFieldValidatorsModel.ItemTypeFieldValidator(each)
    })
  }

  async createItemTypeFieldValidator(itemTypeFieldValidator) {
    const entity = new ItemTypeFieldValidatorsModel.ItemTypeFieldValidator(itemTypeFieldValidator)
    return await super.create('item_type_field_validators', entity)
  }

  async updateItemTypeFieldValidator(itemTypeFieldValidator) {
    const entity = new ItemTypeFieldValidatorsModel.ItemTypeFieldValidator(itemTypeFieldValidator, true)
    return await super.update('item_type_field_validators', entity.getPk(), entity)
  }

  async deleteItemTypeFieldValidator(itemTypeFieldValidator) {
    const entity = new ItemTypeFieldValidatorsModel.ItemTypeFieldValidator(itemTypeFieldValidator)
    return await super.delete('item_type_field_validators', entity.getPk())
  }

}

ItemTypeFieldValidatorsModel.ItemTypeFieldValidator = class extends TreeQLModel.Entity {

  constructor(fields, forUpdate) {
    super(fields, 'field_validator_id', forUpdate)
  }

}
