import React from 'react'
import jscrudapi from 'js-crud-api'

const ApiContext = React.createContext()

const API_URLS = { development: 'http://127.0.0.1:8000', production: '/api/api.php' }
//API_URLS.development = 'https://nano.malmesbury-live-arts.org.uk/api/api.php'
//API_URLS.development = 'https://nano.moriconium-quay.co.uk/api/api.php'
const API_URL = API_URLS[process.env.NODE_ENV || 'production']

const API_CLIENT = jscrudapi(API_URL, {credentials:'include'})
API_CLIENT.endpoint = API_URL

function ApiProvider({ children }) {
  return (
    <ApiContext.Provider value={API_CLIENT}>
      {children}
    </ApiContext.Provider>
  )
}

export function useApiClient() {
  return React.useContext(ApiContext)
}

export default ApiProvider
