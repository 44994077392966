export default class Privileges {

    static ALL = 1

    static APPLICATION = 2

    static USERS = 3

    static CONTENT = 4

    static WEBSITE = 5

    static DATA = 6

    static FORMS = 6

    static ROLES = 7

}