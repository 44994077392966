import TreeQLModel from './TreeQLModel'

export default class UserRolesModel extends TreeQLModel {

  constructor(apiClient) {
    super(apiClient)
    this.apolloClient = apiClient
  }

  async getUserRole(userRoleId) {
    const entity = await super.read('user_roles', userRoleId)
    return new UserRolesModel.UserRole(entity)
  }

  async getUserRoles() {
    return (await super.list('user_roles')).map(each => {
      return new UserRolesModel.UserRole(each)
    })
  }

  async createUserRole(userRole) {
    const entity = new UserRolesModel.UserRole(userRole)
    return await super.create('user_roles', entity)
  }

  async updateUserRole(userRole) {
    const entity = new UserRolesModel.UserRole(userRole, true)
    return await super.update('user_roles', entity.getPk(), entity)
  }

  async deleteUserRole(userRole) {
    const entity = new UserRolesModel.UserRole(userRole)
    return await super.delete('user_roles', entity.getPk())
  }

}

UserRolesModel.UserRole = class extends TreeQLModel.Entity {

  constructor(fields, forUpdate) {
    super(fields, 'user_role_id', forUpdate)
    this.description = this.description || ''
    this.precedence = Number(this.precedence || 0)
  }

}
