import React from 'react'
import { useBinding, useProperty } from 'eilmer-mvvm/react'
import * as Core from '@coreui/react'
import CIcon from '@coreui/icons-react'

import { useAppViewModel } from '#context/AppContext'
import EntityFilterConverter from '#converters/EntityFilterConverter'
import ItemTypeFieldsViewModel from '#viewmodel/ItemTypeFieldsViewModel'
import ItemTypesViewModel from '#viewmodel/ItemTypesViewModel'
import ItemClasses from '#primitives/ItemClasses'
import ItemTypes from '#utils/ItemTypes.js'
import Types from '#primitives/Types'

import * as Items from '#components/widgets/Items'
import * as Inputs from '#components/widgets/Inputs'
import * as Entities from '#components/widgets/Entities'
import HtmlEditor from '#components/misc/HtmlEditor'
import Pager from '#components/misc/Pager'
import Privileges from '#primitives/Privileges.js'
import { useAuthContext } from '#context/AuthContext.js'

export const Text = function ({ item, onChange }) {
  const textValue = useValue(item, 'text', onChange)

  // TODO: What about required field here?

  return (
    <Content item={item} onChange={onChange}>
      <Core.CFormGroup row>
        <Core.CCol xs="12">
          <HtmlEditor value={textValue.get()} onChange={textValue.set} />
        </Core.CCol>
      </Core.CFormGroup>
    </Content>
  )
}

export const Image = function ({ item, onChange }) {
  const imageValue = useMediaItemValue(item, 'media', onChange)
  const captionValue = useValue(item, 'caption', onChange)
  const linkValue = useValue(item, 'link', onChange, {})

  return (
    <Content item={item} onChange={onChange}>
      <Core.CFormGroup row>
        <Core.CCol xs="12">
          <Inputs.MediaItem
            innerRef={null}
            mimeType="image/*"
            required
            preview
            value={imageValue.get()}
            onChange={imageValue.set}
          />
        </Core.CCol>
      </Core.CFormGroup>
      <Core.CFormGroup row>
        <Core.CCol xs="12">
          <Core.CInput
            type="text"
            placeholder="Caption..."
            value={captionValue.get()}
            onChange={captionValue.set}
          />
        </Core.CCol>
      </Core.CFormGroup>
      <Core.CFormGroup row>
        <Core.CCol xs="12">
          <Inputs.Link
            innerRef={null}
            required={false}
            value={linkValue.get()}
            onChange={linkValue.set}
          />
        </Core.CCol>
      </Core.CFormGroup>
    </Content>
  )
}

export const ImageImage = function ({ item, onChange }) {
  const image1Value = useMediaItemValue(item, 'left_media', onChange)
  const image2Value = useMediaItemValue(item, 'right_media', onChange)
  const caption1Value = useValue(item, 'left_caption', onChange)
  const caption2Value = useValue(item, 'right_caption', onChange)

  return (
    <Content item={item} onChange={onChange}>
      <Core.CFormGroup row>
        <Core.CCol xs="12">
          <Inputs.MediaItem
            innerRef={null}
            mimeType="image/*"
            required
            preview
            placeholder="Left Image..."
            value={image1Value.get()}
            onChange={image1Value.set}
          />
        </Core.CCol>
      </Core.CFormGroup>
      <Core.CFormGroup row>
        <Core.CCol xs="12">
          <Core.CInput
            type="text"
            placeholder="Caption..."
            value={caption1Value.get()}
            onChange={caption1Value.set}
          />
        </Core.CCol>
      </Core.CFormGroup>
      <Core.CFormGroup row>
        <Core.CCol xs="12">
          <Inputs.MediaItem
            innerRef={null}
            mimeType="image/*"
            required
            preview
            placeholder="Right Image..."
            value={image2Value.get()}
            onChange={image2Value.set}
          />
        </Core.CCol>
      </Core.CFormGroup>
      <Core.CFormGroup row>
        <Core.CCol xs="12">
          <Core.CInput
            type="text"
            placeholder="Caption..."
            value={caption2Value.get()}
            onChange={caption2Value.set}
          />
        </Core.CCol>
      </Core.CFormGroup>
    </Content>
  )
}

export const TextImage = function ({ item, onChange }) {
  const textValue = useValue(item, 'text', onChange)
  const imageValue = useMediaItemValue(item, 'media', onChange)
  const alignValue = useValue(item, 'alignment', onChange, 'left')
  const captionValue = useValue(item, 'caption', onChange)

  return (
    <Content item={item} onChange={onChange}>
      <Core.CFormGroup row>
        <Core.CCol md="3">
          <Core.CLabel>Image Alignment</Core.CLabel>
        </Core.CCol>
        <Core.CCol xs="12" md="9">
          <Core.CSelect
            custom
            value={alignValue.get()}
            onChange={alignValue.set}
          >
            <option value="left">Left</option>
            <option value="right">Right</option>
          </Core.CSelect>
        </Core.CCol>
      </Core.CFormGroup>
      <Core.CFormGroup row>
        <Core.CCol xs="12">
          <HtmlEditor value={textValue.get()} onChange={textValue.set} />
        </Core.CCol>
      </Core.CFormGroup>
      <Core.CFormGroup row>
        <Core.CCol xs="12">
          <Inputs.MediaItem
            innerRef={null}
            mimeType="image/*"
            required
            preview
            value={imageValue.get()}
            onChange={imageValue.set}
          />
        </Core.CCol>
      </Core.CFormGroup>
      <Core.CFormGroup row>
        <Core.CCol xs="12">
          <Core.CInput
            type="text"
            placeholder="Caption..."
            value={captionValue.get()}
            onChange={captionValue.set}
          />
        </Core.CCol>
      </Core.CFormGroup>
    </Content>
  )
}

export const YouTubeVideo = function ({ item, onChange }) {
  const srcValue = useValue(item, 'embed_src', onChange)

  return (
    <Content item={item} onChange={onChange}>
      <Core.CFormGroup row>
        <Core.CCol xs="12">
          <Core.CInput
            required
            type="url"
            placeholder="Video Embed URL..."
            value={srcValue.get()}
            onChange={srcValue.set}
          />
        </Core.CCol>
      </Core.CFormGroup>
    </Content>
  )
}

export const VimeoVideo = function ({ item, onChange }) {
  const srcValue = useValue(item, 'embed_src', onChange)

  return (
    <Content item={item} onChange={onChange}>
      <Core.CFormGroup row>
        <Core.CCol xs="12">
          <Core.CInput
            required
            type="url"
            placeholder="Video Embed URL..."
            value={srcValue.get()}
            onChange={srcValue.set}
          />
        </Core.CCol>
      </Core.CFormGroup>
    </Content>
  )
}

export const Button = function ({ item, onChange }) {
  const labelValue = useValue(item, 'label', onChange)
  const linkTypeValue = useValue(item, 'link_type', onChange, 'page')
  const pageIdValue = useValue(item, 'page_id', onChange)
  const hrefValue = useValue(item, 'href', onChange)

  // TODO: Hardcoded website ID below

  return (
    <Content item={item} onChange={onChange}>
      <Core.CFormGroup row>
        <Core.CCol md="3">
          <Core.CLabel>Link Type</Core.CLabel>
        </Core.CCol>
        <Core.CCol xs="12" md="9">
          <Core.CSelect
            custom
            value={linkTypeValue.get()}
            onChange={linkTypeValue.set}
          >
            <option value="page">Internal (Page)</option>
            <option value="external">External</option>
          </Core.CSelect>
        </Core.CCol>
      </Core.CFormGroup>
      {linkTypeValue.get() === 'external' && (
        <Core.CFormGroup row>
          <Core.CCol xs="12">
            <Core.CInput
              required
              type="url"
              placeholder="Link URL..."
              value={hrefValue.get()}
              onChange={hrefValue.set}
            />
          </Core.CCol>
        </Core.CFormGroup>
      )}
      {linkTypeValue.get() === 'page' && (
        <Core.CFormGroup row>
          <Core.CCol xs="12">
            <Items.ItemInput
              innerRef={null}
              websiteId={1}
              itemTypeId={ItemTypes.PAGE}
              required={true}
              value={pageIdValue.get()}
              onChange={pageIdValue.set}
            />
          </Core.CCol>
        </Core.CFormGroup>
      )}
      <Core.CFormGroup row>
        <Core.CCol xs="12">
          <Core.CInput
            required
            type="text"
            placeholder="Button Label..."
            value={labelValue.get()}
            onChange={labelValue.set}
          />
        </Core.CCol>
      </Core.CFormGroup>
    </Content>
  )
}

export const ItemList = function ({ item, onChange }) {
  const listStyleValue = useValue(item, 'listStyle', onChange, 'none')
  const itemTypeValue = useValue(item, 'itemType', onChange)
  const sortFieldValue = useValue(item, 'sortField', onChange)
  const sortDirValue = useValue(item, 'sortDirection', onChange, 'ASC')
  const fieldsViewModel = ItemTypeFieldsViewModel.useInstance(
    itemTypeValue.get()
  )

  return (
    <Content item={item} onChange={onChange}>
      <Core.CFormGroup row>
        <Core.CCol md="3">
          <Core.CLabel>List Style</Core.CLabel>
        </Core.CCol>
        <Core.CCol xs="12" md="9">
          <Core.CSelect
            custom
            value={listStyleValue.get()}
            onChange={listStyleValue.set}
          >
            <option value="none">Plain</option>
            <option value="disc">Bulleted</option>
            <option value="decimal">Numbered</option>
          </Core.CSelect>
        </Core.CCol>
      </Core.CFormGroup>
      <Core.CFormGroup row>
        <Core.CCol md="3">
          <Core.CLabel>Item Type</Core.CLabel>
        </Core.CCol>
        <Core.CCol xs="12" md="9">
          <Inputs.ItemType
            required
            itemClassId={ItemClasses.DATA}
            value={itemTypeValue.get()}
            onChange={itemTypeValue.set}
          />
        </Core.CCol>
      </Core.CFormGroup>
      <Core.CFormGroup row>
        <Core.CCol md="3">
          <Core.CLabel>Sort Order</Core.CLabel>
        </Core.CCol>
        <Core.CCol xs="12" md="9">
          <Entities.EntityDropdown
            required={false}
            value={sortFieldValue.get()}
            onChange={sortFieldValue.set}
            entityViewModel={fieldsViewModel}
          />
        </Core.CCol>
      </Core.CFormGroup>
      <Core.CFormGroup row>
        <Core.CCol md="3">
          <Core.CLabel>Sort Direction</Core.CLabel>
        </Core.CCol>
        <Core.CCol xs="12" md="9">
          <Core.CSelect
            custom
            value={sortDirValue.get()}
            onChange={sortDirValue.set}
          >
            <option value="ASC">Ascending</option>
            <option value="DESC">Descending</option>
          </Core.CSelect>
        </Core.CCol>
      </Core.CFormGroup>
    </Content>
  )
}

export const Carousel = function ({ item, onChange }) {
  const states = new Array(8)

  states[0] = {
    labelValue: useValue(item, 'label1', onChange),
    pageIdValue: useValue(item, 'page1', onChange),
    mediaItem: useMediaItemValue(item, 'mediaObject1', onChange),
    textValue: useValue(item, 'text1', onChange),
  }
  states[1] = {
    labelValue: useValue(item, 'label2', onChange),
    pageIdValue: useValue(item, 'page2', onChange),
    mediaItem: useMediaItemValue(item, 'mediaObject2', onChange),
    textValue: useValue(item, 'text2', onChange),
  }
  states[2] = {
    labelValue: useValue(item, 'label3', onChange),
    pageIdValue: useValue(item, 'page3', onChange),
    mediaItem: useMediaItemValue(item, 'mediaObject3', onChange),
    textValue: useValue(item, 'text3', onChange),
  }
  states[3] = {
    labelValue: useValue(item, 'label4', onChange),
    pageIdValue: useValue(item, 'page4', onChange),
    mediaItem: useMediaItemValue(item, 'mediaObject4', onChange),
    textValue: useValue(item, 'text4', onChange),
  }

  states[4] = {
    labelValue: useValue(item, 'label5', onChange),
    pageIdValue: useValue(item, 'page5', onChange),
    mediaItem: useMediaItemValue(item, 'mediaObject5', onChange),
    textValue: useValue(item, 'text5', onChange),
  }
  states[5] = {
    labelValue: useValue(item, 'label6', onChange),
    pageIdValue: useValue(item, 'page6', onChange),
    mediaItem: useMediaItemValue(item, 'mediaObject6', onChange),
    textValue: useValue(item, 'text6', onChange),
  }
  states[6] = {
    labelValue: useValue(item, 'label7', onChange),
    pageIdValue: useValue(item, 'page7', onChange),
    mediaItem: useMediaItemValue(item, 'mediaObject7', onChange),
    textValue: useValue(item, 'text7', onChange),
  }
  states[7] = {
    labelValue: useValue(item, 'label8', onChange),
    pageIdValue: useValue(item, 'page8', onChange),
    mediaItem: useMediaItemValue(item, 'mediaObject8', onChange),
    textValue: useValue(item, 'text8', onChange),
  }

  return (
    <Content item={item} onChange={onChange}>
      <Pager pages={states.length}>
        {states.map((state, idx) => (
          <div key={idx}>
            <Core.CFormGroup row>
              <Core.CCol md="3">
                <Core.CLabel>Button Label</Core.CLabel>
              </Core.CCol>
              <Core.CCol xs="12" md="9">
                <Core.CInput
                  required
                  type="text"
                  placeholder="Button Label..."
                  value={state.labelValue.get()}
                  onChange={state.labelValue.set}
                />
              </Core.CCol>
            </Core.CFormGroup>
            <Core.CFormGroup row>
              <Core.CCol md="3">
                <Core.CLabel>Button Link</Core.CLabel>
              </Core.CCol>
              <Core.CCol xs="12" md="9">
                <Items.ItemInput
                  innerRef={null}
                  websiteId={1}
                  itemTypeId={ItemTypes.PAGE}
                  required={true}
                  value={state.pageIdValue.get()}
                  onChange={state.pageIdValue.set}
                />
              </Core.CCol>
            </Core.CFormGroup>
            <Core.CFormGroup row>
              <Inputs.MediaItem
                innerRef={null}
                mimeType="image/*"
                required={idx === 0}
                preview
                value={state.mediaItem.get()}
                onChange={state.mediaItem.set}
              />
            </Core.CFormGroup>
            <Core.CFormGroup row>
              <Core.CCol xs="12">
                <HtmlEditor
                  value={state.textValue.get()}
                  onChange={state.textValue.set}
                />
              </Core.CCol>
            </Core.CFormGroup>
          </div>
        ))}
      </Pager>
    </Content>
  )
}

export const Form = function ({ item, onChange }) {
  const itemTypeValue = useValue(item, 'itemType', onChange)
  const sendName = useValue(item, 'sendName', onChange)
  const sendEmailAddress = useValue(item, 'sendEmailAddress', onChange)
  const bccEmailAddress = useValue(item, 'bccEmailAddress', onChange)
  const emailSubject = useValue(item, 'emailSubject', onChange)
  const submitText = useValue(item, 'submitText', onChange)

  return (
    <Content item={item} onChange={onChange}>
      <Core.CFormGroup row>
        <Core.CCol md="3">
          <Core.CLabel>Form Type:</Core.CLabel>
        </Core.CCol>
        <Core.CCol xs="12" md="9">
          <Inputs.ItemType
            innerRef={null}
            itemClassId={ItemClasses.FORM}
            required
            value={itemTypeValue.get()}
            onChange={itemTypeValue.set}
          />
        </Core.CCol>
      </Core.CFormGroup>
      <Core.CFormGroup row>
        <Core.CCol md="3">
          <Core.CLabel>Email To:</Core.CLabel>
        </Core.CCol>
        <Core.CCol xs="12" md="9">
          <Core.CInput
            innerRef={null}
            type="email"
            value={sendEmailAddress.get()}
            onChange={sendEmailAddress.set}
            placeholder="Leave blank for no email"
          />
        </Core.CCol>
      </Core.CFormGroup>
      <Core.CFormGroup row>
        <Core.CCol md="3">
          <Core.CLabel>Email To Name:</Core.CLabel>
        </Core.CCol>
        <Core.CCol xs="12" md="9">
          <Core.CInput
            innerRef={null}
            value={sendName.get()}
            onChange={sendName.set}
            placeholder="Defaults to above address"
          />
        </Core.CCol>
      </Core.CFormGroup>
      <Core.CFormGroup row>
        <Core.CCol md="3">
          <Core.CLabel>BCC To:</Core.CLabel>
        </Core.CCol>
        <Core.CCol xs="12" md="9">
          <Core.CInput
            innerRef={null}
            type="email"
            value={bccEmailAddress.get()}
            onChange={bccEmailAddress.set}
            placeholder="Leave blank for no BCC"
          />
        </Core.CCol>
      </Core.CFormGroup>
      <Core.CFormGroup row>
        <Core.CCol md="3">
          <Core.CLabel>Email Subject:</Core.CLabel>
        </Core.CCol>
        <Core.CCol xs="12" md="9">
          <Core.CInput
            innerRef={null}
            type="text"
            value={emailSubject.get()}
            onChange={emailSubject.set}
          />
        </Core.CCol>
      </Core.CFormGroup>
      <Core.CFormGroup row>
        <Core.CCol md="3">
          <Core.CLabel>Submit Button Text:</Core.CLabel>
        </Core.CCol>
        <Core.CCol xs="12" md="9">
          <Core.CInput
            innerRef={null}
            type="text"
            value={submitText.get()}
            onChange={submitText.set}
          />
        </Core.CCol>
      </Core.CFormGroup>
    </Content>
  )
}

export const Blog = function ({ item, onChange }) {
  const itemTypeValue = useValue(item, 'itemType', onChange)
  const titleFieldValue = useValue(item, 'titleField', onChange)
  const dateFieldValue = useValue(item, 'publishDateField', onChange)
  const summaryFieldValue = useValue(item, 'summaryField', onChange)
  const thumbnailFieldValue = useValue(item, 'thumbnailField', onChange)
  const typesViewModel = ItemTypesViewModel.useInstance(ItemClasses.CONTENT)
  const fieldsViewModel = ItemTypeFieldsViewModel.useInstance(
    itemTypeValue.get()
  )
  // Exclude Pages - they are not allowed to be used as Blog items
  const typesConverter = React.useMemo(
    () =>
      new EntityFilterConverter((each) => each.item_type_id !== ItemTypes.PAGE)
  )
  const textConverter = React.useMemo(
    () =>
      new EntityFilterConverter(
        (each) => each.getFieldType().type_id === Types.TEXT
      )
  )
  const dateConverter = React.useMemo(
    () =>
      new EntityFilterConverter(
        (each) => each.getFieldType().type_id === Types.DATE
      )
  )
  const htmlConverter = React.useMemo(
    () =>
      new EntityFilterConverter(
        (each) => each.getFieldType().type_id === Types.HTML
      )
  )
  const mediaConverter = React.useMemo(
    () =>
      new EntityFilterConverter(
        (each) => each.getFieldType().item_type_id === 8
      )
  )

  return (
    <Content item={item} onChange={onChange}>
      <Core.CFormGroup row>
        <Core.CCol md="3">
          <Core.CLabel>Blog Item Type:</Core.CLabel>
        </Core.CCol>
        <Core.CCol xs="12" md="9">
          <Entities.EntityDropdown
            required={true}
            value={itemTypeValue.get()}
            onChange={itemTypeValue.set}
            entityViewModel={typesViewModel}
            converter={typesConverter}
          />
          <small>
            <em>Specifies which item type to use for blog posts</em>
          </small>
        </Core.CCol>
      </Core.CFormGroup>
      <Core.CFormGroup row>
        <Core.CCol md="3">
          <Core.CLabel>Title Field:</Core.CLabel>
        </Core.CCol>
        <Core.CCol xs="12" md="9">
          <Entities.EntityDropdown
            required={true}
            value={titleFieldValue.get()}
            onChange={titleFieldValue.set}
            entityViewModel={fieldsViewModel}
            converter={textConverter}
          />
          <small>
            <em>The field to use for blog post titles</em>
          </small>
        </Core.CCol>
      </Core.CFormGroup>
      <Core.CFormGroup row>
        <Core.CCol md="3">
          <Core.CLabel>Publish Date Field:</Core.CLabel>
        </Core.CCol>
        <Core.CCol xs="12" md="9">
          <Entities.EntityDropdown
            required={true}
            value={dateFieldValue.get()}
            onChange={dateFieldValue.set}
            entityViewModel={fieldsViewModel}
            converter={dateConverter}
          />
          <small>
            <em>The field to use for blog post publish dates</em>
          </small>
        </Core.CCol>
      </Core.CFormGroup>
      <Core.CFormGroup row>
        <Core.CCol md="3">
          <Core.CLabel>Summary Field:</Core.CLabel>
        </Core.CCol>
        <Core.CCol xs="12" md="9">
          <Entities.EntityDropdown
            required={true}
            value={summaryFieldValue.get()}
            onChange={summaryFieldValue.set}
            entityViewModel={fieldsViewModel}
            converter={htmlConverter}
          />
          <small>
            <em>The field to use for blog post summaries</em>
          </small>
        </Core.CCol>
      </Core.CFormGroup>
      <Core.CFormGroup row>
        <Core.CCol md="3">
          <Core.CLabel>Thumbnail Field:</Core.CLabel>
        </Core.CCol>
        <Core.CCol xs="12" md="9">
          <Entities.EntityDropdown
            required={true}
            value={thumbnailFieldValue.get()}
            onChange={thumbnailFieldValue.set}
            entityViewModel={fieldsViewModel}
            converter={mediaConverter}
          />
          <small>
            <em>The field to use for blog post summaries</em>
          </small>
        </Core.CCol>
      </Core.CFormGroup>
    </Content>
  )
}

export const Faq = function ({ item, onChange }) {
  const itemTypeValue = useValue(item, 'itemType', onChange)
  const questionFieldValue = useValue(item, 'questionField', onChange)
  const answerFieldValue = useValue(item, 'answerField', onChange)
  const typesViewModel = ItemTypesViewModel.useInstance(ItemClasses.DATA)
  const fieldsViewModel = ItemTypeFieldsViewModel.useInstance(
    itemTypeValue.get()
  )
  // Exclude Pages - they are not allowed to be used
  const typesConverter = React.useMemo(
    () =>
      new EntityFilterConverter((each) => each.item_type_id !== ItemTypes.Page)
  )
  const textConverter = React.useMemo(
    () =>
      new EntityFilterConverter(
        (each) => each.getFieldType().type_id === Types.TEXT
      )
  )
  const htmlConverter = React.useMemo(
    () =>
      new EntityFilterConverter(
        (each) => each.getFieldType().type_id === Types.HTML
      )
  )

  return (
    <Content item={item} onChange={onChange}>
      <Core.CFormGroup row>
        <Core.CCol md="3">
          <Core.CLabel>Item Type:</Core.CLabel>
        </Core.CCol>
        <Core.CCol xs="12" md="9">
          <Entities.EntityDropdown
            required={true}
            value={itemTypeValue.get()}
            onChange={itemTypeValue.set}
            entityViewModel={typesViewModel}
            converter={typesConverter}
          />
          <small>
            <em>Specifies which item type to use for FAQs</em>
          </small>
        </Core.CCol>
      </Core.CFormGroup>
      <Core.CFormGroup row>
        <Core.CCol md="3">
          <Core.CLabel>Question Field:</Core.CLabel>
        </Core.CCol>
        <Core.CCol xs="12" md="9">
          <Entities.EntityDropdown
            required={true}
            value={questionFieldValue.get()}
            onChange={questionFieldValue.set}
            entityViewModel={fieldsViewModel}
            converter={textConverter}
          />
          <small>
            <em>The field to use for FAQ questions</em>
          </small>
        </Core.CCol>
      </Core.CFormGroup>
      <Core.CFormGroup row>
        <Core.CCol md="3">
          <Core.CLabel>Answer Field:</Core.CLabel>
        </Core.CCol>
        <Core.CCol xs="12" md="9">
          <Entities.EntityDropdown
            required={true}
            value={answerFieldValue.get()}
            onChange={answerFieldValue.set}
            entityViewModel={fieldsViewModel}
            converter={htmlConverter}
          />
          <small>
            <em>The field to use for FAQ answers</em>
          </small>
        </Core.CCol>
      </Core.CFormGroup>
    </Content>
  )
}

export const Events = function ({ item, onChange }) {
  const itemClasses = [ItemClasses.CONTENT, ItemClasses.DATA]
  const itemTypeValue = useValue(item, 'itemType', onChange)
  const nameFieldValue = useValue(item, 'nameField', onChange)
  const startDateFieldValue = useValue(item, 'startDateField', onChange)
  const startTimeFieldValue = useValue(item, 'startTimeField', onChange)
  const endDateFieldValue = useValue(item, 'endDateField', onChange)
  const endTimeFieldValue = useValue(item, 'endTimeField', onChange)
  const summaryFieldValue = useValue(item, 'summaryField', onChange)
  const thumbnailFieldValue = useValue(item, 'thumbnailField', onChange)
  const filterValue = useValue(item, 'filter', onChange, 'future')

  const typesViewModel = ItemTypesViewModel.useInstance()
  const fieldsViewModel = ItemTypeFieldsViewModel.useInstance(
    itemTypeValue.get()
  )
  // Exclude Pages - they are not allowed to be used
  const typesConverter = React.useMemo(
    () =>
      new EntityFilterConverter(
        (each) =>
          itemClasses.includes(each.item_class_id) &&
          each.item_type_id !== ItemTypes.PAGE
      )
  )
  const textConverter = React.useMemo(
    () =>
      new EntityFilterConverter(
        (each) => each.getFieldType().type_id === Types.TEXT
      )
  )
  const htmlConverter = React.useMemo(
    () =>
      new EntityFilterConverter(
        (each) => each.getFieldType().type_id === Types.HTML
      )
  )
  const dateConverter = React.useMemo(
    () =>
      new EntityFilterConverter(
        (each) => each.getFieldType().type_id === Types.DATE
      )
  )
  const timeConverter = React.useMemo(
    () =>
      new EntityFilterConverter(
        (each) => each.getFieldType().type_id === Types.TIME
      )
  )
  const mediaConverter = React.useMemo(
    () =>
      new EntityFilterConverter(
        (each) => each.getFieldType().item_type_id === 8
      )
  )

  // TODO: All these blocks should use a validating form just like in menus.js
  // so that the page cannot be saved if required fields are not set.

  return (
    <Content item={item} onChange={onChange}>
      <Core.CFormGroup row>
        <Core.CCol md="3">
          <Core.CLabel>Item Type:</Core.CLabel>
        </Core.CCol>
        <Core.CCol xs="12" md="9">
          <Entities.EntityDropdown
            required={true}
            value={itemTypeValue.get()}
            onChange={itemTypeValue.set}
            entityViewModel={typesViewModel}
            converter={typesConverter}
          />
          <small>
            <em>Specifies which item type to use for Events</em>
          </small>
        </Core.CCol>
      </Core.CFormGroup>
      <Core.CFormGroup row>
        <Core.CCol md="3">
          <Core.CLabel>Name Field:</Core.CLabel>
        </Core.CCol>
        <Core.CCol xs="12" md="9">
          <Entities.EntityDropdown
            required={true}
            value={nameFieldValue.get()}
            onChange={nameFieldValue.set}
            entityViewModel={fieldsViewModel}
            converter={textConverter}
          />
          <small>
            <em>The field to use for the Event name</em>
          </small>
        </Core.CCol>
      </Core.CFormGroup>
      <Core.CFormGroup row>
        <Core.CCol md="3">
          <Core.CLabel>Start Date Field:</Core.CLabel>
        </Core.CCol>
        <Core.CCol xs="12" md="9">
          <Entities.EntityDropdown
            required={true}
            value={startDateFieldValue.get()}
            onChange={startDateFieldValue.set}
            entityViewModel={fieldsViewModel}
            converter={dateConverter}
          />
          <small>
            <em>The field to use for the Event start date</em>
          </small>
        </Core.CCol>
      </Core.CFormGroup>
      <Core.CFormGroup row>
        <Core.CCol md="3">
          <Core.CLabel>Start Time Field:</Core.CLabel>
        </Core.CCol>
        <Core.CCol xs="12" md="9">
          <Entities.EntityDropdown
            required={false}
            value={startTimeFieldValue.get()}
            onChange={startTimeFieldValue.set}
            entityViewModel={fieldsViewModel}
            converter={timeConverter}
            nullLabel="No Start Time"
          />
          <small>
            <em>The field to use for the Event start time</em>
          </small>
        </Core.CCol>
      </Core.CFormGroup>
      <Core.CFormGroup row>
        <Core.CCol md="3">
          <Core.CLabel>End Date Field:</Core.CLabel>
        </Core.CCol>
        <Core.CCol xs="12" md="9">
          <Entities.EntityDropdown
            required={false}
            value={endDateFieldValue.get()}
            onChange={endDateFieldValue.set}
            entityViewModel={fieldsViewModel}
            converter={dateConverter}
            nullLabel="No End Date"
          />
          <small>
            <em>The field to use for the Event end date</em>
          </small>
        </Core.CCol>
      </Core.CFormGroup>
      <Core.CFormGroup row>
        <Core.CCol md="3">
          <Core.CLabel>End Time Field:</Core.CLabel>
        </Core.CCol>
        <Core.CCol xs="12" md="9">
          <Entities.EntityDropdown
            required={false}
            value={endTimeFieldValue.get()}
            onChange={endTimeFieldValue.set}
            entityViewModel={fieldsViewModel}
            converter={timeConverter}
            nullLabel="No End Time"
          />
          <small>
            <em>The field to use for the Event end time</em>
          </small>
        </Core.CCol>
      </Core.CFormGroup>
      <Core.CFormGroup row>
        <Core.CCol md="3">
          <Core.CLabel>Summary Field:</Core.CLabel>
        </Core.CCol>
        <Core.CCol xs="12" md="9">
          <Entities.EntityDropdown
            required={true}
            value={summaryFieldValue.get()}
            onChange={summaryFieldValue.set}
            entityViewModel={fieldsViewModel}
            converter={htmlConverter}
          />
          <small>
            <em>The field to use for the Event summary</em>
          </small>
        </Core.CCol>
      </Core.CFormGroup>
      <Core.CFormGroup row>
        <Core.CCol md="3">
          <Core.CLabel>Thumbnail Field:</Core.CLabel>
        </Core.CCol>
        <Core.CCol xs="12" md="9">
          <Entities.EntityDropdown
            required={false}
            value={thumbnailFieldValue.get()}
            onChange={thumbnailFieldValue.set}
            entityViewModel={fieldsViewModel}
            converter={mediaConverter}
            nullLabel="No Thumbnail"
          />
          <small>
            <em>The field to use for the Event thumbnail</em>
          </small>
        </Core.CCol>
      </Core.CFormGroup>
      <Core.CFormGroup row>
        <Core.CCol md="3">
          <Core.CLabel>Filter:</Core.CLabel>
        </Core.CCol>
        <Core.CCol xs="12" md="9">
          <Core.CSelect
            custom
            value={filterValue.get()}
            onChange={filterValue.set}
          >
            <option value="future">Future Events</option>
            <option value="past">Past Events</option>
          </Core.CSelect>
          <small>
            <em>Shows only the given Events</em>
          </small>
        </Core.CCol>
      </Core.CFormGroup>
    </Content>
  )
}

export const Divider = function ({ item, onChange }) {
  const drawRuleValue = useBooleanValue(item, 'drawRule', onChange, true)

  return (
    <Content item={item} onChange={onChange}>
      <Core.CFormGroup row>
        <Core.CCol md="3">
          <Core.CLabel>Draw Rule:</Core.CLabel>
        </Core.CCol>
        <Core.CCol xs="12" md="9">
          <div>
            <Core.CSwitch
              shape="pill"
              color="primary"
              checked={drawRuleValue.get()}
              onChange={drawRuleValue.set}
            />
          </div>
          <small>
            <em>Draw a line across the divier</em>
          </small>
        </Core.CCol>
      </Core.CFormGroup>
    </Content>
  )
}

export const Card = function ({ item, onChange }) {
  const textValue = useValue(item, 'text', onChange)
  const imageValue = useMediaItemValue(item, 'media', onChange)
  const alignValue = useValue(item, 'alignment', onChange, 'left')

  return (
    <Content item={item} onChange={onChange}>
      <Core.CFormGroup row>
        <Core.CCol md="3">
          <Core.CLabel>Image Alignment</Core.CLabel>
        </Core.CCol>
        <Core.CCol xs="12" md="9">
          <Core.CSelect
            custom
            value={alignValue.get()}
            onChange={alignValue.set}
          >
            <option value="left">Left</option>
            <option value="right">Right</option>
            <option value="above">Above</option>
          </Core.CSelect>
        </Core.CCol>
      </Core.CFormGroup>
      <Core.CFormGroup row>
        <Core.CCol xs="12">
          <HtmlEditor value={textValue.get()} onChange={textValue.set} />
        </Core.CCol>
      </Core.CFormGroup>
      <Core.CFormGroup row>
        <Core.CCol xs="12">
          <Inputs.MediaItem
            innerRef={null}
            mimeType="image/*"
            required
            preview
            value={imageValue.get()}
            onChange={imageValue.set}
          />
        </Core.CCol>
      </Core.CFormGroup>
    </Content>
  )
}

export const Collapse = function ({ item, onChange }) {
  const titleValue = useValue(item, 'title', onChange)
  const textValue = useValue(item, 'text', onChange)

  return (
    <Content item={item} onChange={onChange}>
      <Core.CFormGroup row>
        <Core.CCol xs="12">
          <Core.CInput
            type="text"
            placeholder="Title..."
            value={titleValue.get()}
            onChange={titleValue.set}
          />
        </Core.CCol>
      </Core.CFormGroup>
      <Core.CFormGroup row>
        <Core.CCol xs="12">
          <HtmlEditor value={textValue.get()} onChange={textValue.set} />
        </Core.CCol>
      </Core.CFormGroup>
    </Content>
  )
}

export const Login = function ({ item, onChange }) {
  return <Content item={item} onChange={onChange}></Content>
}

export const Html = function ({ item, onChange }) {
  const permissions = useProperty(useAuthContext(), 'permissions')
  const editable = permissions?.hasPrivilege(Privileges.APPLICATION)
  const textValue = useValue(item, 'code', onChange)

  return (
    <Content item={item} onChange={onChange}>
      <Core.CAlert color="warning">
        This feature is accessible to developers only
      </Core.CAlert>
      <Core.CFormGroup row>
        <Core.CCol xs="12">
          <textarea
            className="form-control"
            readOnly={!editable}
            value={textValue.get()}
            onChange={textValue.set}
          />
        </Core.CCol>
      </Core.CFormGroup>
    </Content>
  )
}

export const Gallery = function ({ item, onChange }) {
  const mediaFolderValue = useValue(item, 'mediaFolder', onChange)

  return (
    <Content item={item} onChange={onChange}>
      <Core.CFormGroup row>
        <Core.CCol md="3">
          <Core.CLabel>Media Folder</Core.CLabel>
        </Core.CCol>
        <Core.CCol xs="12" md="9">
          <Items.ItemInput
            innerRef={null}
            websiteId={1}
            itemTypeId={ItemTypes.MEDIA_FOLDER}
            required={true}
            value={mediaFolderValue.get()}
            onChange={mediaFolderValue.set}
          />
        </Core.CCol>
      </Core.CFormGroup>
    </Content>
  )
}

export const Item = function ({ item, onChange }) {
  const itemTypeValue = useValue(item, 'itemType', onChange)
  const itemValue = useValue(item, 'item', onChange)

  const typesViewModel = ItemTypesViewModel.useInstance()
  const typesConverter = React.useMemo(
    () =>
      new EntityFilterConverter(
        (each) => each.item_class_id === ItemClasses.DATA
      )
  )

  return (
    <Content item={item} onChange={onChange}>
      <Core.CAlert color="warning">
        Not all item types are supported, dependent on the webiste.
      </Core.CAlert>
      <Core.CFormGroup row>
        <Core.CCol md="3">
          <Core.CLabel>Item Type</Core.CLabel>
        </Core.CCol>
        <Core.CCol xs="12" md="9">
          <Entities.EntityDropdown
            required={true}
            value={itemTypeValue.get()}
            onChange={itemTypeValue.set}
            entityViewModel={typesViewModel}
            converter={typesConverter}
          />
        </Core.CCol>
      </Core.CFormGroup>
      <Core.CFormGroup row>
        <Core.CCol md="3">
          <Core.CLabel>Item</Core.CLabel>
        </Core.CCol>
        <Core.CCol xs="12" md="9">
          <Items.ItemInput
            innerRef={null}
            websiteId={1}
            itemTypeId={itemTypeValue.get()}
            required={true}
            value={itemValue.get()}
            onChange={itemValue.set}
          />
        </Core.CCol>
      </Core.CFormGroup>
    </Content>
  )
}

function Content({ item, onChange, children }) {
  const appViewModel = useAppViewModel()

  const changedHandler = () => {
    appViewModel.unsavedChanges = true
    if (typeof onChange === 'function') {
      onChange()
    }
  }

  return (
    <Core.CTabs activeTab={children ? 'basic' : 'names'}>
      <Core.CNav variant="tabs">
        <Core.CNavItem>
          <Core.CNavLink data-tab="names">
            <CIcon name="cil-settings" />
          </Core.CNavLink>
        </Core.CNavItem>
        {children && (
          <Core.CNavItem>
            <Core.CNavLink data-tab="basic">
              <CIcon name="cil-border-all" />
            </Core.CNavLink>
          </Core.CNavItem>
        )}
      </Core.CNav>
      <Core.CTabContent className="pt-3">
        <Core.CTabPane data-tab="names">
          <Items.ItemEditor
            item={item}
            itemTypeId={ItemTypes.CONTENT_BLOCK}
            changedHandler={changedHandler}
          />
        </Core.CTabPane>
        <Core.CTabPane data-tab="basic">{children}</Core.CTabPane>
      </Core.CTabContent>
    </Core.CTabs>
  )
}

function useValue(item, key, changeHandler, dflt) {
  const appViewModel = useAppViewModel()
  const [value, setValue] = useBinding(item.properties, key)

  if (typeof value === 'undefined' && typeof dflt !== 'undefined') {
    setValue(dflt)
  }

  return {
    get: () => {
      return value || dflt || ''
    },
    set: (e) => {
      appViewModel.unsavedChanges = true
      setValue(e.target.value)
      if (typeof changeHandler === 'function') {
        changeHandler(e.target.value)
      }
    },
  }
}

function useBooleanValue(item, key, changeHandler, dflt) {
  const appViewModel = useAppViewModel()
  const [value, setValue] = useBinding(item.properties, key)

  if (typeof value === 'undefined' && typeof dflt !== 'undefined') {
    setValue(dflt)
  }

  return {
    get: () => {
      if (typeof value === 'boolean') return value
      if (typeof dflt === 'boolean') return dflt
      return false
    },
    set: (e) => {
      appViewModel.unsavedChanges = true
      setValue(e.target.checked)
      if (typeof changeHandler === 'function') {
        changeHandler(e.target.checked)
      }
    },
  }
}

function useMediaItemValue(item, key, changeHandler) {
  const appViewModel = useAppViewModel()
  const [mediaObject, setMediaObject] = useBinding(
    item.properties,
    key || 'media'
  )
  // useExpression not exported yet
  // const [mediaObject, setMediaObject] = useExpression(item, `properties.${key || 'media'}`)

  return {
    get: () => {
      return mediaObject?.id
    },
    set: (e) => {
      appViewModel.unsavedChanges = true
      const mediaObject = Object.assign({}, e.target.mediaObject)
      delete mediaObject.record
      setMediaObject(mediaObject)
      if (typeof changeHandler === 'function') {
        changeHandler(mediaObject)
      }
    },
  }
}
