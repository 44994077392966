import React from 'react'
import { useInstanceOf, useBinding, useProperty } from 'eilmer-mvvm/react'

import { useAppViewModel } from '#context/AppContext'

import FieldValuesConverter from '#converters/FieldValuesConverter'

import * as Forms from '#components/widgets/Forms'
import * as Fields from '#components/widgets/Fields'

export const UserFieldsEditor = function({ user, fields, changedHandler, vertical, children }) {
  const websiteId = useProperty(useAppViewModel(), 'websiteId')
  const converter = useInstanceOf(FieldValuesConverter)
  const [ fieldValues, setFieldValues ] = useBinding(user, 'user_field_values', converter)

  const notifyChangedHandler = () => {
    if (typeof changedHandler === 'function') {
      changedHandler()
    }
  }

  const onUserFieldChanged = () => {
    notifyChangedHandler()
    setFieldValues({...fieldValues})
  }

  return (
    <>
      {fields?.filter(each => !each.private).map((field) => {
        return (
          <Forms.FormGroup key={field.getPk()} label={field.name} vertical={vertical}>
            <Fields.Field item={user} viewModel={fieldValues} visible={true} websiteId={websiteId} field={field} onChange={onUserFieldChanged}/>
            <div><small><em>{field.description}</em></small></div>
          </Forms.FormGroup>
        )
      })}
      {children}
    </>
  )
}
