import TreeQLModel from './TreeQLModel'

export default class UserFieldsModel extends TreeQLModel {

  constructor(apiClient) {
    super(apiClient)
  }

  async getUserFields() {
    const options = {order: 'sequence'}
    options.join = ['field_types', 'user_field_validators,validators']
    return (await super.list('user_fields', options)).map(each => {
      each.field_types = [each.field_type_id]
      each.field_type_id = each.field_types[0]?.field_type_id
      return new UserFieldsModel.UserField(each)
    })
  }

  async createUserField(userField) {
    const entity = new UserFieldsModel.UserField(userField)
    return await super.create('user_fields', entity)
  }

  async updateUserField(userField) {
    const entity = new UserFieldsModel.UserField(userField, true)
    return await super.update('user_fields', entity.getPk(), entity)
  }

  async deleteUserField(userField) {
    const entity = new UserFieldsModel.UserField(userField)
    const options = {filter: `field_id,eq,${entity.getPk()}`, include: 'value_id'}
    const values = await super.list('user_field_values', options)
    const deletes = values.map(each => each.value_id)
    if (deletes.length) await super.delete('user_field_values', deletes)
    try {
      return await super.delete('user_fields', entity.getPk())
    } catch (err) {
      if (values.length) {
        // Recreate the values so the delete is rolled back
        await super.create('user_field_values', values)
      }
      throw err
    }
  }

}

UserFieldsModel.UserField = class extends TreeQLModel.Entity {

  constructor(fields, forUpdate) {
    super(fields, 'user_field_id', forUpdate)
    this.field_type_id = Number(this.field_type_id)
    this.summary = this.summary ? 1 : 0
    this.private = this.private ? 1 : 0
    this.required = this.required ? 1 : 0
    this.sequence = Number(this.sequence || 0)
  }

  getFieldType() {
    return this.field_types ? this.field_types[0] : undefined
  }

  getValidators() {
    return this.user_field_validators
  }

}
