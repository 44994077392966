import React from 'react'
import { Link } from 'react-router-dom'
import { useProperty, useBinding } from 'eilmer-mvvm/react'

import * as Core from '@coreui/react'
import CIcon from '@coreui/icons-react'

import ItemTypesViewModel from '#viewmodel/ItemTypesViewModel'
import ItemClassesViewModel from '#viewmodel/ItemClassesViewModel'
import UserRolesViewModel from '#viewmodel/UserRolesViewModel'

import * as Forms from '#components/widgets/Forms'
import * as Inputs from '#components/widgets/Inputs'
import * as Entities from '#components/widgets/Entities'

export default function ItemTypes({ appViewModel }) {
  appViewModel.breadcrumb = 'Developer / Item Types'
  const viewModel = ItemTypesViewModel.useInstance()

  const handleAddEntity = () => {
    viewModel.doEditEntity(null)
  }

  return (
    <Core.CCard style={{display: 'flex', flex: '1 1 0%'}}>
      <Core.CCardHeader>
        <div className="card-header-actions">
          <Core.CButton color="primary" size="sm" onClick={() => handleAddEntity()}>
            <CIcon name="cil-library-add"/>&nbsp;Add Item Type
          </Core.CButton>
        </div>
      </Core.CCardHeader>
      <Core.CCardBody>
        <EntityDataTable viewModel={viewModel}/>
      </Core.CCardBody>
      <EditEntityModal entityName="Item Type" viewModel={viewModel}/>
    </Core.CCard>
  )

}

function EntityDataTable({ viewModel }) {
  const entities = useProperty(viewModel, 'entities')

  const handleEditEntity = (entity) => {
    viewModel.doEditEntity(entity)
  }

  const handleDeleteEntity = (entity) => {
    viewModel.doDeleteEntity(entity)
  }

  return (
    <Entities.DataTable entities={entities} fields={viewModel.fields} scopedSlots={{
      'class':
        (entity)=>(
          <td>
            {entity.item_classes[0]?.name || ''}
          </td>
        ),
      'parent':
        (entity)=>(
          <td>
            {entity.item_types[0]?.name || ''}
          </td>
        ),
      'actions':
        (entity)=>(
          <td style={{textAlign:'right'}}>
            <a href="#" onClick={() => handleEditEntity(entity)}>
              <CIcon className="mr-2" title="Edit" name="cil-pencil"/>
            </a>
            <Link to={`/developer/item-type-fields/${entity.item_type_id}`}>
              <CIcon className="mr-2" title="Fields" name="cil-storage"/>
            </Link>
            <Link to={`/developer/items/${entity.item_type_id}`}>
              <CIcon className="mr-2" title="Items" name="cil-library"/>
            </Link>
            <a href="#" onClick={() => handleDeleteEntity(entity)}>
              <CIcon title="Delete" name="cil-trash"/>
            </a>
          </td>
        ),
    }}/>
  )
}

function EditEntityModal({ viewModel, entityName }) {
  const open = useProperty(viewModel, 'editing')
  const itemTypes = useProperty(viewModel, 'entities')
  const [ name, setName ] = useBinding(viewModel.entity, 'name')
  const [ description, setDescription ] = useBinding(viewModel.entity, 'description')
  const [ itemClassId, setItemClassId ] = useBinding(viewModel.entity, 'item_class_id')
  const [ parentTypeId, setParentTypeId ] = useBinding(viewModel.entity, 'parent_type_id')
  const [ userRoleId, setUserRoleId ] = useBinding(viewModel.entity, 'user_role_id')
  const [ sequence, setSequence ] = useBinding(viewModel.entity, 'sequence')
  const [ helpText, setHelpText ] = useBinding(viewModel.entity, 'help_text')
  const [ summaryFields, setSummaryFields ] = useBinding(viewModel.entity, 'summary_fields')

  const handleSetSummmaryFields = (e) => {
    let value = Array.from(e.target.selectedOptions, option => option.value);
    setSummaryFields(JSON.stringify(value))
  }

  const handleCancel = () => {
    viewModel.doCancelEdit()
  }

  const handleSave = () => {
    viewModel.doSaveEntity(viewModel.entity)
  }

  return (
    <Core.CModal centered closeOnBackdrop={false} show={open} size="lg">
      <Forms.ValidatingForm onSubmit={handleSave} onReset={handleCancel}>
        <Core.CModalHeader>Add/Edit {entityName}</Core.CModalHeader>
        <Core.CModalBody>
          <Core.CFormGroup row>
            <Core.CCol md="3">
              <Core.CLabel>Class</Core.CLabel>
            </Core.CCol>
            <Core.CCol xs="12" md="9">
              <ItemClassDropdown required value={itemClassId} onChange={e => setItemClassId(e.target.value)}/>
            </Core.CCol>
          </Core.CFormGroup>
          <Core.CFormGroup row>
            <Core.CCol md="3">
              <Core.CLabel>Parent Type</Core.CLabel>
            </Core.CCol>
            <Core.CCol xs="12" md="9">
              <ItemTypeDropdown value={parentTypeId} onChange={e => setParentTypeId(e.target.value)}/>
            </Core.CCol>
          </Core.CFormGroup>
          <Core.CFormGroup row>
            <Core.CCol md="3">
              <Core.CLabel>User Role</Core.CLabel>
            </Core.CCol>
            <Core.CCol xs="12" md="9">
              <UserRoleDropdown required={false} value={userRoleId || ''} onChange={e => setUserRoleId(e.target.value)}/>
              <small><em>Users must have at least this role to use the type</em></small>
            </Core.CCol>
          </Core.CFormGroup>
          <Core.CFormGroup row>
            <Core.CCol md="3">
              <Core.CLabel>Name</Core.CLabel>
            </Core.CCol>
            <Core.CCol xs="12" md="9">
              <Forms.ValidatedFormControl>
                {({ ref }) => (
                  <Core.CInput innerRef={ref} required type="text" value={name || ''} onChange={e => setName(e.target.value)}/>
                )}
              </Forms.ValidatedFormControl>
              <small><em>For internal use to identify this {entityName}</em></small>
            </Core.CCol>
          </Core.CFormGroup>
          <Core.CFormGroup row>
            <Core.CCol md="3">
              <Core.CLabel>Description</Core.CLabel>
            </Core.CCol>
            <Core.CCol xs="12" md="9">
              <Forms.ValidatedFormControl>
                {({ ref }) => (
                  <Core.CInput innerRef={ref} required type="text" value={description || ''} onChange={e => setDescription(e.target.value)}/>
                )}
              </Forms.ValidatedFormControl>
              <small><em>For internal use to describe this {entityName}</em></small>
            </Core.CCol>
          </Core.CFormGroup>
          <Core.CFormGroup row>
            <Core.CCol md="3">
              <Core.CLabel>Sequence</Core.CLabel>
            </Core.CCol>
            <Core.CCol xs="12" md="9">
              <Core.CInput required type="number" value={sequence || '0'} onChange={e => setSequence(e.target.value)}/>
              <small><em>Determines the display order of the item type</em></small>
            </Core.CCol>
          </Core.CFormGroup>
          <Core.CFormGroup row>
            <Core.CCol md="3">
              <Core.CLabel>Help Text</Core.CLabel>
            </Core.CCol>
            <Core.CCol xs="12" md="9">
              <Inputs.Html value={helpText} onChange={e => setHelpText(e.target.value)}/>
              <small><em>Help text to display to users for this {entityName}</em></small>
            </Core.CCol>
          </Core.CFormGroup>
          <Core.CFormGroup row>
            <Core.CCol md="3">
              <Core.CLabel>Summary Fields</Core.CLabel>
            </Core.CCol>
            <Core.CCol xs="12" md="9">
              <Core.CSelect custom multiple value={JSON.parse(summaryFields || '[]')} onChange={handleSetSummmaryFields}>
                <option value="name">Name</option>
                <option value="description">Description</option>
                <option value="created_date">Created Date</option>
              </Core.CSelect>
              <small><em>System fields to show in user views</em></small>
            </Core.CCol>
          </Core.CFormGroup>
        </Core.CModalBody>
        <Core.CModalFooter>
          <Core.CButton color="secondary" type="reset">Cancel</Core.CButton>
          &nbsp;
          <Core.CButton color="primary" type="submit">Save</Core.CButton>
        </Core.CModalFooter>
      </Forms.ValidatingForm>
    </Core.CModal>
  )
}

function UserRoleDropdown({ required, value, onChange }) {
  const viewModel = UserRolesViewModel.useInstance()
  return (
    <Entities.EntityDropdown required={required} value={value} onChange={onChange} entityViewModel={viewModel}/>
  )
}

function ItemClassDropdown({ required, value, onChange }) {
  const viewModel = ItemClassesViewModel.useInstance()
  return (
    <Entities.EntityDropdown required={required} value={value} onChange={onChange} entityViewModel={viewModel}/>
  )
}

function ItemTypeDropdown({ required, value, onChange }) {
  const viewModel = ItemTypesViewModel.useInstance()
  return (
    <Entities.EntityDropdown required={required} value={value} onChange={onChange} entityViewModel={viewModel}/>
  )
}
