import ItemTypesModel from '#model/ItemTypesModel'
import ItemTypeFieldsModel from '#model/ItemTypeFieldsModel'
import ItemTypeFieldValidatorsModel from '#model/ItemTypeFieldValidatorsModel'
import { useApiClient } from '#context/ApiContext'
import { useNotifications } from '#context/NotificationContext'
import { useInstanceOf } from 'eilmer-mvvm/react'

export default class ItemTypesViewModel {

  constructor(apiClient, notificationsProvider, itemClassId) {
    this.model = new ItemTypesModel(apiClient)
    this.fieldsModel = new ItemTypeFieldsModel(apiClient)
    this.validatorsModel = new ItemTypeFieldValidatorsModel(apiClient)
    this.notifications = notificationsProvider
    this.entity = new ItemTypesModel.ItemType()
    this.itemClassId = itemClassId
    this.editing = false
    this.loadEntities()
  }

  async loadEntities() {
    try {
      this.entities = await this.model.getItemTypes(this.itemClassId)
    } catch (err) {
      console.error(err)
      this.notifications.error(err.message)
    }
  }

  doEditEntity(entity) {
    this.entity = new ItemTypesModel.ItemType(entity)
    this.editing = true
  }

  doCancelEdit() {
    this.editing = false
  }

  async doSaveEntity(entity) {
    try {
      if (entity.isNew()) {
        const result = await this.model.createItemType(entity)
        this.editing = false
        this.loadEntities()
        return result
      } else {
        const result = await this.model.updateItemType(entity)
        this.editing = false
        this.loadEntities()
        return result
      }
    } catch (err) {
      console.error(err)
      this.notifications.error(err.message)
    }
  }

  async doDeleteEntity(entity) {
    if (await this.notifications.confirm('Are you sure you\'d like to delete this entity?')) {
      try {
        await Promise.all(entity.item_type_fields.map(async (each) => {
          await Promise.all(each.item_type_field_validators.map(async (each) => {
            await this.validatorsModel.deleteItemTypeFieldValidator(each)
          }))
          await this.fieldsModel.deleteItemTypeField(each)
        }))
        const result = await this.model.deleteItemType(entity)
        this.loadEntities()
        return result
      } catch (err) {
        console.error(err)
        this.notifications.error(err.message)
      }
    }
  }

  get fields() {
    return [
      { key: 'item_type_id', label: 'ID', _style: { width: '6%'}, _classes: 'bg-light text-dark' },
      { key: 'class', _style: { width: '20%'} },
      { key: 'name', _style: { width: '20%'} },
      { key: 'parent', _style: { width: '15%'} },
      { key: 'description' },
      {
        key: 'actions',
        label: '',
        _style: { width: '120px' },
        filter: false
      }
    ]
  }

}

ItemTypesViewModel.useInstance = function(itemClassId) {
  return useInstanceOf(ItemTypesViewModel, useApiClient(), useNotifications(), itemClassId)
}
