import TreeQLModel from './TreeQLModel'
import ItemsModel from './ItemsModel'

export default class ContentModel extends TreeQLModel {

  constructor(apiClient) {
    super(apiClient)
  }

  async getContent(itemId, websiteId) {
    const options = {join: 'content_item_values', order: 'sequence'}
    if (Array.isArray(itemId)) {
      options.filter = [`website_id,eq,${websiteId}`, `parent_item_id,in,${itemId.join(',')}`]
    } else {
      options.filter = [`website_id,eq,${websiteId}`, `parent_item_id,eq,${itemId}`]
    }
    // options.exclude = ['content_item_values.base64_value']
    const entities = await super.list('content_items', options)
    return entities.map(each => {
      return new ItemsModel.Item(each)
    })
  }

}
