import React from 'react'
import { useProperty, useBinding } from 'eilmer-mvvm/react'

import * as Core from '@coreui/react'
import CIcon from '@coreui/icons-react'

import SettingsViewModel from '#viewmodel/SettingsViewModel'

import * as Forms from '#components/widgets/Forms'
import * as Items from '#components/widgets/Items'
import * as Inputs from '#components/widgets/Inputs'

export default function Settings({ appViewModel }) {
  appViewModel.breadcrumb = 'Website / Settings'
  const changed = useProperty(appViewModel, 'unsavedChanges')
  const websiteId = useProperty(appViewModel, 'websiteId')
  const viewModel = SettingsViewModel.useInstance(websiteId)
  const website = useProperty(viewModel, 'entity') || {}

  const [ title, setTitle ] = useBinding(website, 'meta_title')
  const [ description, setDescription ] = useBinding(website, 'meta_description')
  const [ logoItemId, setLogoItemId ] = useBinding(website, 'logo_item_id')
  const [ homeItemId, setHomeItemId ] = useBinding(website, 'homepage_item_id')

  const onFieldChanged = (handler) => {
    return (e) => {
      appViewModel.unsavedChanges = true
      if (handler) handler(e.target.value)
    }
  }

  const handleSave = async () => {
    await viewModel.doSaveEntity(website)
    appViewModel.unsavedChanges = false
  }

  if (viewModel.entity) {
    return (
      <Forms.ValidatingForm onSubmit={handleSave} style={{display:'flex',flex:1}}>
        <Core.CCard style={{display: 'flex', flex: '1 1 0%'}}>
          <Core.CCardHeader>
            Website Settings
          </Core.CCardHeader>
          <Core.CCardBody>
            <Core.CFormGroup>
              <Core.CLabel>Title</Core.CLabel>
              <Forms.ValidatedFormControl>
                {({ ref }) => (
                  <Core.CInput innerRef={ref} placeholder="Website Title" required value={title || ''} onChange={onFieldChanged(setTitle)}/>
                )}
              </Forms.ValidatedFormControl>
              <small><em></em></small>
            </Core.CFormGroup>
            <Core.CFormGroup>
              <Core.CLabel>Meta Description</Core.CLabel>
              <Forms.ValidatedFormControl>
                {({ ref }) => (
                  <Core.CTextarea innerRef={ref} placeholder="Meta Description" required value={description || ''} onChange={onFieldChanged(setDescription)}/>
                )}
              </Forms.ValidatedFormControl>
              <small><em></em></small>
            </Core.CFormGroup>
            <Core.CFormGroup>
              <Core.CLabel>Logo</Core.CLabel>
              <Inputs.MediaItem innerRef={null} mimeType="image/*" required={false} value={logoItemId} onChange={onFieldChanged(setLogoItemId)}/>
            </Core.CFormGroup>
            <Core.CFormGroup>
              <Core.CLabel>Home Page</Core.CLabel>
              <Items.ItemInput innerRef={null} websiteId={websiteId} itemTypeId={3} required={true} value={homeItemId} onChange={onFieldChanged(setHomeItemId)}/>
            </Core.CFormGroup>
            <Items.ItemEditor vertical item={website.settings_item} changedHandler={onFieldChanged()} typeFieldsOnly/>
          </Core.CCardBody>
          <Core.CCardFooter>
            <Core.CButton disabled={!changed} type="submit" color="success">
              <CIcon name="cil-save"/> Save Changes
            </Core.CButton>
          </Core.CCardFooter>
        </Core.CCard>
      </Forms.ValidatingForm>
    )
  }
}
