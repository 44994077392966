import TreeQLModel from './TreeQLModel'

export default class StatusesModel extends TreeQLModel {

  constructor(apiClient) {
    super(apiClient)
  }

  async getStatuses() {
    return (await super.list('statuses')).map(each => {
      return new StatusesModel.Status(each)
    })
  }

  async createStatus(status) {
    const entity = new StatusesModel.Status(status)
    return await super.create('statuses', entity)
  }

  async updateStatus(status) {
    const entity = new StatusesModel.Status(status, true)
    return await super.update('statuses', entity.getPk(), entity)
  }

  async deleteStatus(status) {
    const entity = new StatusesModel.Status(status)
    return await super.delete('statuses', entity.getPk())
  }

}

StatusesModel.Status = class extends TreeQLModel.Entity {

  constructor(fields, forUpdate) {
    super(fields, 'status_id', forUpdate)
  }

}
