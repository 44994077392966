import React from 'react'
import * as Core from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { useProperty, useBinding } from 'eilmer-mvvm/react'

import { useAppViewModel } from '#context/AppContext'
import { useAuthContext } from '#context/AuthContext'

import AdminAccount from './AdminAccount'

function Link({children}) {
  return children
}

export default function AdminHeader({ user, breadcrumbName }) {
  const appViewModel = useAppViewModel()

  // HMMMM? THIS ISN'T WORKING!
  console.log(appViewModel.breadcrumb)
  console.log(Object.getOwnPropertyDescriptor(appViewModel, 'breadcrumb'))
  // const breadcrumb = useProperty(appViewModel, 'breadcrumb')
  const [ breadcrumb ] = useBinding(appViewModel, 'breadcrumb')
  console.log(Object.getOwnPropertyDescriptor(appViewModel, 'breadcrumb'))

  //const test = useProperty(appViewModel, 'test')
  //console.log('Test:', test)

  //const appService = useAppService()
  //const [ showSideBar, setShowSideBar ] = appService.useState('showSideBar', true)
  //const sites = appService.useProperty('sites')
  const [ showSideBar, setShowSideBar ] = React.useState(true)
  const sites = [{id:1,hostname:'www.malmesbury-live-arts.org.uk'}]

  //console.log('Breadcrumb:', breadcrumb)

  return (
    <Core.CHeader withSubheader>
      <Core.CToggler inHeader className="ml-md-3 d-lg-none" onClick={() => setShowSideBar(!showSideBar)}/>
      <Core.CToggler inHeader className="ml-3 d-md-down-none" onClick={() => setShowSideBar(!showSideBar)}/>

      <Core.CHeaderNav className="d-md-down-none mr-auto">
        {/*
        <Core.CDropdown inNav className="c-header-nav-item mx-2">
          <Core.CDropdownToggle className="c-header-nav-link" caret={false}>
            <CIcon name="cil-browser"/>&nbsp;Manage...
          </Core.CDropdownToggle>
          <Core.CDropdownMenu  placement="bottom-start" className="pt-0">
            {sites.map(site =>
              <Core.CDropdownItem key={site.id} onClick={() => { appService.setManagedSite(site)}>
                {site.hostname}
              </Core.CDropdownItem>
            )}
          </Core.CDropdownMenu>
        </Core.CDropdown>

        <Core.CHeaderNavItem className="px-3">
          <Link href="/admin/users" passHref>
            <Core.CHeaderNavLink>
              <CIcon name="cil-settings"/>&nbsp;Administration
            </Core.CHeaderNavLink>
          </Link>
        </Core.CHeaderNavItem>
        */}
      </Core.CHeaderNav>
      <Core.CHeaderNav className="px-3">
        <AdminAccount user={user}/>
      </Core.CHeaderNav>
      <Core.CSubheader className="px-3 justify-content-between">
        <Core.CBreadcrumb className="border-0 c-subheader-nav m-0 px-0 px-md-3">
          <Core.CBreadcrumbItem>
            <Link href="/" passHref>
              <a>Home</a>
            </Link>
          </Core.CBreadcrumbItem>
          <Core.CBreadcrumbItem active>{breadcrumb}</Core.CBreadcrumbItem>
        </Core.CBreadcrumb>
      </Core.CSubheader>
    </Core.CHeader>
  )
}
