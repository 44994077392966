import UserRolePermissionsModel from '#model/UserRolePermissionsModel'
import UserRolePermissionConstraintsModel from '#model/UserRolePermissionConstraintsModel'
import { useApiClient } from '#context/ApiContext'
import { useNotifications } from '#context/NotificationContext'
import { useInstanceOf } from 'eilmer-mvvm/react'

export default class UserRolePermissionConstraintsViewModel {

  constructor(apiClient, notificationsProvider, userRolePermissionId) {
    this.model = new UserRolePermissionConstraintsModel(apiClient)
    this.permissionsModel = new UserRolePermissionsModel(apiClient)
    this.notifications = notificationsProvider
    this.userRolePermissionId = Number(userRolePermissionId)
    this.entity = new UserRolePermissionConstraintsModel.UserRolePermissionConstraint()
    this.editing = false
    this.loadEntities()
  }

  async loadEntities() {
    try {
      this.entities = await this.model.getUserRolePermissionConstraints(this.userRolePermissionId)
      this.userRolePermission = await this.permissionsModel.getUserRolePermission(this.userRolePermissionId)
    } catch (err) {
      console.error(err)
      this.notifications.error(err.message)
    }
  }

  doEditEntity(entity) {
    const editingEntity = entity || {user_role_permission_id: this.userRolePermissionId}
    this.entity = new UserRolePermissionConstraintsModel.UserRolePermissionConstraint(editingEntity)
    this.editing = true
  }

  doCancelEdit() {
    this.editing = false
  }

  async doSaveEntity(entity) {
    try {
      if (entity.isNew()) {
        const result = await this.model.createUserRolePermissionConstraint(entity)
        this.editing = false
        this.loadEntities()
        return result
      } else {
        const result = await this.model.updateUserRolePermissionConstraint(entity)
        this.editing = false
        this.loadEntities()
        return result
      }
    } catch (err) {
      console.error(err)
      this.notifications.error(err.message)
    }
  }

  async doDeleteEntity(entity) {
    if (await this.notifications.confirm('Are you sure you\'d like to delete this item?')) {
      try {
        const result = await this.model.deleteUserRolePermissionConstraint(entity)
        this.loadEntities()
        return result
      } catch (err) {
        console.error(err)
        this.notifications.error(err.message)
      }
    }
  }

  get fields() {
    return [
      { key: 'user_role_permission_constraint_id', label: "ID", _style: { width: '6%'}, _classes: 'bg-light text-dark' },
      { key: 'constraint', _style: { width: '25%'} },
      { key: 'description' },
      {
        key: 'actions',
        label: '',
        _style: { width: '90px' },
        filter: false
      }
    ]
  }

}

UserRolePermissionConstraintsViewModel.useInstance = function(userRolePermissionId) {
  return useInstanceOf(UserRolePermissionConstraintsViewModel, useApiClient(), useNotifications(), userRolePermissionId)
}
