import React from 'react'
import * as Core from '@coreui/react'

export const ValidatingForm = function({ onSubmit, onReset, children, ...props }) {
  const ref = React.useRef()
  const [validated, setValidated] = React.useState(false)

  React.useEffect(() => {
    const form = ref.current

    const submitHandler = (e) => {
      const valid = e.target.checkValidity()
      setValidated(!valid)
      e.preventDefault()
      if (valid && typeof onSubmit === 'function') {
        onSubmit(e)
      }
    }

    const resetHandler = (e) => {
      e.preventDefault()
      setValidated(false)
      if (typeof onReset === 'function') {
        onReset(e)
      }
    }

    form.addEventListener('submit', submitHandler)
    form.addEventListener('reset', resetHandler)
    return () => {
      form.removeEventListener('submit', submitHandler)
      form.removeEventListener('reset', resetHandler)
    }
  }, [onSubmit, onReset, ref.current])

  return (
    <Core.CForm innerRef={ref} wasValidated={validated} noValidate {...props}>
      {children}
    </Core.CForm>
  )
}

export const ValidatedFormControl = function({ innerRef, children }) {
  const privateRef = React.useRef()
  const [ feedback, setFeedback ] = React.useState('')
  const ref = innerRef || privateRef

  React.useEffect(() => {
    const element = ref.current, handler = (e) => {
      setFeedback(element.validationMessage)
    }
    if (element) {
      element.addEventListener('invalid', handler)
      return () => element.removeEventListener('invalid', handler)
    } else {
      console.error('No element found:', ref)
    }
  }, [ref.current])

  return (
    <>
      {children({ ref, feedback })}
      <Core.CInvalidFeedback>{feedback}</Core.CInvalidFeedback>
    </>
  )
}

export const FormGroup = function({ label, vertical, children }) {
  if (!vertical) {
    return (
      <Core.CFormGroup row>
        <Core.CCol md="3">
          <Core.CLabel>{label}</Core.CLabel>
        </Core.CCol>
        <Core.CCol xs="12" md="9">
          {children}
        </Core.CCol>
      </Core.CFormGroup>
    )
  } else {
    return (
      <Core.CFormGroup>
        <Core.CLabel>{label}</Core.CLabel>
        {children}
      </Core.CFormGroup>
    )
  }
}
