import ItemTypes from '#utils/ItemTypes.js'
import ItemsModel from '#model/ItemsModel'
import ItemTypesModel from '#model/ItemTypesModel'
import ContentBlocksModel from '#model/ContentBlocksModel'
import { useApiClient } from '#context/ApiContext'
import { useNotifications } from '#context/NotificationContext'
import { useInstanceOf } from 'eilmer-mvvm/react'
import { Converter } from 'eilmer-mvvm/converters'

export default class ContentViewModel {

  constructor(apiClient, notificationsProvider, itemId, itemTypeId, websiteId) {
    this.contentBlocksModel = new ContentBlocksModel(apiClient)
    this.itemsModel = new ItemsModel(apiClient)
    this.itemTypesModel = new ItemTypesModel(apiClient)
    this.contentConverter = new ContentConverter()
    this.notifications = notificationsProvider
    this.apiClient = apiClient
    this.websiteId = websiteId
    this.itemId = itemId
    this.itemTypeId = itemTypeId
    this.blocks = {}
    this.items = []
    this.item = null
    this.loadContent()
    this.loadItemType()
  }

  newItem({name, description, ...block}) {
    const item = new ItemsModel.Item({name, description, item_type_id: ItemTypes.CONTENT_BLOCK})
    item.setFieldValue(9, 'int_value', block.block_id)
    item.parent_item_id = this.itemId
    item.website_id = this.websiteId
    item.properties = {}
    return { ...item, getFieldValue: item.getFieldValue.bind(item) }
  }

  async loadContent() {
    const blocks = await this.contentBlocksModel.getContentBlocks(this.websiteId)
    const items = await this.itemsModel.getItems(ItemTypes.CONTENT_BLOCK, this.websiteId, this.itemId)
    this.item = await this.itemsModel.getItem(this.itemId)
    this.items = items.map(each => {
      each.properties = each.getFieldValue(9, 'json_value')
      return { ...each, getFieldValue: each.getFieldValue.bind(each) }
    })    
    this.blocks = blocks.reduce((result, each) => {
      const group = result[each.group_name] = result[each.group_name] || []
      group.push(each.blocks[0])
      return result
    }, {})
  }

  async loadItemType() {
    try {
      this.itemType = await this.itemTypesModel.getItemType(this.itemTypeId)
    } catch (err) {
      console.error(err)
      this.notifications.error(err.message)
    }
  }

  async doSave(layout) {
    const ids = layout.map(each => each.item_id)
    layout.sort((a, b) => a.layout.y - b.layout.y)
    const items = layout.map((each, idx) => {
      const item = new ItemsModel.Item({ ...each, sequence: Number(idx) })
      item.setFieldValue(9, 'json_value', each.properties)
      delete item.properties
      delete item.layout
      return [item, each]
    })
    const deletes = this.items.filter(each => {
      return !ids.includes(each.item_id)
    })
    try {
      for (let i = 0; i < items.length; i++) {
        let [item, layoutItem] = items[i]
        if (item.isNew()) {
          let item_id = await this.itemsModel.createItem(item)
          layoutItem.item_id = item_id
        } else {
          await this.itemsModel.updateItem(item)
        }
      }
      for (let i = 0; i < deletes.length; i++) {
        await this.itemsModel.deleteItem(deletes[i])
      }
    } catch (err) {
      console.error(err)
      this.notifications.error(err.message)
    }

  }
}

class ContentConverter extends Converter {

  convertFrom(viewModelValue, bindingContext) {
    return viewModelValue
  }

  convertTo(viewValue, bindingContext) {
    return viewValue
  }
}

ContentViewModel.useInstance = function(itemId, itemTypeId, websiteId) {
  return useInstanceOf(ContentViewModel, useApiClient(), useNotifications(), itemId, itemTypeId, websiteId)
}
