import React from 'react'
import { useProperty } from 'eilmer-mvvm/react'

import * as Core from '@coreui/react'
import CIcon from '@coreui/icons-react'

import MediaLibrary from '#components/misc/MediaLibrary'
import MediaViewModel from '#viewmodel/MediaViewModel'

const WEBSITE_ID = 1

export default function Media({ appViewModel }) {
  appViewModel.breadcrumb = 'Website / Media'
  const mediaViewModel = MediaViewModel.useInstance(WEBSITE_ID, true)
  const media = useProperty(mediaViewModel, 'media')

  // onMediaObjectSelected, deleteMediaObjectHandler, uploadMediaObjectHandler, refreshHandler
  const deleteMediaObjectHandler = mediaViewModel.doDeleteMediaObject.bind(mediaViewModel)
  const createMediaObjectHandler = mediaViewModel.doCreateMediaObject.bind(mediaViewModel)
  const saveMediaObjectsHandler = mediaViewModel.doSaveMediaObjects.bind(mediaViewModel)
  const refreshHandler = mediaViewModel.doLoadMedia.bind(mediaViewModel)

  return (
    <Core.CCard style={{display: 'flex', flex: '1 1 0%'}}>
      <Core.CCardBody style={{display: 'flex'}}>
        {media && <MediaLibrary media={media} deleteMediaObjectHandler={deleteMediaObjectHandler} uploadMediaObjectHandler={createMediaObjectHandler} saveMediaObjectsHandler={saveMediaObjectsHandler}/>}
      </Core.CCardBody>
    </Core.CCard>
  )
}
