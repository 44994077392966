import TreeQLModel from './TreeQLModel'

export default class ItemClassesModel extends TreeQLModel {

  constructor(apiClient) {
    super(apiClient)
  }

  async getItemClasses() {
    return (await super.list('item_classes')).map(each => {
      return new ItemClassesModel.ItemClass(each)
    })
  }

  async createItemClass(itemClass) {
    const entity = new ItemClassesModel.ItemClass(itemClass)
    return await super.create('item_classes', entity)
  }

  async updateItemClass(itemClass) {
    const entity = new ItemClassesModel.ItemClass(itemClass, true)
    return await super.update('item_classes', entity.getPk(), entity)
  }

  async deleteItemClass(itemClass) {
    const entity = new ItemClassesModel.ItemClass(itemClass)
    return await super.delete('item_classes', entity.getPk())
  }

}

ItemClassesModel.ItemClass = class extends TreeQLModel.Entity {

  constructor(fields, forUpdate) {
    super(fields, 'class_id', forUpdate)
  }

}
