import { Converter } from 'eilmer-mvvm/converters'

import Constraints from '#primitives/Constraints'

export default class ItemConstraintConverter extends Converter {

    constructor(itemType, permission) {
      super()
      this.itemType = itemType
      this.permission = permission
    }

    convertFrom(viewModelValue, bindingContext) {
      if (Array.isArray(viewModelValue)) {
        // So the constraints ONLY apply *if* the permission item type matches 
        // the given item type, because the permission could be for a parent item type.
        if (this.itemType?.item_type_id === this.permission?.item_type_id) {
          const constraints = this.permission?.getConstraints()
          // Process each constraint, by type
          if (Array.isArray(constraints) && constraints.length > 0) {
            const valid = viewModelValue.filter(item => {
              // All of the constraints have to pass for the item to be included
              // So just find the first failure, and if none fail then fine              
              return typeof constraints.find(each => {
                // False for pass, true for fail
                const properties = JSON.parse(each.properties)
                if (each.constraint_id === Constraints.BY_ITEM) {
                  return item.getPk() !== properties.itemId
                }
                return false
              }) === 'undefined'
            })
            valid.unfiltered = viewModelValue
            return valid
          }
          const valid = []
          valid.unfiltered = viewModelValue
          return valid
        } else {
          const valid = viewModelValue
          valid.unfiltered = viewModelValue
          return valid
        }
      }
      return viewModelValue
    }
  
    convertTo(viewValue, bindingContext) {
      throw new Error('Only one-way conversion supported')
    }
  }
  