export default class ItemClasses {

    static INTERNAL = 1

    static CONTENT = 2

    static DATA = 3

    static DEVELOPMENT = 4

    static FORM = 5

}