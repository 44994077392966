import React from 'react'
import { useProperty } from 'eilmer-mvvm/react'

import * as Core from '@coreui/react'
import CIcon from '@coreui/icons-react'

import { useConfigViewModel } from '#context/AppContext'

import Types from '#primitives/Types'
import * as Forms from '#components/widgets/Forms'
import * as Inputs from '#components/widgets/Inputs'

export default function Config({ appViewModel }) {
  appViewModel.breadcrumb = 'Developer / Config'
  const configViewModel = useConfigViewModel()
  const changed = useProperty(appViewModel, 'unsavedChanges')

  const logo = useConfig(configViewModel, Types.FILE, 'logo')
  const htmlDash = useConfig(configViewModel, Types.BOOLEAN, 'htmlDash')

  const setConfig = (config) => {
    return (e) => {
      appViewModel.unsavedChanges = true
      config.set(e)
    }
  }

  const handleSave = async () => {
    if (await configViewModel.doSave()) {
      appViewModel.unsavedChanges = false
    }
  }

  return (
    <Forms.ValidatingForm
      onSubmit={handleSave}
      style={{ display: 'flex', flex: 1 }}
    >
      <Core.CCard style={{ display: 'flex', flex: '1 1 0%' }}>
        <Core.CCardHeader>Configuration</Core.CCardHeader>
        <Core.CCardBody>
          <Core.CFormGroup>
            <Core.CLabel>Logo</Core.CLabel>
            <Inputs.File
              innerRef={null}
              required={false}
              value={logo.get()}
              onChange={setConfig(logo)}
            />
          </Core.CFormGroup>
          <Core.CFormGroup>
            <Core.CLabel>HTML Dashboard</Core.CLabel>
            <div>
              <Core.CSwitch
                shape="pill"
                color="primary"
                checked={htmlDash.get()}
                onChange={setConfig(htmlDash)}
              />
            </div>
          </Core.CFormGroup>
        </Core.CCardBody>
        <Core.CCardFooter>
          <Core.CButton disabled={!changed} type="submit" color="success">
            <CIcon name="cil-save" /> Save Changes
          </Core.CButton>
        </Core.CCardFooter>
      </Core.CCard>
    </Forms.ValidatingForm>
  )
}

function useConfig(configViewModel, type, key) {
  const [value, setValue] = React.useState(configViewModel.getConfig(key))
  const loaded = useProperty(configViewModel, 'loaded')

  const setValueInternal = (value) => {
    configViewModel.setConfig(key, value)
    setValue(Array.isArray(value) ? value[0] : value)
  }

  React.useEffect(() => {
    if (loaded) {
      setValue(configViewModel.getConfig(key))
    }
  }, [loaded])

  return {
    get: () => {
      if (type === Types.BOOLEAN) {
        return !!value
      } else {
        return value
      }
    },
    set: (e) => {
      if (type === Types.FILE) {
        if (e.target.files?.length) {
          const uploadFile = e.target.files[0]
          const fileReader = new FileReader()
          fileReader.onload = async () => {
            const dataUrl = fileReader.result
            setValueInternal([uploadFile, dataUrl])
          }
          fileReader.readAsDataURL(uploadFile)
        } else {
          setValueInternal([null, null])
        }
      } else if (type === Types.BOOLEAN) {
        setValueInternal(e.target.checked)
      } else {
        setValueInternal(e.target.value)
      }
    },
  }
}
