import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { useProperty, useInstanceOf } from 'eilmer-mvvm/react'

import * as Core from '@coreui/react'
import CIcon from '@coreui/icons-react'

import { useAuthContext } from '#context/AuthContext'

import ItemClasses from '#primitives/ItemClasses'
import Privileges from '#primitives/Privileges'
import Constraints from '#primitives/Constraints'
import Types from '#primitives/Types'

import Values from '#utils/Values'
import ItemsViewModel from '#viewmodel/ItemsViewModel'
import ItemConstraintConverter from '#converters/ItemConstraintConverter'

import * as Entities from '#components/widgets/Entities'
import * as Items from '#components/widgets/Items'

/*
function withPermission(privilegeId, Component) {
  return (props) => {
    const authViewModel = useAuthContext()
    const permissions = useProperty(authViewModel, 'permissions')
    if (true || permissions?.getPermission(privilegeId)) {
      return <Component {...props}/>
    } else {
      return null
    }
  }
}
*/

export default function ContentItems({ appViewModel }) {
  const { item_type_id, parent_item_id } = useParams()
  const authViewModel = useAuthContext()
  const viewModel = ItemsViewModel.useInstance(item_type_id, parent_item_id)
  const itemType = useProperty(viewModel, 'itemType')
  const permissions = useProperty(authViewModel, 'permissions')
  const privilegeId = itemType?.item_class_id == ItemClasses.CONTENT ? Privileges.CONTENT : Privileges.DATA
  const permission = permissions?.getPermission(privilegeId, itemType)

  if (permission) {
    appViewModel.breadcrumb = 'Items / ' + itemType?.name

    const handleAddItem = () => {
      viewModel.doEditItem(null)
    }

    return (
      <div style={{display: 'flex', flex: '1 1 0%', flexDirection: 'column'}}>
        {itemType?.help_text && itemType?.help_text !== '<p><br></p>' &&
          <Core.CCallout color="info bg-white mt-0">
            <div className="p-2" dangerouslySetInnerHTML={{__html: itemType?.help_text}}/>
          </Core.CCallout>
        }
        <Core.CCard style={{display: 'flex', flex: '1 1 0%'}}>
          {(permission?.item_type_id !== Number(item_type_id) || permission?.getConstraints()?.length == 0) &&
            <Core.CCardHeader>
              <div className="card-header-actions">
                  <Core.CButton color="primary" size="sm" onClick={() => handleAddItem()}>
                  <CIcon name="cil-library-add"/>&nbsp;Add {itemType?.name}
                </Core.CButton>
            </div>
          </Core.CCardHeader>
          }
          <Core.CCardBody>
            <ItemDataTable itemType={itemType} permission={permission} viewModel={viewModel}/>
          </Core.CCardBody>
          <Items.EditItemModal title={`Add/Edit ${itemType?.name}`} viewModel={viewModel}/>
        </Core.CCard>
      </div>
    )
  } else {
    appViewModel.breadcrumb = ''
    return null
  }
}

function ItemDataTable({ itemType, permission, viewModel }) {
  const itemsConverter = useInstanceOf(ItemConstraintConverter, itemType, permission)
  const entities = useProperty(viewModel, 'items', itemsConverter)
  const fields = useProperty(viewModel, 'fields')
  const [ items, setItems ] = React.useState([])

  React.useEffect(() => {
    if (typeof entities?.length !== 'undefined') {
      (async () => {
        setItems(await Promise.all(entities.map(async (each) => {
          const item = { original: each, item_id: each.item_id, item_type_id: each.item_type_id }
          item.name = each.name
          item.description = each.description
          item.created_date = each.created_date
          await Promise.all(viewModel.itemTypeFields?.map(async (itemTypeField) => {
            if (itemTypeField.summary) {
              const fieldType = itemTypeField.getFieldType()
              const contentItemValue = each.content_item_values?.find(value => {
                return value.field_id === itemTypeField.item_type_field_id
              })
              let value = Values.getValue(fieldType, contentItemValue)
              if (value && fieldType.type_id === Types.ITEM) {
                const itemValue = await viewModel.getItem(value)
                value = itemValue?.name
              } else if (fieldType.type_id === Types.BOOLEAN) {
                value = value ? 'Yes' : 'No'
              } else if (fieldType.type_id === Types.FILE) {
                value = `${value.name} (${(value.size / 1000000).toFixed(2)} MB)` 
              }
              item[itemTypeField.name] = String(value)
            }
          }) || [])
          return item
        })))
      })()
    }
  }, [entities?.unfiltered])

  const handleEditItem = (item) => {
    viewModel.doEditItem(item.original)
  }

  const handleDeleteItem = (item) => {
    viewModel.doDeleteItem(item.original)
  }

  return (
    <Entities.DataTable entities={items} fields={fields} scopedSlots={{
      'actions':
        (item) => {
          const childrenOnly = permission?.getConstraints()?.find(each => each.constraint_id === Constraints.CHILDREN_ONLY)
          const editable = !(permission?.item_type_id === itemType?.getPk() && childrenOnly)
          return (
            <td style={{textAlign:'right'}}>
              <a href={editable ? '#' : null} disabled={!editable} onClick={() => editable && handleEditItem(item)}>
                <CIcon className="mr-2" title="Edit" name="cil-pencil"/>
              </a>
              {itemType?.getChildItemType() && itemType?.getChildItemType().item_class_id !== ItemClasses.INTERNAL &&
                <Link to={`/items/${itemType.child_item_type_id}/children/${item.item_id}`}>
                  <CIcon className="mr-2" title={`${itemType.getChildItemType()?.name} Items`} name="cil-library"/>
                </Link>
              }
              {[ItemClasses.CONTENT, ItemClasses.BLOG].includes(itemType?.item_class_id) &&
                <Link to={`/items/${item.item_type_id}/${item.item_id}`}>
                  <CIcon className="mr-2" title="Compose" name="cil-text-square"/>
                </Link>
              }
              <a href={editable ? '#' : null} disabled={!editable} onClick={() => editable && handleDeleteItem(item)}>
                <CIcon title="Delete" name="cil-trash"/>
              </a>
            </td>
          )
        }
    }}/>
  )
}
