import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { useProperty, useBinding, useInstanceOf } from 'eilmer-mvvm/react'

import * as Core from '@coreui/react'
import CIcon from '@coreui/icons-react'

import ItemsViewModel from '#viewmodel/ItemsViewModel'
import ConstraintsViewModel from '#viewmodel/ConstraintsViewModel'
import UserRolePermissionConstraintsViewModel from '#viewmodel/UserRolePermissionConstraintsViewModel'
import JsonConverter from '#converters/JsonConverter'

import * as Forms from '#components/widgets/Forms'
import * as Entities from '#components/widgets/Entities'

export default function UserRolePermissionConstraints({ appViewModel }) {
  const { user_role_permission_id } = useParams()
  appViewModel.breadcrumb = 'Admin / User Role Permission Constraints'
  const viewModel = UserRolePermissionConstraintsViewModel.useInstance(user_role_permission_id)

  const handleAddEntity = () => {
    viewModel.doEditEntity(null)
  }

  return (
    <Core.CCard style={{display: 'flex', flex: '1 1 0%'}}>
      <Core.CCardHeader>
        <div className="card-header-actions">
          <Core.CButton color="primary" size="sm" onClick={() => handleAddEntity()}>
            <CIcon name="cil-library-add"/>&nbsp;Add Permission Constraint
          </Core.CButton>
        </div>
      </Core.CCardHeader>
      <Core.CCardBody>
        <EntityDataTable viewModel={viewModel}/>
      </Core.CCardBody>
      <EditItemModal entityName="Permission Constraint" viewModel={viewModel}/>
    </Core.CCard>
  )
}

function EntityDataTable({ viewModel }) {
  const entities = useProperty(viewModel, 'entities')

  const handleEditEntity = (entity) => {
    viewModel.doEditEntity(entity)
  }

  const handleDeleteEntity = (entity) => {
    viewModel.doDeleteEntity(entity)
  }

  return (
    <Entities.DataTable entities={entities} fields={viewModel.fields} scopedSlots={{
      'constraint':
        (entity)=>(
          <td>
            {entity.getConstraint().name}
          </td>
        ),
      'actions':
        (entity)=>(
          <td style={{textAlign:'right'}}>
            <a href="#" onClick={() => handleEditEntity(entity)}>
              <CIcon className="mr-2" title="Edit" name="cil-pencil"/>
            </a>
            <a href="#" onClick={() => handleDeleteEntity(entity)}>
              <CIcon title="Delete" name="cil-trash"/>
            </a>
          </td>
        ),
    }}/>
  )
}

function EditItemModal({ viewModel, entityName }) {
  const open = useProperty(viewModel, 'editing')
  const jsonConverter = useInstanceOf(JsonConverter, {})
  const userRolePermission = useProperty(viewModel, 'userRolePermission')
  const [ constraintId, setConstraintId ] = useBinding(viewModel.entity, 'constraint_id')
  const [ description, setDescription ] = useBinding(viewModel.entity, 'description')
  const [ properties, setProperties ] = useBinding(viewModel.entity, 'properties', jsonConverter)
  const itemTypeId = userRolePermission?.item_type_id

  const handleCancel = () => {
    viewModel.doCancelEdit()
  }

  const handleSave = () => {
    viewModel.doSaveEntity(viewModel.entity)
  }

  return (
    <Core.CModal centered show={open}>
      <Forms.ValidatingForm onSubmit={handleSave} onReset={handleCancel}>
        <Core.CModalHeader>Add/Edit {entityName}</Core.CModalHeader>
        <Core.CModalBody>
          <Core.CFormGroup row>
            <Core.CCol md="3">
              <Core.CLabel>Description</Core.CLabel>
            </Core.CCol>
            <Core.CCol xs="12" md="9">
              <Forms.ValidatedFormControl>
                {({ ref }) => (
                  <Core.CInput innerRef={ref} required type="text" value={description || ''} onChange={e => setDescription(e.target.value)}/>
                )}
              </Forms.ValidatedFormControl>
              <small><em>For internal use to describe this {entityName}</em></small>
            </Core.CCol>
          </Core.CFormGroup>
          <Core.CFormGroup row>
            <Core.CCol md="3">
              <Core.CLabel>Constraint</Core.CLabel>
            </Core.CCol>
            <Core.CCol xs="12" md="9">
              <ConstraintDropdown required={true} value={constraintId || ''} onChange={e => setConstraintId(e.target.value)}/>
              <small><em>Specifies the constraint type</em></small>
            </Core.CCol>
          </Core.CFormGroup>
          {constraintId == 1 && <Core.CFormGroup row>
            <Core.CCol md="3">
              <Core.CLabel>Item</Core.CLabel>
            </Core.CCol>
            <Core.CCol xs="12" md="9">
              <ItemDropdown itemTypeId={itemTypeId} required={true} value={properties.itemId} onChange={e => setProperties({itemId: Number(e.target.value)})}/>
              <small><em>The value for the constraint (depends on type)</em></small>
            </Core.CCol>
          </Core.CFormGroup>}
        </Core.CModalBody>
        <Core.CModalFooter>
          <Core.CButton color="secondary" type="reset">Cancel</Core.CButton>
          &nbsp;
          <Core.CButton color="primary" type="submit">Save</Core.CButton>
        </Core.CModalFooter>
      </Forms.ValidatingForm>
    </Core.CModal>
  )
}

function ConstraintDropdown({ required, value, onChange }) {
  const viewModel = ConstraintsViewModel.useInstance()
  return (
    <Entities.EntityDropdown required={required} value={value} onChange={onChange} entityViewModel={viewModel}/>
  )
}

function ItemDropdown({ itemTypeId, required, value, onChange }) {
  const viewModel = ItemsViewModel.useInstance(itemTypeId)
  return (
    <Entities.EntityDropdown required={required} value={value} onChange={onChange} entityViewModel={viewModel} property="items"/>
  )
}
