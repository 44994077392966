export default class Validators {

    static REGULAR_EXPRESSION = 1

    static MIME_TYPE = 2

    static MAX_LENGTH = 3

    static MIME_REGEX = 4

}