import { Converter } from 'eilmer-mvvm/converters'

export default class SortByNameConverter extends Converter {

    convertFrom(viewModelValue, bindingContext) {
      if (Array.isArray(viewModelValue)) {
        // TODO Probably dont want to sort the array in place
        viewModelValue.sort((a, b) => {
          if (a.name < b.name) {
            return -1
          }
          if (a.name > b.name) {
            return 1
          }
          return 0
        })
      }
      return viewModelValue
    }
  
    convertTo(viewValue, bindingContext) {
      throw new Error('Only one-way conversion supported')
    }
  }
  