import React from 'react'
import * as Core from '@coreui/react'
import { useProperty } from 'eilmer-mvvm/react'

const NotificationContext = React.createContext()

function NotificationProvider({ children }) {
  const viewModel = React.useState({})

  function confirm(message, ok, cancel) {
    return new Promise((resolve) => {
      const callback = (ok) => {
        viewModel.confirmation = null
        resolve(ok)
      }
      viewModel.confirmation = {
        message: message,
        callback: callback,
        cancel: cancel || 'Cancel',
        ok: ok || 'OK',
      }
    })
  }

  function notify(message, type) {
    viewModel.notifications = viewModel.notifications.concat({
      message: message,
      type: type || 'success'
    })
  }

  function error(message) {
    const callback = () => {
      viewModel.errorNotification = null
    }
    viewModel.errorNotification = {
      message: message,
      callback: callback
    }
  }

  return (
    <NotificationContext.Provider value={{ confirm, notify, error }}>
      {children}
      <Notifications viewModel={viewModel}/>
    </NotificationContext.Provider>
  )
}

function Notifications({ viewModel }) {
  const confirmation = useProperty(viewModel, 'confirmation')
  const notifications = useProperty(viewModel, 'notifications')
  const error = useProperty(viewModel, 'errorNotification')
  const components = []

  const handleStateChange = (notification, showing) => {
    if (!showing) {
      viewModel.notifications = viewModel.notifications.filter(each => {
        return each !== notification
      })
    }
  }

  if (error?.callback) {
    components.push(
      <Core.CModal centered color="danger" show={true}>
        <Core.CModalHeader>An Error Occurred</Core.CModalHeader>
        <Core.CModalBody>
          {error.message}
        </Core.CModalBody>
        <Core.CModalFooter>
          <Core.CButton color="danger" onClick={() => error.callback()}>
            Ok
          </Core.CButton>
        </Core.CModalFooter>
      </Core.CModal>
    )
  }

  if (confirmation?.callback) {
    components.push(
      <Core.CModal centered color="info" show={true}>
        <Core.CModalHeader>Confirmation Needed</Core.CModalHeader>
        <Core.CModalBody>
          {confirmation.message}
        </Core.CModalBody>
        <Core.CModalFooter>
          <Core.CButton color="secondary" onClick={() => confirmation.callback(false)}>
            {confirmation.cancel}
          </Core.CButton>
          &nbsp;
          <Core.CButton color="primary" onClick={() => confirmation.callback(true)}>
            {confirmation.ok}
          </Core.CButton>
        </Core.CModalFooter>
      </Core.CModal>
    )
  }

  if (notifications?.length) {
    components.push(
      <Core.CToaster position="bottom-right">
        {notifications.map(notification =>
          <Core.CToast key={notification} className={`text-white bg-${notification.type}`} show={true} autohide={3000} onStateChange={(showing) => handleStateChange(notification, showing)}>
            <Core.CToastBody>{notification.message}</Core.CToastBody>
          </Core.CToast>
        )}
      </Core.CToaster>
    )
  }

  if (components.length) {
    return components
  } else {
    return null
  }
}

export function useNotifications() {
  return React.useContext(NotificationContext)
}

export default NotificationProvider
