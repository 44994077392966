import TreeQLModel from './TreeQLModel'

export default class FieldTypesModel extends TreeQLModel {

  constructor(apiClient) {
    super(apiClient)
  }

  async getFieldTypes() {
    return (await super.list('field_types', {join: 'types'})).map(each => {
      each.types = [each.type_id]
      each.type_id = each.types[0]?.type_id
      return new FieldTypesModel.FieldType(each)
    })
  }

  async getFieldType(fieldTypeId) {
    const entity = await super.read('field_types', fieldTypeId)
    return new FieldTypesModel.FieldType(entity)
  }
 
  async createFieldType(fieldType) {
    const entity = new FieldTypesModel.FieldType(fieldType)
    return await super.create('field_types', entity)
  }

  async updateFieldType(fieldType) {
    const entity = new FieldTypesModel.FieldType(fieldType, true)
    return await super.update('field_types', entity.getPk(), entity)
  }

  async deleteFieldType(fieldType) {
    const entity = new FieldTypesModel.FieldType(fieldType)
    return await super.delete('field_types', entity.getPk())
  }

}

FieldTypesModel.FieldType = class extends TreeQLModel.Entity {

  constructor(fields, forUpdate) {
    super(fields, 'field_type_id', forUpdate)
    this.type_id = this.type_id ? Number(this.type_id) : null
    this.item_type_id = this.item_type_id ? Number(this.item_type_id) : null
  }

}
