import { Converter } from 'eilmer-mvvm/converters'

export default class PrivilegesConverter extends Converter {

    constructor(permissions) {
      super()
      this.permissions = permissions
    }
  
    convertFrom(viewModelValue, bindingContext) {
      return viewModelValue?.filter(privilege => this.permissions?.hasPrivilege(privilege.getPk()) || false)
    }
  
  }