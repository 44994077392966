import TypesModel from '#model/TypesModel'
import { useApiClient } from '#context/ApiContext'
import { useNotifications } from '#context/NotificationContext'
import { useInstanceOf } from 'eilmer-mvvm/react'

export default class TypesViewModel {

  constructor(apiClient, notificationsProvider) {
    this.model = new TypesModel(apiClient)
    this.notifications = notificationsProvider
    this.entity = new TypesModel.Type()
    this.editing = false
    this.loadEntities()
  }

  async loadEntities() {
    try {
      this.entities = await this.model.getTypes()
    } catch (err) {
      console.error(err)
      this.notifications.error(err.message)
    }
  }

  doEditEntity(entity) {
    this.entity = new TypesModel.Type(entity)
    this.editing = true
  }

  doCancelEdit() {
    this.editing = false
  }

  async doSaveEntity(entity) {
    try {
      if (entity.isNew()) {
        const result = await this.model.createType(entity)
        this.editing = false
        this.loadEntities()
        return result
      } else {
        const result = await this.model.updateType(entity)
        this.editing = false
        this.loadEntities()
        return result
      }
    } catch (err) {
      console.error(err)
      this.notifications.error(err.message)
    }
  }

  async doDeleteEntity(entity) {
    if (await this.notifications.confirm('Are you sure you\'d like to delete this entity?')) {
      try {
        const result = await this.model.deleteType(entity)
        this.loadEntities()
        return result
      } catch (err) {
        console.error(err)
        this.notifications.error(err.message)
      }
    }
  }

  get fields() {
    return [
      { key: 'type_id', label: "ID", _style: { width: '6%'}, _classes: 'bg-light text-dark' },
      { key: 'name', _style: { width: '25%'} },
      { key: 'description' },
      {
        key: 'actions',
        label: '',
        _style: { width: '70px' },
        filter: false
      }
    ]
  }

}

TypesViewModel.useInstance = function() {
  return useInstanceOf(TypesViewModel, useApiClient(), useNotifications())
}
