import { useProperty, useBinding } from 'eilmer-mvvm/react'

import * as Core from '@coreui/react'
import CIcon from '@coreui/icons-react'

export const LoginModal = ({ authViewModel }) => {
  const errorMessage = useProperty(authViewModel, 'errorMessage')
  const [ emailAddress, setEmailAddress ] = useBinding(authViewModel.credentials, 'emailAddress')
  const [ password, setPassword ] = useBinding(authViewModel.credentials, 'password')

  const login = (e) => {
    e.preventDefault()
    authViewModel.doLogin()
  }

  return (
    <Core.CModal closeOnBackdrop={false} centered show={true}>
      <form onSubmit={login}>
        <Core.CModalHeader>Log In</Core.CModalHeader>
        <Core.CModalBody>
          {errorMessage &&
            <Core.CAlert color="danger">{errorMessage}</Core.CAlert>
          }
          <Core.CFormGroup row>
            <Core.CCol md="3">
              <Core.CLabel>Email Address:</Core.CLabel>
            </Core.CCol>
            <Core.CCol xs="12" md="9">
              <Core.CInput type="text" placeholder="User Name" value={emailAddress} onChange={
                (e) => setEmailAddress(e.target.value)
              }/>
            </Core.CCol>
          </Core.CFormGroup>
          <Core.CFormGroup row>
            <Core.CCol md="3">
              <Core.CLabel>Password:</Core.CLabel>
            </Core.CCol>
            <Core.CCol xs="12" md="9">
              <Core.CInput type="password" placeholder="Password" value={password} onChange={
                (e) => setPassword(e.target.value)
              }/>
            </Core.CCol>
          </Core.CFormGroup>
        </Core.CModalBody>
        <Core.CModalFooter>
          <Core.CButton color="primary" type="submit">
            Log In
          </Core.CButton>
        </Core.CModalFooter>
      </form>
    </Core.CModal>
  )
}
