import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { useProperty, useBinding } from 'eilmer-mvvm/react'

import * as Core from '@coreui/react'
import CIcon from '@coreui/icons-react'

import ItemTypeFieldsViewModel from '#viewmodel/ItemTypeFieldsViewModel'
import FieldTypesViewModel from '#viewmodel/FieldTypesViewModel'
import UserRolesViewModel from '#viewmodel/UserRolesViewModel'

import * as Forms from '#components/widgets/Forms'
import * as Entities from '#components/widgets/Entities'

export default function ItemTypeFields({ appViewModel }) {
  const { item_type_id } = useParams()
  appViewModel.breadcrumb = 'Developer / Item Types / Fields'
  const viewModel = ItemTypeFieldsViewModel.useInstance(item_type_id)

  const handleAddEntity = () => {
    viewModel.doEditEntity(null)
  }

  return (
    <Core.CCard style={{display: 'flex', flex: '1 1 0%'}}>
      <Core.CCardHeader>
        <div className="card-header-actions">
          <Core.CButton color="primary" size="sm" onClick={() => handleAddEntity()}>
            <CIcon name="cil-library-add"/>&nbsp;Add Field
          </Core.CButton>
        </div>
      </Core.CCardHeader>
      <Core.CCardBody>
        <EntityDataTable viewModel={viewModel}/>
      </Core.CCardBody>
      <EditEntityModal entityName="Field" viewModel={viewModel}/>
    </Core.CCard>
  )
}

function EntityDataTable({ viewModel }) {
  const entities = useProperty(viewModel, 'entities')

  const handleEditEntity = (entity) => {
    viewModel.doEditEntity(entity)
  }

  const handleDeleteEntity = (entity) => {
    viewModel.doDeleteEntity(entity)
  }

  return (
    <Entities.DataTable entities={entities} fields={viewModel.fields} scopedSlots={{
      'field_type':
        (entity)=>(
          <td>
            {entity.field_types[0].name}
          </td>
        ),
      'actions':
        (entity)=>(
          <td style={{textAlign:'right'}}>
            <a href="#" onClick={() => handleEditEntity(entity)}>
              <CIcon className="mr-2" title="Edit" name="cil-pencil"/>
            </a>
            <Link to={`/developer/item-field-validators/${entity.item_type_field_id}`}>
              <CIcon className="mr-2" title="Configure Validators" name="cil-check-circle"/>
            </Link>
            <a href="#" onClick={() => handleDeleteEntity(entity)}>
              <CIcon title="Delete" name="cil-trash"/>
            </a>
          </td>
        ),
    }}/>
  )
}

function EditEntityModal({ viewModel, entityName }) {
  const open = useProperty(viewModel, 'editing')
  const [ name, setName ] = useBinding(viewModel.entity, 'name')
  const [ description, setDescription ] = useBinding(viewModel.entity, 'description')
  const [ keyName, setKeyNameInternal ] = useBinding(viewModel.entity, 'key_name')
  const [ fieldTypeId, setFieldTypeId ] = useBinding(viewModel.entity, 'field_type_id')
  const [ parentFieldId, setParentFieldId ] = useBinding(viewModel.entity, 'parent_item_type_field_id')
  const [ userRoleId, setUserRoleId ] = useBinding(viewModel.entity, 'user_role_id')
  const [ sequence, setSequence ] = useBinding(viewModel.entity, 'sequence')
  const [ isPrivate, setIsPrivate ] = useBinding(viewModel.entity, 'private')
  const [ isRequired, setIsRequired ] = useBinding(viewModel.entity, 'required')
  const [ isSummary, setIsSummary ] = useBinding(viewModel.entity, 'summary')
  const [ fieldType, setFieldType ] = React.useState()

  const handleCancel = () => {
    viewModel.doCancelEdit()
  }

  const handleSave = () => {
    viewModel.doSaveEntity(viewModel.entity)
  }

  const setKeyName = (value) => {
    const keyName = value?.toLowerCase().replace(/[^a-z0-9_ ]/g, '').replace(/[ _]+/g, '_').trim()
    setKeyNameInternal(keyName)
  }

  React.useEffect(() => {
    (async () => {
      if (fieldType?.field_type_id !== fieldTypeId) {
        setFieldType(await viewModel.getFieldType(viewModel.entity))
      }
    })()
  }, [fieldTypeId])

  return (
    <Core.CModal centered closeOnBackdrop={false} show={open}>
      <Forms.ValidatingForm onSubmit={handleSave} onReset={handleCancel}>
        <Core.CModalHeader>Add/Edit {entityName}</Core.CModalHeader>
        <Core.CModalBody>
          <Core.CFormGroup row>
            <Core.CCol md="3">
              <Core.CLabel>Field Type</Core.CLabel>
            </Core.CCol>
            <Core.CCol xs="12" md="9">
              <FieldTypeDropdown required={true} value={fieldTypeId || ''} onChange={e => setFieldTypeId(e.target.value)}/>
              <small><em>The type of value to be stored in this field</em></small>
            </Core.CCol>
          </Core.CFormGroup>
          <Core.CFormGroup row>
            <Core.CCol md="3">
              <Core.CLabel>Name</Core.CLabel>
            </Core.CCol>
            <Core.CCol xs="12" md="9">
              <Forms.ValidatedFormControl>
                {({ ref }) => (
                  <Core.CInput innerRef={ref} required type="text" value={name || ''} onChange={e => setName(e.target.value)}/>
                )}
              </Forms.ValidatedFormControl>
              <small><em>For internal use to identify this field</em></small>
            </Core.CCol>
          </Core.CFormGroup>
          <Core.CFormGroup row>
            <Core.CCol md="3">
              <Core.CLabel>Description</Core.CLabel>
            </Core.CCol>
            <Core.CCol xs="12" md="9">
              <Forms.ValidatedFormControl>
                {({ ref }) => (
                  <Core.CInput innerRef={ref} required type="text" value={description || ''} onChange={e => setDescription(e.target.value)}/>
                )}
              </Forms.ValidatedFormControl>
              <small><em>For internal use to describe this field</em></small>
            </Core.CCol>
          </Core.CFormGroup>
          <Core.CFormGroup row>
            <Core.CCol md="3">
              <Core.CLabel>Key</Core.CLabel>
            </Core.CCol>
            <Core.CCol xs="12" md="9">
              <Forms.ValidatedFormControl>
                {({ ref }) => (
                  <Core.CInput innerRef={ref} required type="text" maxlength="20" value={keyName || ''} onChange={e => setKeyName(e.target.value)}/>
                )}
              </Forms.ValidatedFormControl>
              <small><em>A unique key used to identify this field</em></small>
            </Core.CCol>
          </Core.CFormGroup>
          <Core.CFormGroup row>
            <Core.CCol md="3">
              <Core.CLabel>User Role</Core.CLabel>
            </Core.CCol>
            <Core.CCol xs="12" md="9">
              <UserRoleDropdown required={false} value={userRoleId || ''} onChange={e => setUserRoleId(e.target.value)}/>
              <small><em>Users must have at least this role to use the field</em></small>
            </Core.CCol>
          </Core.CFormGroup>
          <Core.CFormGroup row>
            <Core.CCol md="3">
              <Core.CLabel>Sequence</Core.CLabel>
            </Core.CCol>
            <Core.CCol xs="12" md="9">
              <Core.CInput required type="number" value={sequence || '0'} onChange={e => setSequence(e.target.value)}/>
              <small><em>Determines the order of the fields when displayed for editing</em></small>
            </Core.CCol>
          </Core.CFormGroup>
          <Core.CFormGroup row>
            <Core.CCol md="3">
              <Core.CLabel>Required</Core.CLabel>
            </Core.CCol>
            <Core.CCol xs="12" md="9">
              <Core.CSwitch shape="pill" color="primary" checked={!!isRequired} onChange={e => setIsRequired(e.target.checked)}/>
              <div><small><em>Whether the field must be given a value or not</em></small></div>
            </Core.CCol>
          </Core.CFormGroup>
          <Core.CFormGroup row>
            <Core.CCol md="3">
              <Core.CLabel>Private</Core.CLabel>
            </Core.CCol>
            <Core.CCol xs="12" md="9">
              <Core.CSwitch shape="pill" color="primary" checked={!!isPrivate} onChange={e => setIsPrivate(e.target.checked)}/>
              <div><small><em>Website users need to be logged in to see this field</em></small></div>
            </Core.CCol>
          </Core.CFormGroup>
          <Core.CFormGroup row>
            <Core.CCol md="3">
              <Core.CLabel>Summary</Core.CLabel>
            </Core.CCol>
            <Core.CCol xs="12" md="9">
              <Core.CSwitch shape="pill" color="primary" checked={!!isSummary} onChange={e => setIsSummary(e.target.checked)}/>
              <div><small><em>Whether the field should be shown as a column in admin pages</em></small></div>
            </Core.CCol>
          </Core.CFormGroup>
          {fieldType?.type_id == 17 &&
            <Core.CFormGroup row>
              <Core.CCol md="3">
                <Core.CLabel>Default Value</Core.CLabel>
              </Core.CCol>
              <Core.CCol xs="12" md="9">
                <FieldDropdown required={false} value={parentFieldId} onChange={e => setParentFieldId(e.target.value)} viewModel={viewModel}/>
                <small><em>The field which this field should default to</em></small>
              </Core.CCol>
            </Core.CFormGroup>
          }
        </Core.CModalBody>
        <Core.CModalFooter>
          <Core.CButton color="secondary" type="reset">Cancel</Core.CButton>
          &nbsp;
          <Core.CButton color="primary" type="submit">Save</Core.CButton>
        </Core.CModalFooter>
      </Forms.ValidatingForm>
    </Core.CModal>
  )
}

function UserRoleDropdown({ required, value, onChange }) {
  const viewModel = UserRolesViewModel.useInstance()
  return (
    <Entities.EntityDropdown required={required} value={value} onChange={onChange} entityViewModel={viewModel}/>
  )
}

function FieldTypeDropdown({ required, value, onChange }) {
  const viewModel = FieldTypesViewModel.useInstance()
  return (
    <Entities.EntityDropdown required={required} value={value} onChange={onChange} entityViewModel={viewModel}/>
  )
}

function FieldDropdown({ viewModel, required, value, onChange }) {
  const entities = useProperty(viewModel, 'entities')
  const entityViewModel = {entities: entities?.filter((each => each.field_types[0].type_id == 7))}
  return (
    <Entities.EntityDropdown required={required} value={value} onChange={onChange} entityViewModel={entityViewModel}/>
  )
}
