import ItemTypes from '#utils/ItemTypes.js'
import TreeQLModel from './TreeQLModel'

export default class ItemTypeFieldsModel extends TreeQLModel {

  constructor(apiClient) {
    super(apiClient)
  }

  async getItemTypeFields(itemTypeId) {
    const options = {filter: `item_type_id,eq,${itemTypeId}`, order: 'sequence'}
    options.join = ['field_types', 'item_type_field_validators,validators', 'user_roles']
    return (await super.list('item_type_fields', options)).map(each => {
      each.field_types = [each.field_type_id]
      each.field_type_id = each.field_types[0]?.field_type_id
      each.user_roles = [each.user_role_id]
      each.user_role_id = each.user_roles[0]?.user_role_id
      return new ItemTypeFieldsModel.ItemTypeField(each)
    })
  }

  async createItemTypeField(itemTypeField) {
    const entity = new ItemTypeFieldsModel.ItemTypeField(itemTypeField)
    return await super.create('item_type_fields', entity)
  }

  async updateItemTypeField(itemTypeField) {
    const entity = new ItemTypeFieldsModel.ItemTypeField(itemTypeField, true)
    return await super.update('item_type_fields', entity.getPk(), entity)
  }

  async deleteItemTypeField(itemTypeField) {
    const entity = new ItemTypeFieldsModel.ItemTypeField(itemTypeField)
    const options = {filter: `field_id,eq,${entity.getPk()}`, include: 'value_id'}
    const values = await super.list('content_item_values', options)
    const deletes = values.map(each => each.value_id)
    if (deletes.length) await super.delete('content_item_values', deletes)
    try {
      return await super.delete('item_type_fields', entity.getPk())
    } catch (err) {
      if (values.length) {
        // Recreate the values so the delete is rolled back
        await super.create('content_item_values', values)
      }
      throw err
    }
  }

}

ItemTypeFieldsModel.ItemTypeField = class extends TreeQLModel.Entity {

  constructor(fields, forUpdate) {
    super(fields, 'item_type_field_id', forUpdate)
    this.field_type_id = Number(this.field_type_id)
    this.summary = this.summary ? 1 : 0
    this.private = this.private ? 1 : 0
    this.internal = this.item_type_id === ItemTypes.CONTENT_BLOCK && this.key_name === "properties"
    this.required = this.required ? 1 : 0
    this.sequence = Number(this.sequence || 0)
  }

  getUserRole() {
    return this.user_roles ? this.user_roles[0] : undefined
  }

  getFieldType() {
    return this.field_types ? this.field_types[0] : undefined
  }

  getValidators() {
    return this.item_type_field_validators
  }

}
