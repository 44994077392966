import React from 'react'
import {isMatch} from 'matcher'

import { useAppViewModel } from '#context/AppContext'
import styles from './MediaObject.module.scss'

const toCamelCase = (str) => {
  return str.replace(/([-_][a-z0-9])/ig, ($1) => {
    return $1.toUpperCase()
  }).replace(/-/ig, '')
}

const mimeTypeIcons = {
  'application/zip': 'cid-file-archive',
  'application/vnd.ms-excel': 'cid-file-excel',
  'application/vnd.ms-powerpoint': 'cid-file-powerpoint',
  'application/pdf': 'cid-file-pdf',
  'application/msword': 'cid-file-doc',
  'image/*': 'cid-file-image',
  'audio/*': 'cid-file-audio',
  'video/*': 'cid-file-video',
  '*': 'cid-file'
}

const previewImageMimeTypes = [
  'image/jpeg', 'image/gif', 'image/png'
]

export default function MediaObject({ mediaObject, selected, onSelect }) {
  const apiEndpoint = useAppViewModel().apiClient.endpoint
  let src = apiEndpoint + (mediaObject.thumbnailUrl || mediaObject.url)
  if (!previewImageMimeTypes.includes(mediaObject.contentType)) {
    const mimeTypeIcon = Object.entries(mimeTypeIcons).find(([key, value]) => {
      return isMatch(mediaObject.contentType, key)
    }) || ['default', 'cil-file']
    const icon = React.icons[toCamelCase(mimeTypeIcon[1])]
    const svgString = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ' + icon[0] + '" role="img">' + icon[1] + '</svg>'
    src = 'data:image/svg+xml,' + encodeURIComponent(svgString)
  }

  return (
    <div className={`${styles.mediaObject} ${selected ? styles.active : ''}`} onClick={() => onSelect(mediaObject)}>
      <div className={styles.thumbnail}>
        <div className="border rounded" style={{width:'100%', height:'100%'}}>
          <img src={src} style={{width:'100%', height:'100%', objectFit: 'contain'}}/>
        </div>
      </div>
      <div className={styles.caption}>
        {mediaObject.name} {mediaObject.imageWidth && mediaObject.imageHeight &&
          <small><br/><em>{mediaObject.imageWidth}x{mediaObject.imageHeight}</em></small>
        }
      </div>
    </div>
  )
}
