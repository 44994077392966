import UserFieldValidatorsModel from '#model/UserFieldValidatorsModel'
import { useApiClient } from '#context/ApiContext'
import { useNotifications } from '#context/NotificationContext'
import { useInstanceOf } from 'eilmer-mvvm/react'

export default class UserFieldValidatorsViewModel {

  constructor(apiClient, notificationsProvider, userFieldId) {
    this.model = new UserFieldValidatorsModel(apiClient)
    this.entity = new UserFieldValidatorsModel.UserFieldValidator()
    this.notifications = notificationsProvider
    this.userFieldId = Number(userFieldId)
    this.editing = false
    this.loadEntities()
  }

  async loadEntities() {
    try {
      this.entities = await this.model.getUserFieldValidators(this.userFieldId)
    } catch (err) {
      console.error(err)
      this.notifications.error(err.message)
    }
  }

  doEditEntity(entity) {
    const editingEntity = entity || {user_field_id: this.userFieldId, sequence: 0}
    this.entity = new UserFieldValidatorsModel.UserFieldValidator(editingEntity)
    this.editing = true
  }

  doCancelEdit() {
    this.editing = false
  }

  async doSaveEntity(entity) {
    try {
      if (entity.isNew()) {
        const result = await this.model.createUserFieldValidator(entity)
        this.editing = false
        this.loadEntities()
        return result
      } else {
        const result = await this.model.updateUserFieldValidator(entity)
        this.editing = false
        this.loadEntities()
        return result
      }
    } catch (err) {
      console.error(err)
      this.notifications.error(err.message)
    }
  }

  async doDeleteEntity(entity) {
    if (await this.notifications.confirm('Are you sure you\'d like to delete this entity?')) {
      try {
        const result = await this.model.deleteUserFieldValidator(entity)
        this.loadEntities()
        return result
      } catch (err) {
        console.error(err)
        this.notifications.error(err.message)
      }
    }
  }

  get fields() {
    return [
      { key: 'field_validator_id', label: 'ID', _style: { width: '6%'}, _classes: 'bg-light text-dark' },
      { key: 'sequence', label: 'Seq', _style: { width: '5%'} },
      { key: 'validator', label: 'Validator', _style: { width: '20%'} },
      { key: 'message', _style: { width: '40%'} },
      { key: 'value', _style: { width: '20%'} },
      {
        key: 'actions',
        label: '',
        _style: { width: '90px' },
        filter: false
      }
    ]
  }

}

UserFieldValidatorsViewModel.useInstance = function(userFieldId) {
  return useInstanceOf(UserFieldValidatorsViewModel, useApiClient(), useNotifications(), userFieldId)
}
