import ItemTypes from '#utils/ItemTypes.js'

export default class TreeQLModel {

  constructor(apiClient) {
    this.apiClient = apiClient
  }

  async list(table, options) {
    try {
      return (await this.apiClient.list(table, options)).records
    } catch (err) {
      throw new Error('Server Error: ' + err.message)
    }
  }

  async create(table, options) {
    try {
      return await this.apiClient.create(table, options)
    } catch (err) {
      const message = err.message ?? 'Uknown error - check for duplicate keys'
      throw new Error('Server Error: ' + message)
    }
  }

  async read(table, id, options) {
    try {
      return await this.apiClient.read(table, id, options)
    } catch (err) {
      throw new Error('Server Error: ' + err.message)
    }
  }

  async update(table, id, options) {
    try {
      return await this.apiClient.update(table, id, options)
    } catch (err) {
      const message = err.message ?? 'Uknown error - check for duplicate keys'
      throw new Error('Server Error: ' + message)
    }
  }

  async delete(table, id) {
    try {
      return await this.apiClient.delete(table, id)
    } catch (err) {
      if (err.code === 1010 && err.details) {
        if (err.details.includes('FOREIGN KEY ("parent_item_id") REFERENCES "content_items" ("item_id")')) {
          // This means that there are child items which this item is the parent of
          const parent = await this.read(table, id)
          const children = await this.list(table, {filter:`parent_item_id,eq,${id}`})
          if (parent?.item_type_id === ItemTypes.PAGE && children.length) {
            if (children[0].item_type_id === ItemTypes.CONTENT_BLOCK) {
              throw new Error('This page cannot be deleted as it contains content.' 
                + ' Please edit the page and delete the content before deleting.')
            }
          }
        }
      }
      throw new Error('Server Error: ' + err.message)
    }
  }

}

TreeQLModel.Entity = class {

  constructor(fields, pkField, forUpdate) {
    this.pkField = pkField
    this.forUpdate = forUpdate
    Object.assign(this, fields)
  }

  isNew() {
    return !this[this.pkField]
  }

  getPk() {
    return this[this.pkField]
  }

  getParentPk() {
    return this['parent_' + this.pkField]
  }

  toJSON() {
    const json = Object.assign({}, this)
    delete json.pkField
    delete json.forUpdate
    if (this.forUpdate) {
      delete json[this.pkField]
    }
    return json
  }
}
