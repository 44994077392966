import ItemsModel from '#model/ItemsModel'
import ItemTypesModel from '#model/ItemTypesModel'

// TODO: So far, this seems to be acting as a item children model,
// maybe rename it unless I get a better idea


import ContentModel from '#model/ContentModel'
import { useApiClient } from '#context/ApiContext'
import { useWebsiteId } from '#context/AppContext'
import { useNotifications } from '#context/NotificationContext'
import { useInstanceOf } from 'eilmer-mvvm/react'

const MENU_ITEM_TYPE = 1
const MENU_ITEM_ITEM_TYPE = 2

export default class MenusViewModel {

  constructor(apiClient, notificationsProvider, websiteId) {
    this.contentModel = new ContentModel(apiClient)
    this.itemTypesModel = new ItemTypesModel(apiClient)
    this.itemsModel = new ItemsModel(apiClient)
    this.notifications = notificationsProvider
    this.apiClient = apiClient
    this.websiteId = websiteId
    this.menuItems = []
    this.menus = []
    this.loadMenus()
  }

  async loadMenus() {
    try {
      const menuItemType = await this.itemTypesModel.getItemType(MENU_ITEM_TYPE)
      this.subMenuSupported = menuItemType.parent_type_id === MENU_ITEM_TYPE
      this.menus = await this.itemsModel.getItems(MENU_ITEM_TYPE, this.websiteId, null)
      this.selectedMenu = this.menus[0]
    } catch (err) {
      console.error(err)
      this.notifications.error(err.message)
    }
  }

  async loadMenuItems(menu) {
    try {
      let menuItems = await this.contentModel.getContent(menu.item_id, menu.website_id)
      const subMenuIds = menuItems.filter((each) => each.item_type_id === MENU_ITEM_TYPE).map(each => each.item_id)
      if (subMenuIds.length) menuItems = menuItems.concat(await this.contentModel.getContent(subMenuIds, menu.website_id))
      menuItems.sort((a, b) => a.sequence - b.sequence)
      this.menuItems = menuItems
    } catch (err) {
      console.error(err)
      this.notifications.error(err.message)
    }
  }

  async doSaveMenuItems(menuItems) {
    // TODO There is an issue where if you try to delete the parent of an item and not the children, theres a data integrity violation
    try {
      let saved = [], removed = [], last = [this.selectedMenu.item_id]
      menuItems.sort((a, b) => a.layout.y - b.layout.y)
      for (let i = 0; i < menuItems.length; i++) {
        const item = new ItemsModel.Item({ ...menuItems[i], sequence: i})
        item.parent_item_id = last[menuItems[i].layout.x] || item.parent_item_id 
        delete item.layout
        if (item.isNew()) {
          item.item_id = await this.itemsModel.createItem(item)
          saved.push(item.item_id)
        } else {
          await this.itemsModel.updateItem(item)
          saved.push(item.item_id)
        }
        if (item.item_type_id == MENU_ITEM_TYPE) {
          last[menuItems[i].layout.x + 1] = item.item_id
        } else {
          delete last[menuItems[i].layout.x + 1]
        }
      }
      removed = this.menuItems.filter(each => !saved.includes(each.item_id))
      for (let i = 0; i < removed.length; i++) {
        await this.itemsModel.deleteItem(removed[i])
      }
      await this.loadMenuItems(this.selectedMenu)
    } catch (err) {
      console.error(err)
      this.notifications.error(err.message)
    }
   }

  newMenuItem(menu) {
    return new ItemsModel.Item({
      name:'Untitled',
      description: 'A menu item',
      item_type_id: MENU_ITEM_ITEM_TYPE,
      parent_item_id: menu.item_id,
      website_id: this.websiteId
    })
  }

  newMenu(menu) {
    return new ItemsModel.Item({
      name:'Untitled',
      description: 'A sub menu',
      item_type_id: MENU_ITEM_TYPE,
      parent_item_id: menu.item_id,
      website_id: this.websiteId
    })
  }

  get selectedMenu() {
    return this._menu
  }

  set selectedMenu(menu) {
    this.loadMenuItems(menu)
    this._menu = menu
  }

}

MenusViewModel.useInstance = function() {
  return useInstanceOf(MenusViewModel, useApiClient(), useNotifications(), useWebsiteId())
}
