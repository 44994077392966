import WebsitesModel from '#model/WebsitesModel'
import ItemTypesModel from '#model/ItemTypesModel'
import { useApiClient } from '#context/ApiContext'
import { useNotifications } from '#context/NotificationContext'
import { useInstanceOf } from 'eilmer-mvvm/react'

export default class AppViewModel {

  constructor(apiClient, notificationsProvider) {
    this.websitesModel = new WebsitesModel(apiClient)
    this.itemTypesModel = new ItemTypesModel(apiClient)
    this.notifications = notificationsProvider
    this.apiClient = apiClient
    this.hasUnsavedChanges = false
    this.websiteId = 1
    this.website = null
    this.breadcrumb = ''
    this.load()
  }

  async load() {
    try {
      this.website = await this.websitesModel.getWebsite(this.websiteId)
      const itemTypes = await this.itemTypesModel.getItemTypes()
      itemTypes.sort((a, b) => (a.sequence || 0) - (b.sequence || 0))
      this.itemTypes = itemTypes
    } catch (err) {
      console.error(err)
      this.notifications.error(err.message)
    }
  }

  get unsavedChanges() {
    return this.hasUnsavedChanges
  }

  set unsavedChanges(value) {
    this.hasUnsavedChanges = value
  }

}

AppViewModel.useInstance = function() {
  return useInstanceOf(AppViewModel, useApiClient(), useNotifications())
}
