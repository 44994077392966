import Types from '#primitives/Types'

export default class Values {
  static getValue(fieldType, contentItemValue) {
    switch (fieldType.type_id) {
      case Types.TEXT:
        return this.getText(contentItemValue)
      case Types.DATE:
        return this.getDate(contentItemValue)
      case Types.ITEM:
        return this.getItemID(contentItemValue)
      case Types.TEXTAREA:
        return this.getText(contentItemValue)
      case Types.FILE:
        return this.getFile(contentItemValue)
      case Types.URL:
        return this.getText(contentItemValue)
      case Types.BOOLEAN:
        return contentItemValue?.bool_value || false
      case Types.TIME:
        return this.getText(contentItemValue)
      case Types.HTML:
        return this.getText(contentItemValue)
      case Types.SLUG:
        return this.getKey(contentItemValue)
    }
  }

  static setValue(fieldType, contentItemValue, value) {
    switch (fieldType.type_id) {
      case Types.TEXT:
        return this.setText(contentItemValue, value)
      case Types.DATE:
        return this.setDate(contentItemValue, value)
      case Types.ITEM:
        return this.setItemID(contentItemValue, value)
      case Types.TEXTAREA:
        return this.setText(contentItemValue, value)
      case Types.FILE:
        return this.setFile(contentItemValue, value)
      case Types.URL:
        return this.setText(contentItemValue, value)
      case Types.BOOLEAN:
        return this.setBoolean(contentItemValue, value)
      case Types.TIME:
        return this.setText(contentItemValue, value)
      case Types.HTML:
        return this.setText(contentItemValue, value)
      case Types.SLUG:
        const slug = value
          ?.toLowerCase()
          .replace(/[^a-z- ]/g, '')
          .replace(/[ -]+/g, '-')
          .trim()
        return this.setKey(contentItemValue, slug)
    }
  }

  static getKey(contentItemValue) {
    return contentItemValue?.key_value || ''
  }

  static setKey(contentItemValue, value) {
    contentItemValue.key_value = value?.length ? value : null
    return contentItemValue
  }

  static getText(contentItemValue) {
    return contentItemValue?.text_value || ''
  }

  static setText(contentItemValue, value) {
    contentItemValue.text_value = value
    return contentItemValue
  }

  static getDate(contentItemValue) {
    return (contentItemValue?.date_value || '').substr(0, 10)
  }

  static setDate(contentItemValue, value) {
    contentItemValue.date_value = value
    return contentItemValue
  }

  static getItemID(contentItemValue) {
    return contentItemValue?.item_value || null
  }

  static setItemID(contentItemValue, value) {
    contentItemValue.item_value = value
    return contentItemValue
  }

  static getFile(contentItemValue) {
    return JSON.parse(contentItemValue?.json_value || 'null') || null
  }

  static setFile(contentItemValue, value) {
    if (Array.isArray(value)) {
      const uploadFile = value[0],
        dataUrl = value[1]
      // TODO: Check the argument types
      if (uploadFile !== null && dataUrl !== null) {
        const base64Value = dataUrl.substr(dataUrl.indexOf(',') + 1)
        const json = { name: uploadFile.name, size: uploadFile.size }
        if (uploadFile.imageWidth) json.imageWidth = uploadFile.imageWidth
        if (uploadFile.imageHeight) json.imageHeight = uploadFile.imageHeight
        json.contentType = uploadFile.type
        contentItemValue.text_value = uploadFile.type
        contentItemValue.json_value = JSON.stringify(json)
        contentItemValue.base64_value = base64Value
      } else {
        contentItemValue.text_value = null
        contentItemValue.json_value = null
        contentItemValue.base64_value = null
      }
    } else if (value === null) {
      contentItemValue.text_value = null
      contentItemValue.json_value = null
      contentItemValue.base64_value = null
    }
    return contentItemValue
  }

  static getBoolean(contentItemValue) {
    return contentItemValue?.bool_value || false
  }

  static setBoolean(contentItemValue, value) {
    contentItemValue.bool_value = value
    return contentItemValue
  }
}
