import { Converter } from 'eilmer-mvvm/converters'
import Values from '#utils/Values'

export default class FieldValueConverter extends Converter {

    constructor(fieldType) {
      super()
      this.fieldType = fieldType
    }
  
    convertFrom(viewModelValue, bindingContext) {
      return Values.getValue(this.fieldType, viewModelValue)
    }
  
    convertTo(viewValue, bindingContext) {
      return Values.setValue(this.fieldType, {}, viewValue)
    }
  }
  