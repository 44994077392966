import React, { useState, useRef, useEffect } from 'react'

import styles from './DynamicList.module.scss'

export default function DynamicList({ draggable, items, onItemClick, onChange, render }) {
  const [ listItems, setListItems ] = useState(items || [])
  const grid = useRef()
  const ref = useRef()
  let dropIdx = null

  const isLast = (el) => {
    if (el.nextElementSibling) {
      let hovered = el.classList.contains(styles.hoverLeft)
      hovered = hovered ||  el.classList.contains(styles.hoverRight)
      const width = el.offsetWidth * (hovered ? 1 : 2)
      return el.parentElement.offsetWidth < (el.offsetLeft + width)
    } else {
      return true
    }
  }

  const onDragEnter = (e) => {
    const gridItem = e.target.classList.contains(styles.listItem)
    const current = gridItem ? e.target : e.target.parentElement
    if (current.dragging !== true) {
      const last = isLast(current)
      const previous = current.previousElementSibling

      // console.log('Entering:', current.getAttribute('data-index'))
      grid.current.classList.add(styles.dragging)

      if (!last && ref.current !== current) {
        ref.current = current
        if (!ref.current.classList.contains(styles.hoverLeft)) {
          if (!ref.current.classList.contains(styles.hoverRight)) {
            // console.log('1. Hover Left', ref.current.getAttribute('data-index'))
            ref.current.classList.add(styles.hoverLeft)
          }
        }
      } else if (last && ref.current !== current) {
        ref.current = previous
        if (!ref.current.classList.contains(styles.hoverRight)) {
          if (!ref.current.classList.contains(styles.hoverLeft)) {
            // console.log('1. Hover Right', ref.current.getAttribute('data-index'))
            ref.current.classList.add(styles.hoverRight)
          }
        }
      } else if (!gridItem && ref.current.classList.contains(styles.hoverLeft)) {
        // console.log('2. Hover Left to Right', ref.current.getAttribute('data-index'))
        ref.current.classList.replace(styles.hoverLeft, styles.hoverRight)
      } else if (!gridItem && current.classList.contains(styles.hoverRight)) {
        // console.log('2. Hover Right to Left', ref.current.getAttribute('data-index'))
        ref.current.classList.replace(styles.hoverRight, styles.hoverLeft)
      }
    }
  }

  const onDragLeave = (e) => {
    const gridItem = e.target.classList.contains(styles.listItem)
    const current = gridItem ? e.target : e.target.parentElement
    if (current.dragging !== true) {
      if (e.relatedTarget !== e.target.firstElementChild && gridItem) {
        console.log('Leaving:', current.getAttribute('data-index'))
        current.classList.remove(styles.hoverLeft)
        current.classList.remove(styles.hoverRight)
        if (ref.current === current) {
          ref.current = null
        }
      }
    }
  }

  const onDrop = (e) => {
    if (ref.current) {
      const before = ref.current.classList.contains(styles.hoverLeft)
      const dragIdx = e.dataTransfer.getData('application/x-drag-index')
      const draggedItem = listItems[dragIdx]
      const droppedItem = listItems[dropIdx]
      const sortedItems = listItems.slice().sort((a, b) => {
        const aIdx = listItems.indexOf(a)
        const bIdx = listItems.indexOf(b)
        if (a === draggedItem && b === droppedItem) {
          return before ? -1 : 1
        } else if (a === droppedItem && b === draggedItem) {
          return before ? 1 : -1
        } else if (a === draggedItem) {
          return dropIdx - bIdx
        } else if (b === draggedItem) {
          return aIdx - dropIdx
        }
        return aIdx - bIdx
      })
      grid.current.classList.remove(styles.dragging)
      ref.current.classList.remove(styles.hoverLeft)
      ref.current.classList.remove(styles.hoverRight)
      ref.current = null
      setListItems(sortedItems)
      if (typeof onChange === 'function') {
        onChange(sortedItems)
      }
    } else {
      grid.current.classList.remove(styles.dragging)
    }
  }

  const onDragOver = (e, idx) => {
    e.preventDefault()
    e.stopPropagation()
    dropIdx = idx
  }

  const onDragStart = (e, idx) => {
    e.dataTransfer.setData('application/x-drag-index', idx)
    e.target.dragging = true
  }

  const onDragEnd = (e) => {
    const el = e.target
    grid.current.classList.remove(styles.dragging)
    el.classList.remove(styles.hoverLeft)
    el.classList.remove(styles.hoverRight)
    el.style.display = ''
    e.target.dragging = false
  }

  const onDrag = (e) => {
    const el = e.target
    if (el.style.display !== 'none') {
      const next = el.nextElementSibling
      const previous = el.previousElementSibling
      const last = isLast(el)
      el.style.display = 'none'
      if (last) {
        //console.log('0. Hover Right', previous.getAttribute('data-index'))
        previous.classList.add(styles.hoverRight)
      } else {
        //console.log('0. Hover Left', next.getAttribute('data-index'))
        next.classList.add(styles.hoverLeft)
      }
    }
  }

  const onClick = (e, item) => {
    if (typeof onItemClick === 'function') {
      onItemClick(e, item)
    }
  }

  useEffect(() => {
    setListItems(items)
  }, [items])

  return (
    <div ref={grid} className={styles.list}>
      {listItems.map((item, idx) => {
        return (
          <div key={item.id} data-index={idx} draggable={draggable} className={`${styles.listItem} ${draggable ? styles.draggable : ''}`} onClick={(e) => onClick(e, item)} onDragEnter={onDragEnter} onDragLeave={onDragLeave} onDrag={onDrag} onDragStart={(e) => onDragStart(e, idx)} onDragEnd={onDragEnd} onDragOver={(e) => onDragOver(e, idx)} onDrop={onDrop}>
            {render(item)}
          </div>
        )
      })}
    </div>
  )
}
