import React from 'react'
import ReactQuill, {Quill} from 'react-quill'

import 'react-quill/dist/quill.snow.css'

const MODULES = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'link'],
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    [{ 'header': [1, 2, 3, false] }],
    ['clean']                                         // remove formatting button
  ]
}

const FORMATS = [
  'bold', 'italic', 'link', 'size', 'strike',
  'underline', 'script', 'blockquote', 'header',
  'list'
]

const Link = Quill.import('formats/link');

class CustomLink extends Link {
  static create(value) {
    let node = super.create(value)
    value = this.sanitize(value)
    node.setAttribute('href', value)
    if (value.startsWith('page:')) {
      node.removeAttribute('target')
      node.removeAttribute('rel')
    }
    return node
  }
}

CustomLink.PROTOCOL_WHITELIST.push('page')
Quill.register(CustomLink);

export default function HtmlEditor({ value, onChange, ...props }) {
  const { textStyles = true, blockQuotes = true, links = true, lists = true, headers = true, ...remainingProps } = props
  const [ modules ] = React.useState(() => {
    const toolbar = []
    if (textStyles) toolbar.push(['bold', 'italic', 'underline', 'strike'])
    // TODO: Need logic for this.... 
    toolbar.push(['blockquote', 'link'])
    if (lists) toolbar.push([{ 'list': 'ordered'}, { 'list': 'bullet' }])
    if (headers) toolbar.push([{ 'header': [1, 2, 3, false] }])
    toolbar.push(['clean'])
    return { toolbar }
  })

  const changeHandler = (v) => {
    const text = v === '<p><br></p>' ? '' : v
    onChange({target:{value:text}})
  }

  return (
    <ReactQuill value={value} modules={modules} formats={FORMATS} onChange={changeHandler} {...remainingProps}/>      
  )
}
