import WebsitesModel from '#model/WebsitesModel'
import { useApiClient } from '#context/ApiContext'
import { useNotifications } from '#context/NotificationContext'
import { useWebsiteId } from '#context/AppContext'
import { useInstanceOf } from 'eilmer-mvvm/react'

export default class DashboardViewModel {

  constructor(apiClient, notificationsProvider, websiteId) {
    this.model = new WebsitesModel(apiClient)
    this.notifications = notificationsProvider
    this.loadWebsite(websiteId)
  }

  async loadWebsite(websiteId) {
    try {
      this.website = await this.model.getWebsite(websiteId)
    } catch (err) {
      console.error(err)
      this.notifications.error(err.message)
    }
  }

  async doSaveWebsite(website) {
    try {
      await this.model.updateWebsite(website)
    } catch (err) {
      console.error(err)
      this.notifications.error(err.message)
    }
  }

}

DashboardViewModel.useInstance = function() {
  return useInstanceOf(DashboardViewModel, useApiClient(), useNotifications(), useWebsiteId())
}
