import ConstraintsModel from '#model/ConstraintsModel'
import { useApiClient } from '#context/ApiContext'
import { useNotifications } from '#context/NotificationContext'
import { useInstanceOf } from 'eilmer-mvvm/react'

export default class ConstraintsViewModel {

  constructor(apiClient, notificationsProvider) {
    this.model = new ConstraintsModel(apiClient)
    this.notifications = notificationsProvider
    this.entity = new ConstraintsModel.Constraint()
    this.editing = false
    this.loadEntities()
  }

  async loadEntities() {
    try {
      this.entities = await this.model.getConstraints()
    } catch (err) {
      console.error(err)
      this.notifications.error(err.message)
    }
  }

  doEditEntity(entity) {
    this.entity = new ConstraintsModel.Constraint(entity)
    this.editing = true
  }

  doCancelEdit() {
    this.editing = false
  }

  async doSaveEntity(entity) {
    try {
      if (entity.isNew()) {
        const result = await this.model.createConstraint(entity)
        this.editing = false
        this.loadEntities()
        return result
      } else {
        const result = await this.model.updateConstraint(entity)
        this.editing = false
        this.loadEntities()
        return result
      }
    } catch (err) {
      console.error(err)
      this.notifications.error(err.message)
    }
  }

  async doDeleteEntity(entity) {
    if (await this.notifications.confirm('Are you sure you\'d like to delete this entity?')) {
      try {
        const result = await this.model.deleteConstraint(entity)
        this.loadEntities()
        return result
      } catch (err) {
        console.error(err)
        this.notifications.error(err.message)
      }
    }
  }

  get fields() {
    return [
      { key: 'constraint_id', label: "ID", _style: { width: '6%'}, _classes: 'bg-light text-dark' },
      { key: 'name', _style: { width: '25%'} },
      { key: 'description' },
      {
        key: 'actions',
        label: '',
        _style: { width: '70px' },
        filter: false
      }
    ]
  }

}

ConstraintsViewModel.useInstance = function() {
  return useInstanceOf(ConstraintsViewModel, useApiClient(), useNotifications())
}
