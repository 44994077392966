import React from 'react'
import { useProperty } from 'eilmer-mvvm/react'

import * as Core from '@coreui/react'
import {isMatch} from 'matcher'

import MediaLibrary from '#components/misc/MediaLibrary'
import MediaViewModel from '#viewmodel/MediaViewModel'

const WEBSITE_ID = 1

const MediaLibraryContext = React.createContext()

function MediaLibraryProvider({ children }) {
  const mediaViewModel = MediaViewModel.useInstance(WEBSITE_ID, false)
  const [ resolveFunction, setResolveFunction ] = React.useState(null)
  const [ contentType, setContentType ] = React.useState(null)
  const [ mediaObject, setMediaObject ] = React.useState(null)
  const mediaData = useProperty(mediaViewModel, 'media')
  const show = mediaData && typeof resolveFunction === 'function'

  const provider = {
    selectMediaObject: function(contentType) {
      return new Promise((resolve) => {
        if (!mediaData) mediaViewModel.doLoadMedia()
        setResolveFunction(() => resolve)
        setContentType(contentType)
        setMediaObject(null)
      })
    },
    getMediaObject: async function(itemId) {
      if (itemId) return await mediaViewModel.getMediaObject(itemId)
      return null
    }
  }

  const onMediaObjectSelected = (selectedMediaObject) => {
    setMediaObject(selectedMediaObject)
  }

  // onMediaObjectSelected, deleteMediaObjectHandler, uploadMediaObjectHandler, refreshHandler
  const deleteMediaObjectHandler = mediaViewModel.doDeleteMediaObject.bind(mediaViewModel)
  const uploadMediaObjectHandler = mediaViewModel.doCreateMediaObject.bind(mediaViewModel)
  const refreshHandler = mediaViewModel.doLoadMedia.bind(mediaViewModel)

  const onMediaObject = () => {
    setResolveFunction(null)
    resolveFunction(mediaObject)
  }

  const isMediaObjectValid = () => {
    if (mediaObject && contentType) {
      return isMatch(mediaObject.contentType, contentType)
    } else {
      return !!mediaObject
    }
  }

  const eventHandlers = {
    onMediaObjectSelected,
    deleteMediaObjectHandler,
    uploadMediaObjectHandler,
    refreshHandler
  }

  return (
    <MediaLibraryContext.Provider value={provider}>
      {children}
      <Core.CModal centered closeOnBackdrop={false} size="xl" show={show}>
        <Core.CModalHeader>Media Library</Core.CModalHeader>
        <Core.CModalBody>
          <Core.CContainer style={{height: '400px', display: 'flex'}}>
            {show && <MediaLibrary media={mediaData} {...eventHandlers}/>}
          </Core.CContainer>
        </Core.CModalBody>
        <Core.CModalFooter>
          <Core.CButton color="secondary" onClick={() => setResolveFunction(null)}>Cancel</Core.CButton>
          &nbsp;
          <Core.CButton color="primary" disabled={!isMediaObjectValid()} onClick={onMediaObject}>Choose Item</Core.CButton>
        </Core.CModalFooter>
      </Core.CModal>
    </MediaLibraryContext.Provider>
  )
}

function MediaLibraryModal() {


}

export function useMediaLibrary() {
  return React.useContext(MediaLibraryContext)
}

export default MediaLibraryProvider
