import * as Core from '@coreui/react'
import CIcon from '@coreui/icons-react'

import { useAuthContext } from '#context/AuthContext'

export default function AdminAccount({ user }) {
  const authViewModel = useAuthContext()

  const logout = () => {
    authViewModel.doLogout()
  }

  console.log(user)

  return (
    <Core.CDropdown inNav className="c-header-nav-item mx-2">
      <Core.CDropdownToggle className="c-header-nav-link" caret={false}>
        <CIcon name="cil-user"/>&nbsp;{user?.user_name} ({user?.getUserRole().name})
      </Core.CDropdownToggle>
      <Core.CDropdownMenu  placement="bottom-end" className="pt-0">
        {/*
        <Core.CDropdownItem>
          <CIcon name="cil-contact" className="mfe-2"/>
          Edit Profile
        </Core.CDropdownItem>
        */}
        <Core.CDropdownItem onClick={logout}>
          <CIcon name="cil-account-logout" className="mfe-2"/>
          Sign Out
        </Core.CDropdownItem>
      </Core.CDropdownMenu>
    </Core.CDropdown>
  )
}
