import ItemsModel from '#model/ItemsModel'
import WebsitesModel from '#model/WebsitesModel'
import { useApiClient } from '#context/ApiContext'
import { useNotifications } from '#context/NotificationContext'
import { useInstanceOf } from 'eilmer-mvvm/react'

export default class SettingsViewModel {

  constructor(apiClient, notificationsProvider, websiteId) {
    this.model = new WebsitesModel(apiClient)
    this.itemsModel = new ItemsModel(apiClient)
    this.notifications = notificationsProvider
    this.websiteId = websiteId
    this.entity = null
    this.loadEntities()
  }

  async loadEntities() {
    try {
      const entity = await this.model.getWebsite(this.websiteId)
      if (entity.settings_item_id) {
        entity.settings_item = await this.itemsModel.getItem(entity.settings_item_id)
      }
      this.entity = entity
    } catch (err) {
      console.error(err)
      this.notifications.error(err.message)
    }
  }

  async doSaveEntity(entity) {
    try {
      if (entity.isNew()) {
        throw new Error('New websites cannot be created via the settings editor')
      } else {
        const result = await this.model.updateWebsite(entity)
        await this.itemsModel.updateItem(entity.settings_item)
        this.loadEntities()
        return result
      }
    } catch (err) {
      console.error(err)
      this.notifications.error(err.message)
    }
  }

}

SettingsViewModel.useInstance = function(websiteId) {
  return useInstanceOf(SettingsViewModel, useApiClient(), useNotifications(), websiteId)
}
