import React, { useState, useRef, useEffect } from 'react'

import * as Core from '@coreui/react'
import CIcon from '@coreui/icons-react'
import GridLayout from 'react-grid-layout'
import useResizeObserver from '@react-hook/resize-observer'

import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'

export const DynamicLayout = React.forwardRef(function({ cols, defaultItems, itemWidth, itemHeight, itemComponent, onItemDrop, onChange }, ref) {
  const [ items, setItems ] = useState([])
  const [ counter, setCounter ] = useState(0)
  const [ width, setWidth ] = useState(0)
  const ItemComponent = itemComponent
  const container = useRef(null)
  const innerRef = useRef(null)
  const grid = useRef(null)

  const onLayoutChange = (layout) => {
    const _newlayout = layout.filter(each => each.i !== '__dropping-elem__').sort((a, b) => a.y - b.y)
    const _oldlayout = items.map(each => each.layout).sort((a, b) => a.y - b.y)
    const changed = _newlayout.find((each, idx) => {
      const old = _oldlayout[idx]
      const unchanged = !old || (old && old.i == each.i && old.x == each.x)
      return !unchanged
    })
    layout.forEach(each => {
      const item = items.find(candidate => candidate.layout.i === each.i)
      if (item) item.layout = { ...each }
    })
    if (changed && typeof onChange === 'function') {
      onChange(items)
    }
  }

  const onDrop = (layout, layoutItem, event) => {
    if (typeof onItemDrop === 'function') {
      const itemLayout = { x: layoutItem.x, y: layoutItem.y }
      onItemDrop(event, itemLayout)
    }
  }

  const addItem = (item) => {
    let _items
    const positioned = !!item.layout
    item.layout = item.layout || {}
    item.layout.i = counter.toString()
    item.layout.x = item.layout.x || 0
    item.layout.w = item.layout.w || itemWidth || 1
    item.layout.h = item.layout.h || itemHeight || 1
    item.layout.y = item.layout.y || Infinity
    if (!positioned) {
      _items = items.concat(item)
    } else {
      _items = [ item, ...items]
    }
    setItems(_items)
    setCounter(counter + 1)
    if (typeof onChange === 'function') {
      onChange(_items)
    }
  }

  const removeItem = (index) => {
    const _items = items.map(item => item)
    _items.splice(index, 1)
    setItems(_items)
    if (typeof onChange === 'function') {
      onChange(_items)
    }
  }

  const updateItem = (index, item) => {
    const old = grid.current.state.layout[index]
    item.layout.h += item.layout.h !== old.h ? 10 : 0
    const _items = items.map((each, idx) => {
      return idx === index ? item : each
    })
    setItems(_items)
  }

  const removeAll = () => {
    const _items = []
    setItems(_items)
    if (typeof onChange === 'function') {
      onChange(_items)
    }
  }

  const getItems = () => {
    return items
  }  

  if (ref !== undefined && ref !== null) {
    ref.current = { addItem, removeItem, removeAll, updateItem, getItems }
  }

  useEffect(() => {
    function handleResize() {
      setWidth(container.current.offsetWidth)
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize);
  }, [container.current])

  useEffect(() => {
    if (defaultItems) {
      const _counter = counter + defaultItems.length
      const _items = defaultItems.map((each, idx) => {
        const item = { ...each }
        item.layout = item.layout || {}
        item.layout.i = (counter + idx).toString()
        item.layout.x = item.layout.x || 0
        item.layout.w = item.layout.w || itemWidth || 1
        item.layout.h = item.layout.h || itemHeight || 1
        item.layout.y = item.layout.y || itemHeight * idx
        return item
      })
      setCounter(_counter)
      setItems(_items)
    } else {
      setCounter(0)
      setItems([])
    }
  }, [defaultItems])

  useEffect(() => {
    innerRef.current.style['min-height'] = (itemHeight - 10) + 'px'
  }, [])

  const layout = items.map(each => ({ ...each.layout }))
  const droppingItem = { w: itemWidth || 1, h: itemHeight || 1, i: '__dropping-elem__'}

  return (
    <div ref={container}>
      <GridLayout ref={grid} innerRef={innerRef} className="layout" onDrop={onDrop} isDroppable={true} droppingItem={droppingItem} onLayoutChange={onLayoutChange} layout={layout} margin={[0,0]} cols={cols} width={width} rowHeight={1} isResizable={false} draggableCancel=".collapse">
        {items.map((each, index) =>
          <div key={each.layout.i} style={{cursor:'move'}}>
            <ItemComponent item={each} removeItemHandler={() => removeItem(index)} updateItemHandler={(item) => updateItem(index, item)}/>
          </div>
        )}
      </GridLayout>
    </div>
  )
})

export const CollapsibleGridCard = function({ colour, title, subtitle, item, updateItem, children }) {
  const target = useRef(null)
  const [collapse, setCollapse] = useState(true)
  const icon = collapse ? 'cil-caret-bottom' : 'cil-caret-right'

  useResizeObserver(target, (entry) => {
    if (item) {
      item.layout.h = entry.contentRect.height
      updateItem(item)
    }
  })

  return (
      <div ref={target}>
        <Core.CCard accentColor={colour}>
          <Core.CCardHeader>
            {title}
            <div className="card-header-actions">
              <span className="mr-2"><em>{subtitle}</em></span>
              <Core.CButton size="sm" style={{padding: 0, outline: 'none', boxShadow:'none'}}>
                <CIcon name={icon} onClick={() => { setCollapse(!collapse) }}/>
              </Core.CButton>
            </div>
          </Core.CCardHeader>
          <Core.CCollapse show={!collapse}>
            <Core.CCardBody style={{cursor:'default'}} draggable={false}>
              {children}
            </Core.CCardBody>
          </Core.CCollapse>
        </Core.CCard>
      </div>
  )
}
