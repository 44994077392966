import { Converter } from 'eilmer-mvvm/converters'

export default class JsonConverter extends Converter {

    constructor(defaultValue) {
      super()
      this.defaultJson = JSON.stringify(defaultValue)
    }

    convertFrom(viewModelValue, bindingContext) {
      return JSON.parse(viewModelValue || this.defaultJson)
    }
  
    convertTo(viewValue, bindingContext) {
      return JSON.stringify(viewValue)
    }
}
  