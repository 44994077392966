import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { useProperty } from 'eilmer-mvvm/react'

import * as Core from '@coreui/react'

export function DataTable({entities, fields, scopedSlots}) {
  const [searchParams, setSearchParams] = useSearchParams()
  const activePage = Number(searchParams.get('page') || '1')
  const [pages, setPages] = React.useState(1)

  const handlePagesChange = (pages) => {
    setPages(pages)
  }

  const handlePageChange = (page) => {
    if (page !== 1) {
      searchParams.set('page', page)
    } else {
      searchParams.delete('page')
    }
    setSearchParams(searchParams)
  }

  return (
    <>
      <Core.CDataTable
        items={entities}
        fields={fields}
        loading={!entities}
        tableFilter
        cleaner
        itemsPerPageSelect
        itemsPerPage={10}
        hover
        sorter
        activePage={activePage}
        onPagesChange={handlePagesChange}
        scopedSlots={scopedSlots}
      />
      {pages > 1 &&
        <Core.CPagination
          activePage={activePage}
          onActivePageChange={handlePageChange}
          pages={pages}
        />
      }
    </>
  )
}

export function EntityDropdown({entityViewModel, property = 'entities', converter, innerRef, required, nullLabel, value, onChange}) {
  const entities = useProperty(entityViewModel, property, converter)

  const handleChange = (e) => {
    onChange({target:{value:e.target.value || null}})
  }

  React.useEffect(() => {
    const entity = entities?.slice(0, 1)[0]
    if (required && entity && !value) {
      onChange({target:{value:entity.getPk()}})
    } else if (value && entity && !entities.find(entity => entity.getPk() == value)) {
      // Note that the above comparison "==" is deliberately not "==="
      // TODO: WHAT IF IT'S NOT REQUIRED? NEEDS TO CHANGE TO NULL
      onChange({target:{value:entity.getPk()}})
    }
  }, [value, entities])

  return (
    <Core.CSelect innerRef={innerRef} custom required={required} value={value || ''} onChange={handleChange}>
      {!required && <option value="">&lt;{(nullLabel || 'EMPTY').toUpperCase()}&gt;</option>}
      {entities?.map(entity => (
        <option key={entity.getPk()} value={entity.getPk()}>{entity.name}</option>
      ))}
    </Core.CSelect>
  )
}

