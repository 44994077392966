import React from 'react'
import { Link } from 'react-router-dom'
import { useProperty, useBinding, useInstanceOf } from 'eilmer-mvvm/react'

import * as Core from '@coreui/react'
import CIcon from '@coreui/icons-react'

import UserRolesViewModel from '#viewmodel/UserRolesViewModel'
import UserRolesConverter from '#converters/UserRolesConverter'

import { useAuthContext } from '#context/AuthContext'

import * as Forms from '#components/widgets/Forms'
import * as Entities from '#components/widgets/Entities'

export default function UserRoles({ appViewModel }) {
  appViewModel.breadcrumb = 'Admin / User Roles'
  const viewModel = UserRolesViewModel.useInstance()

  const handleAddEntity = () => {
    viewModel.doEditEntity(null)
  }

  return (
    <Core.CCard style={{display: 'flex', flex: '1 1 0%'}}>
      <Core.CCardHeader>
        <div className="card-header-actions">
          <Core.CButton color="primary" size="sm" onClick={() => handleAddEntity()}>
            <CIcon name="cil-library-add"/>&nbsp;Add Role
          </Core.CButton>
        </div>
      </Core.CCardHeader>
      <Core.CCardBody>
        <EntityDataTable viewModel={viewModel}/>
      </Core.CCardBody>
      <EditItemModal entityName="Role" viewModel={viewModel}/>
    </Core.CCard>
  )
}

function EntityDataTable({ viewModel }) {
  const permissions = useProperty(useAuthContext(), 'permissions')
  const converter = useInstanceOf(UserRolesConverter, permissions)
  const entities = useProperty(viewModel, 'entities', converter)
  
  const handleEditEntity = (entity) => {
    viewModel.doEditEntity(entity)
  }

  const handleDeleteEntity = (entity) => {
    viewModel.doDeleteEntity(entity)
  }

  return (
    <Entities.DataTable entities={entities} fields={viewModel.fields} scopedSlots={{
      'actions':
        (entity)=>{
          const editable = permissions.hasPrecedence(entity)
          return (
            <td style={{textAlign:'right'}}>
              <a href={editable ? '#' : null} disabled={!editable} onClick={() => editable && handleEditEntity(entity)}>
                <CIcon className="mr-2" title="Edit" name="cil-pencil"/>
              </a>
              {editable &&
                <Link disabled={!editable} to={`/user_role_permissions/${entity.getPk()}`}>
                  <CIcon className="mr-2" title="Permissions" name="cil-storage"/>
                </Link>
              }
              {!editable &&
                <a disabled={true}>
                  <CIcon className="mr-2" title="Permissions" name="cil-storage"/>
                </a>
              }
              <a href={editable ? '#' : null} disabled={!editable} onClick={() => editable && handleDeleteEntity(entity)}>
                <CIcon title="Delete" name="cil-trash"/>
              </a>
            </td>
          )
        },
    }}/>
  )
}

function EditItemModal({ viewModel, entityName }) {
  const open = useProperty(viewModel, 'editing')
  const [ name, setName ] = useBinding(viewModel.entity, 'name')
  const [ description, setDescription ] = useBinding(viewModel.entity, 'description')
  const [ precedence, setPrecedence ] = useBinding(viewModel.entity, 'precedence')

  const handleCancel = () => {
    viewModel.doCancelEdit()
  }

  const handleSave = () => {
    viewModel.doSaveEntity(viewModel.entity)
  }

  return (
    <Core.CModal centered show={open}>
      <Forms.ValidatingForm onSubmit={handleSave} onReset={handleCancel}>
        <Core.CModalHeader>Add/Edit {entityName}</Core.CModalHeader>
        <Core.CModalBody>
          <Core.CFormGroup row>
            <Core.CCol md="3">
              <Core.CLabel>Name</Core.CLabel>
            </Core.CCol>
            <Core.CCol xs="12" md="9">
              <Forms.ValidatedFormControl>
                {({ ref }) => (
                  <Core.CInput innerRef={ref} required type="text" value={name || ''} onChange={e => setName(e.target.value)}/>
                )}
              </Forms.ValidatedFormControl>
              <small><em>For internal use to identify this {entityName}</em></small>
            </Core.CCol>
          </Core.CFormGroup>
          <Core.CFormGroup row>
            <Core.CCol md="3">
              <Core.CLabel>Description</Core.CLabel>
            </Core.CCol>
            <Core.CCol xs="12" md="9">
              <Forms.ValidatedFormControl>
                {({ ref }) => (
                  <Core.CInput innerRef={ref} required type="text" value={description || ''} onChange={e => setDescription(e.target.value)}/>
                )}
              </Forms.ValidatedFormControl>
              <small><em>For internal use to describe this {entityName}</em></small>
            </Core.CCol>
          </Core.CFormGroup>
          <Core.CFormGroup row>
            <Core.CCol md="3">
              <Core.CLabel>Precedence</Core.CLabel>
            </Core.CCol>
            <Core.CCol xs="12" md="9">
              <Forms.ValidatedFormControl>
                {({ ref }) => (
                  <Core.CInput innerRef={ref} required type="number" value={precedence || 0} onChange={e => setPrecedence(e.target.value)}/>
                )}
              </Forms.ValidatedFormControl>
              <small><em>Users can only manage other users with a higher precedence value</em></small>
            </Core.CCol>
          </Core.CFormGroup>
        </Core.CModalBody>
        <Core.CModalFooter>
          <Core.CButton color="secondary" type="reset">Cancel</Core.CButton>
          &nbsp;
          <Core.CButton color="primary" type="submit">Save</Core.CButton>
        </Core.CModalFooter>
      </Forms.ValidatingForm>
    </Core.CModal>
  )
}
