import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { useInstanceOf, useProperty, useBinding } from 'eilmer-mvvm/react'

import * as Core from '@coreui/react'
import CIcon from '@coreui/icons-react'

import * as Layout from '#components/misc/DynamicLayout'
import * as Content from '#components/widgets/Content'

import { useAppViewModel } from '#context/AppContext'
import { useLocalState } from '#utils/Hooks'

import ContentViewModel from '#viewmodel/ContentViewModel'

const WEBSITE_ID = 1

const BLOCK_COMPONENTS = {
  1: Content.Text,
  2: Content.Image,
  3: Content.TextImage,
  4: Content.ImageImage,
  5: Content.YouTubeVideo,
  6: Content.Button,
  7: Content.ItemList,
  8: Content.VimeoVideo,
  9: Content.Carousel,
  10: Content.Form,
  11: Content.Blog,
  12: Content.Faq,
  13: Content.Divider,
  14: Content.Events,
  15: Content.Card,
  16: Content.Collapse,
  17: Content.Login,
  18: Content.Html,
  19: Content.Gallery,
  20: Content.Item,
}

export default function EditContent({ appViewModel }) {
  const { item_id, item_type_id } = useParams()
  const editorRef = React.useRef()
  const contentViewModel = ContentViewModel.useInstance(
    item_id,
    item_type_id,
    WEBSITE_ID
  )
  const itemType = useProperty(contentViewModel, 'itemType')
  const item = useProperty(contentViewModel, 'item')
  appViewModel.breadcrumb = 'Items / ' + itemType?.name + ' / Compose'

  return (
    <>
      <Core.CRow>
        <Core.CCol>
          <Core.CCard>
            <Core.CCardBody className="p-3">
              <div className="d-inline-block mr-3">
                <Core.CButton
                  color="secondary"
                  onClick={() => window.history.go(-1)}
                >
                  Back
                </Core.CButton>
              </div>
              <div className="d-inline-block">
                <h4>{item && `${item.name} (${item.description})`}</h4>
              </div>
            </Core.CCardBody>
          </Core.CCard>
        </Core.CCol>
      </Core.CRow>
      <Core.CRow style={{ display: 'flex', flex: 1 }}>
        <Core.CCol md="2">
          <ContentBlocks
            editorRef={editorRef}
            contentViewModel={contentViewModel}
          />
        </Core.CCol>
        <Core.CCol md="10" style={{ display: 'flex', flex: 1 }}>
          <ContentEditor ref={editorRef} contentViewModel={contentViewModel} />
        </Core.CCol>
      </Core.CRow>
    </>
  )
}

function ContentBlocks({ contentViewModel, editorRef }) {
  const blocks = useProperty(contentViewModel, 'blocks')

  return (
    <Core.CCard>
      <Core.CCardHeader>Blocks</Core.CCardHeader>
      <Core.CCardBody className="p-0">
        <Core.CSidebar
          colorScheme="light"
          fixed={false}
          style={{ display: 'flex', flex: 1, width: '100%', border: 'none' }}
        >
          <Core.CSidebarNav>
            {Object.entries(blocks).map(([name, group], idx) => (
              <Core.CSidebarNavDropdown key={idx} name={name}>
                <div className="px-3">
                  {group.map((block, idx) => (
                    <ContentBlockType
                      key={idx}
                      blockType={block}
                      editorRef={editorRef}
                      contentViewModel={contentViewModel}
                    />
                  ))}
                </div>
              </Core.CSidebarNavDropdown>
            ))}
          </Core.CSidebarNav>
        </Core.CSidebar>
      </Core.CCardBody>
    </Core.CCard>
  )
}

export const ContentEditor = React.forwardRef(function (
  { contentViewModel },
  editorRef
) {
  const ref = editorRef
  const appViewModel = useAppViewModel()
  const website = useProperty(appViewModel, 'website')
  const layout = useProperty(contentViewModel, 'items')
  const previewRef = React.useRef()

  const onPreview = (_items) => {
    const items = _items ?? ref.current.getItems()
    previewRef.current.preview(items.length ? items : layout)
  }

  const onSaveLayout = async () => {
    const items = ref.current.getItems()
    await contentViewModel.doSave(items)
    appViewModel.unsavedChanges = false
  }

  const onDeleteAll = async () => {
    editorRef.current.removeAll()
    previewRef.current.preview([])
  }

  const onLayoutChange = (items) => {
    console.log('Setting unsaved changes')
    appViewModel.unsavedChanges = true
    onPreview(items)
  }

  const onItemDrop = (e, itemLayout) => {
    const blockType = JSON.parse(e.dataTransfer.getData('application/x-item'))
    const item = contentViewModel.newItem(blockType)
    item.layout = itemLayout
    editorRef.current.addItem(item)
  }

  const ItemComponent = ContentBlock(onPreview)
  React.useEffect(() => {
    if (website && layout?.length) onPreview()
  }, [website, layout])

  return (
    <Core.CTabs activeTab={'edit'}>
      <Core.CCard style={{ display: 'flex', flex: 1 }}>
        <Core.CCardHeader>
          <div className="card-header-actions">
            <Core.CNav variant="pills">
              <Core.CNavItem>
                <Core.CNavLink data-tab="edit">Edit</Core.CNavLink>
              </Core.CNavItem>
              <Core.CNavItem>
                <Core.CNavLink data-tab="preview">Preview</Core.CNavLink>
              </Core.CNavItem>
            </Core.CNav>
          </div>
        </Core.CCardHeader>
        <Core.CCardBody
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            padding: 0,
            position: 'relative',
          }}
        >
          <Core.CTabContent className="pt-3">
            <Core.CTabPane
              data-tab="edit"
              style={{
                display: 'block',
                position: 'absolute',
                padding: '20px 35px 20px 20px',
                top: 0,
                bottom: 0,
                left: 0,
                overflowY: 'auto',
                overflowX: 'hidden',
                width: '100%',
              }}
            >
              <Layout.DynamicLayout
                ref={ref}
                cols={1}
                defaultItems={layout}
                itemHeight={62}
                itemComponent={ItemComponent}
                onItemDrop={onItemDrop}
                onChange={onLayoutChange}
              />
            </Core.CTabPane>
            <Core.CTabPane
              data-tab="preview"
              style={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                width: '100%',
              }}
            >
              <ContentEditorPreview ref={previewRef} website={website} />
            </Core.CTabPane>
          </Core.CTabContent>
        </Core.CCardBody>
        <Core.CCardFooter>
          <ContentEditorControls
            onSave={onSaveLayout}
            onDeleteAll={onDeleteAll}
          />
        </Core.CCardFooter>
      </Core.CCard>
    </Core.CTabs>
  )
})

const ContentEditorPreview = React.forwardRef(function (
  { website },
  previewRef
) {
  const iframe = React.useRef()
  const timeoutId = React.useRef()
  const [mode, setMode] = useLocalState('editMode', 'desktop')
  const schema = website?.hostname.includes(':')
    ? window.location.protocol
    : 'https:'
  const url = website ? `${schema}//${website.hostname}/preview.php` : null

  const modeProps = {
    mobile: { width: 420, maxWidth: '100%' },
    tablet: { width: 768, maxWidth: '100%' },
    desktop: { width: '100%', minWidth: 992, maxWidth: '100%' },
  }

  previewRef.current = {
    preview: (items) => {
      if (website?.hostname && items) {
        // If the hostname doesnt contain a port number, assume https
        const preview = items
          .slice()
          .sort((a, b) => a.layout?.y - b.layout?.y)
          .map((item) => {
            return {
              block_type: item.getFieldValue(9, 'int_value'),
              is_col: item.getFieldValue(79, 'bool_value'),
              properties: JSON.stringify(item.properties),
            }
          })
        clearTimeout(timeoutId.current)
        timeoutId.current = setTimeout(() => {
          try {
            iframe.current.contentWindow.postMessage(
              {
                content: JSON.stringify(preview),
              },
              url
            )
          } catch (err) {
            console.error(JSON.stringify(preview))
            console.error(err)
          }
        }, 500)
      }
    },
  }

  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <div className="bg-dark text-light w-100 p-2">
        <div>
          <Core.CButtonGroup>
            <Core.CButton
              color="secondary"
              size="sm"
              onClick={() => setMode('mobile')}
              style={{ opacity: mode === 'mobile' ? '0.75' : '' }}
            >
              <CIcon name="cis-screen-smartphone" />
            </Core.CButton>
            <Core.CButton
              color="secondary"
              size="sm"
              onClick={() => setMode('tablet')}
              style={{ opacity: mode === 'tablet' ? '0.75' : '' }}
            >
              <CIcon name="cis-tablet" />
            </Core.CButton>
            <Core.CButton
              color="secondary"
              size="sm"
              onClick={() => setMode('desktop')}
              style={{ opacity: mode === 'desktop' ? '0.75' : '' }}
            >
              <CIcon name="cis-monitor" />
            </Core.CButton>
          </Core.CButtonGroup>
        </div>
      </div>
      <div
        style={{
          height: '100%',
          overflowX: 'auto',
          overflowY: 'hidden',
          background: 'var(--secondary)',
          display: 'grid',
          justifyItems: 'center',
        }}
      >
        {url && (
          <iframe
            ref={iframe}
            src={url}
            style={{ ...modeProps[mode], height: '100%', border: 0 }}
          ></iframe>
        )}
      </div>
    </div>
  )
})

function ContentEditorControls({ onSave, onDeleteAll }) {
  const appViewModel = useAppViewModel()
  const changed = useProperty(appViewModel, 'unsavedChanges')

  return (
    <>
      <Core.CButton color="success" disabled={!changed} onClick={onSave}>
        <CIcon name="cil-save" /> Save Changes
      </Core.CButton>
      <Core.CButton
        className="float-right"
        color="danger"
        onClick={onDeleteAll}
      >
        Delete All
      </Core.CButton>
    </>
  )
}

function ContentBlock(previewHandler) {
  return function ContentBlock({ item, removeItemHandler, updateItemHandler }) {
    const Component = BLOCK_COMPONENTS[item.getFieldValue(9, 'int_value')]
    const description = useProperty(item, 'description')
    const name = useProperty(item, 'name')

    return (
      <Layout.CollapsibleGridCard
        colour="primary"
        item={item}
        title={name}
        subtitle={description}
        updateItem={updateItemHandler}
      >
        {Component && (
          <Component item={item} onChange={() => previewHandler()} />
        )}
        <hr />
        <Core.CButton color="danger" size="sm" onClick={removeItemHandler}>
          Delete Block
        </Core.CButton>
      </Layout.CollapsibleGridCard>
    )
  }
}

function ContentBlockType({ blockType, editorRef, contentViewModel }) {
  const onDragStart = (e) => {
    e.dataTransfer.setData('application/x-item', JSON.stringify(blockType))
  }

  const addItem = () => {
    const item = contentViewModel.newItem(blockType)
    editorRef.current.addItem(item)
  }

  return (
    <Core.CCallout
      draggable="true"
      onDragStart={onDragStart}
      color="primary"
      className={'bg-gray-200 text-muted p-2'}
      style={{ cursor: 'move', textAlign: 'right' }}
    >
      <span className="float-left">{blockType.name}</span>
      <Core.CButton
        size="sm"
        color="primary"
        style={{ padding: '0.15rem 0.3rem' }}
        onClick={addItem}
      >
        <CIcon name="cil-arrow-thick-right" />
      </Core.CButton>
    </Core.CCallout>
  )
}
