import UserRolePermissionsModel from '#model/UserRolePermissionsModel'
import UserRolesModel from '#model/UserRolesModel'
import { useApiClient } from '#context/ApiContext'
import { useNotifications } from '#context/NotificationContext'
import { useInstanceOf } from 'eilmer-mvvm/react'

export default class UserRolePermissionsViewModel {

  constructor(apiClient, notificationsProvider, userRoleId) {
    this.model = new UserRolePermissionsModel(apiClient)
    this.userRolesModel = new UserRolesModel(apiClient)
    this.notifications = notificationsProvider
    this.userRoleId = Number(userRoleId)
    this.entity = new UserRolePermissionsModel.UserRolePermission()
    this.editing = false
    this.loadEntities()
  }

  async loadEntities() {
    try {
      this.userRole = await this.userRolesModel.getUserRole(this.userRoleId)
      this.entities = await this.model.getUserRolePermissions(this.userRoleId)
    } catch (err) {
      console.error(err)
      this.notifications.error(err.message)
    }
  }

  doEditEntity(entity) {
    const editingEntity = entity || {user_role_id: this.userRoleId}
    this.entity = new UserRolePermissionsModel.UserRolePermission(editingEntity)
    this.editing = true
  }

  doCancelEdit() {
    this.editing = false
  }

  async doSaveEntity(entity) {
    try {
      if (entity.isNew()) {
        const result = await this.model.createUserRolePermission(entity)
        this.editing = false
        this.loadEntities()
        return result
      } else {
        const result = await this.model.updateUserRolePermission(entity)
        this.editing = false
        this.loadEntities()
        return result
      }
    } catch (err) {
      console.error(err)
      this.notifications.error(err.message)
    }
  }

  async doDeleteEntity(entity) {
    if (await this.notifications.confirm('Are you sure you\'d like to delete this item?')) {
      try {
        const result = await this.model.deleteUserRolePermission(entity)
        this.loadEntities()
        return result
      } catch (err) {
        console.error(err)
        this.notifications.error(err.message)
      }
    }
  }

  get fields() {
    return [
      { key: 'user_role_permission_id', label: "ID", _style: { width: '6%'}, _classes: 'bg-light text-dark' },
      { key: 'privilege', _style: { width: '25%'} },
      { key: 'description' },
      {
        key: 'actions',
        label: '',
        _style: { width: '90px' },
        filter: false
      }
    ]
  }

}

UserRolePermissionsViewModel.useInstance = function(userRoleId) {
  return useInstanceOf(UserRolePermissionsViewModel, useApiClient(), useNotifications(), userRoleId)
}
