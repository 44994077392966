import TreeQLModel from './TreeQLModel'

export default class TypesModel extends TreeQLModel {

  constructor(apiClient) {
    super(apiClient)
  }

  async getTypes() {
    return (await super.list('types')).map(each => {
      return new TypesModel.Type(each)
    })
  }

  async createType(type) {
    const entity = new TypesModel.Type(type)
    return await super.create('types', entity)
  }

  async updateType(type) {
    const entity = new TypesModel.Type(type, true)
    return await super.update('types', entity.getPk(), entity)
  }

  async deleteType(type) {
    const entity = new TypesModel.Type(type)
    return await super.delete('types', entity.getPk())
  }

}

TypesModel.Type = class extends TreeQLModel.Entity {

  constructor(fields, forUpdate) {
    super(fields, 'type_id', forUpdate)
  }

}
