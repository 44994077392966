import React from 'react'
import { useProperty, useBinding, useInstanceOf } from 'eilmer-mvvm/react'

import * as Core from '@coreui/react'
import CIcon from '@coreui/icons-react'

import UsersViewModel from '#viewmodel/UsersViewModel'
import UserRolesViewModel from '#viewmodel/UserRolesViewModel'
import UserRolesConverter from '#converters/UserRolesConverter'
import UsersConverter from '#converters/UsersConverter'

import { useAuthContext } from '#context/AuthContext'

import * as User from '#components/widgets/User'
import * as Forms from '#components/widgets/Forms'
import * as Entities from '#components/widgets/Entities'

export default function Users({ appViewModel }) {
  appViewModel.breadcrumb = 'Admin / Users'
  const viewModel = UsersViewModel.useInstance()

  const handleAddEntity = () => {
    viewModel.doEditEntity(null)
  }

  return (
    <Core.CCard style={{display: 'flex', flex: '1 1 0%'}}>
      <Core.CCardHeader>
        <div className="card-header-actions">
          <Core.CButton color="primary" size="sm" onClick={() => handleAddEntity()}>
            <CIcon name="cil-library-add"/>&nbsp;Add User
          </Core.CButton>
        </div>
      </Core.CCardHeader>
      <Core.CCardBody>
        <EntityDataTable viewModel={viewModel}/>
      </Core.CCardBody>
      <EditEntityModal entityName="User" viewModel={viewModel}/>
      <SetPasswordModal viewModel={viewModel}/>
    </Core.CCard>
  )
}

function EntityDataTable({ viewModel }) {
  const permissions = useProperty(useAuthContext(), 'permissions')
  const converter = useInstanceOf(UsersConverter, permissions)
  const entities = useProperty(viewModel, 'entities', converter)
  const user = useProperty(useAuthContext(), 'user')

  const handleEditEntity = (entity) => {
    viewModel.doEditEntity(entity)
  }

  const handleDeleteEntity = (entity) => {
    viewModel.doDeleteEntity(entity)
  }

  const handleSetPassword = (entity) => {
    viewModel.doSetPassword(entity)
  }

  return (
    <Entities.DataTable entities={entities} fields={viewModel.fields} scopedSlots={{
      'role':
      (entity)=>(
        <td>
          {entity.getUserRole().name}
        </td>
      ),
      'actions':
        (entity)=>{
          const editable = permissions.hasPrecedence(entity.getUserRole()) || user.user_id === entity.user_id
          return (
            <td style={{textAlign:'right'}}>
              <a href={editable ? '#' : null} disabled={!editable} onClick={() => editable && handleEditEntity(entity)}>
                <CIcon className="mr-2" title="Edit" name="cil-pencil"/>
              </a>
              <a href={editable ? '#' : null} disabled={!editable} onClick={() => editable && handleSetPassword(entity)}>
                <CIcon className="mr-2" title="Set Password" name="cil-lock-unlocked"/>
              </a>
              <a href={editable ? '#' : null} disabled={!editable} onClick={() => editable && handleDeleteEntity(entity)}>
                <CIcon title="Delete" name="cil-trash"/>
              </a>
            </td>
          )
        },
    }}/>
  )
}

function SetPasswordModal({ viewModel }) {
  const open = useProperty(viewModel, 'settingPassword')
  const [ newPassword, setNewPassword ] = useBinding(viewModel, 'newPassword')
  const [ confirmPassword, setConfirmPassword ] = useBinding(viewModel, 'confirmPassword')
  const confirmRef = React.useRef()

  const handleNewChange = (e) => {
    const element = e.target, failed = element.value !== newPassword
    confirmRef.current.setCustomValidity(failed ? 'Passwords do not match' : '')
    setNewPassword(element.value)
  }

  const handleConfirmChange = (e) => {
    const element = e.target, failed = element.value !== newPassword
    element.setCustomValidity(failed ? 'Passwords do not match' : '')
    setConfirmPassword(element.value)
  }

  const handleCancel = () => {
    viewModel.doCancelSetPassword()
  }

  const handleSave = () => {
    viewModel.doSavePassword(viewModel.entity, newPassword)
  }

  return (
    <Core.CModal centered closeOnBackdrop={false} show={open}>
      <Forms.ValidatingForm onSubmit={handleSave} onReset={handleCancel}>
        <Core.CModalHeader>Set Password</Core.CModalHeader>
        <Core.CModalBody>
          <Core.CFormGroup row>
            <Core.CCol md="3">
              <Core.CLabel>Password</Core.CLabel>
            </Core.CCol>
            <Core.CCol xs="12" md="9">
              <Forms.ValidatedFormControl>
                {({ ref }) => (
                  <Core.CInput innerRef={ref} required type="password" value={newPassword} onChange={handleNewChange}/>
                )}
              </Forms.ValidatedFormControl>
            </Core.CCol>
          </Core.CFormGroup>
          <Core.CFormGroup row>
            <Core.CCol md="3">
              <Core.CLabel>Confirm Password</Core.CLabel>
            </Core.CCol>
            <Core.CCol xs="12" md="9">
              <Forms.ValidatedFormControl innerRef={confirmRef}>
                {({ ref }) => (
                  <Core.CInput innerRef={ref} required type="password" value={confirmPassword} onChange={handleConfirmChange}/>
                )}
              </Forms.ValidatedFormControl>
            </Core.CCol>
          </Core.CFormGroup>
        </Core.CModalBody>
        <Core.CModalFooter>
          <Core.CButton color="secondary" type="reset">Cancel</Core.CButton>
          &nbsp;
          <Core.CButton color="primary" type="submit">Save</Core.CButton>
        </Core.CModalFooter>
      </Forms.ValidatingForm>
    </Core.CModal>
  )
}

function EditEntityModal({ viewModel, entityName }) {
  const open = useProperty(viewModel, 'editing')
  const [ name, setName ] = useBinding(viewModel.entity, 'user_name')
  const [ emailAddress, setEmailAddress ] = useBinding(viewModel.entity, 'email_address')
  const [ userRoleId, setUserRoleId ] = useBinding(viewModel.entity, 'user_role_id')
  const [ isActive, setIsActive ] = useBinding(viewModel.entity, 'active')
  const fields = useProperty(viewModel, 'userFields')

  const handleCancel = () => {
    viewModel.doCancelEdit()
  }

  const handleSave = () => {
    viewModel.doSaveEntity(viewModel.entity)
  }

  return (
    <Core.CModal centered closeOnBackdrop={false} show={open}>
      <Forms.ValidatingForm onSubmit={handleSave} onReset={handleCancel}>
        <Core.CModalHeader>Add/Edit {entityName}</Core.CModalHeader>
        <Core.CModalBody>
          <Core.CFormGroup row>
            <Core.CCol md="3">
              <Core.CLabel>User Role</Core.CLabel>
            </Core.CCol>
            <Core.CCol xs="12" md="9">
              <UserRoleDropdown required value={userRoleId || ''} onChange={e => setUserRoleId(e.target.value)}/>
              <small><em>Determines the users permissions</em></small>
            </Core.CCol>
          </Core.CFormGroup>
          <Core.CFormGroup row>
            <Core.CCol md="3">
              <Core.CLabel>Email Address</Core.CLabel>
            </Core.CCol>
            <Core.CCol xs="12" md="9">
              <Forms.ValidatedFormControl>
                {({ ref }) => (
                  <Core.CInput innerRef={ref} required type="email" value={emailAddress || ''} onChange={e => setEmailAddress(e.target.value)}/>
                )}
              </Forms.ValidatedFormControl>
              <small><em>Identifies the user - must be unique</em></small>
            </Core.CCol>
          </Core.CFormGroup>
          <Core.CFormGroup row>
            <Core.CCol md="3">
              <Core.CLabel>Name</Core.CLabel>
            </Core.CCol>
            <Core.CCol xs="12" md="9">
              <Forms.ValidatedFormControl>
                {({ ref }) => (
                  <Core.CInput innerRef={ref} required type="text" value={name || ''} onChange={e => setName(e.target.value)}/>
                )}
              </Forms.ValidatedFormControl>
              <small><em>The users display name</em></small>
            </Core.CCol>
          </Core.CFormGroup>
          <Core.CFormGroup row>
            <Core.CCol md="3">
              <Core.CLabel>Active</Core.CLabel>
            </Core.CCol>
            <Core.CCol xs="12" md="9">
              <Core.CSwitch shape="pill" color="primary" checked={!!isActive} onChange={e => setIsActive(e.target.checked)}/>
              <div><small><em>Inactive users cannot log in</em></small></div>
            </Core.CCol>
          </Core.CFormGroup>
          <User.UserFieldsEditor user={viewModel.entity} fields={fields} changedHandler={null} vertical={false}/>
        </Core.CModalBody>
        <Core.CModalFooter>
          <Core.CButton color="secondary" type="reset">Cancel</Core.CButton>
          &nbsp;
          <Core.CButton color="primary" type="submit">Save</Core.CButton>
        </Core.CModalFooter>
      </Forms.ValidatingForm>
    </Core.CModal>
  )
}

function UserRoleDropdown({ required, value, onChange }) {
  const viewModel = UserRolesViewModel.useInstance()
  const permissions = useProperty(useAuthContext(), 'permissions')
  const converter = useInstanceOf(UserRolesConverter, permissions)
  return (
    <Entities.EntityDropdown required={required} value={value} onChange={onChange} entityViewModel={viewModel} converter={converter}/>
  )
}
