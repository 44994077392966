import React from 'react'
import { useInstanceOf, useBinding, useProperty } from 'eilmer-mvvm/react'
import * as Core from '@coreui/react'

// TODO: I'm only importing this for the converter -
// find a better place for it, dunno - I am using elsewhere now
import ItemsViewModel from '#viewmodel/ItemsViewModel'
import ItemTypeFieldsViewModel from '#viewmodel/ItemTypeFieldsViewModel'
import FieldValuesConverter from '#converters/FieldValuesConverter'

import { useAuthContext } from '#context/AuthContext'

import * as Forms from '#components/widgets/Forms'
import * as Fields from '#components/widgets/Fields'
import * as Entities from '#components/widgets/Entities'

import ItemClasses from '#primitives/ItemClasses'

// The itemTypeId for media items
// TODO: THIS DOESN'T BELONG HERE
const MEDIA_ITEM_TYPE_ID = 8

export const EditItemModal = function({ viewModel, title, useParentItem = true }) {
  const open = useProperty(viewModel, 'editing')
  const fields = useProperty(viewModel, 'itemTypeFields')
  const itemType = useProperty(viewModel, 'itemType')
  const parentItem = useProperty(viewModel, 'parentItem')
  const isContentType = ItemClasses.CONTENT === itemType?.item_class_id

  const handleCancel = () => {
    viewModel.doCancelEdit()
  }

  const handleSave = (e) => {
    const edit = e.submitter.value === 'edit'
    viewModel.doSaveItem(viewModel.item, edit)
  }

  return (
    <Core.CModal centered closeOnBackdrop={false} show={open} size='lg'>
      <Forms.ValidatingForm onSubmit={handleSave} onReset={handleCancel}>
        <Core.CModalHeader>{title}</Core.CModalHeader>
        <Core.CModalBody style={{maxHeight:'60vh',overflowY:'auto'}}>
          <ItemFieldsEditor item={viewModel.item} parentItem={useParentItem && parentItem} itemType={itemType} itemTypeFields={fields}/>
        </Core.CModalBody>
        <Core.CModalFooter>
          <Core.CButton color="secondary" type="reset">Cancel</Core.CButton>
          &nbsp;
          <Core.CButton color="primary" type="submit" value="save">Save</Core.CButton>
          {isContentType && !viewModel.item.getPk() && <>
            &nbsp;
            <Core.CButton color="primary" type="submit" value="edit">Save and Edit</Core.CButton>
          </>}
        </Core.CModalFooter>
      </Forms.ValidatingForm>
    </Core.CModal>
  )
}

export const ItemEditor = function({ item, vertical, typeFieldsOnly, itemTypeId, changedHandler }) {
  const viewModel = ItemTypeFieldsViewModel.useInstance(itemTypeId || item.item_type_id)
  const itemTypeFields = useProperty(viewModel, 'entities')
  const itemType = useProperty(viewModel, 'itemType')
  const parentItem = item.parent_item_id
  // const parentItem = item.item_id

  if (typeFieldsOnly) {
    return (
      <ItemTypeFieldsEditor item={item} vertical={vertical} itemType={itemType} itemTypeFields={itemTypeFields} changedHandler={changedHandler}/>
    )
  } else {
    return (
      <ItemFieldsEditor item={item} parentItem={parentItem} vertical={vertical} itemType={itemType} itemTypeFields={itemTypeFields} changedHandler={changedHandler}/>
    )
  }
}

// Ok so 1: ability to do this vertically.
// I sometimes (like with settings) only want to edit the item type fields
// not the name and so on.

export const ItemFieldsEditor = function({ item, parentItem, itemType, itemTypeFields, changedHandler, vertical, children }) {
  const [ name ] = useBinding(item, 'name')
  const [ description ] = useBinding(item, 'description')
  const [ parentItemId, setParentItemId ] = useBinding(item, 'parent_item_id')
  const instance = React.useRef(), defltDescription = React.useRef()

  if (instance.current !== item) {
    instance.current = item
    defltDescription.current = item.description
  }

  const notifyChangedHandler = () => {
    if (typeof changedHandler === 'function') {
      changedHandler()
    }
  }

  const onFieldChanged = (handler) => {
    return (e) => {
      notifyChangedHandler()
      handler(e)
    }
  }

  const setName = (name) => {
    item.name = name
    if (!defltDescription.current) {
      item.description = name
    }
  }

  const setDescription = (description) => {
    item.description = description
    defltDescription.current = description
  }

  return (
    <>
      <Forms.FormGroup label="Name" vertical={vertical}>
        <Forms.ValidatedFormControl>
          {({ ref }) => (
            <Core.CInput innerRef={ref} required type="text" value={name || ''} onChange={onFieldChanged(e => setName(e.target.value))}/>
          )}
        </Forms.ValidatedFormControl>
        <small><em>For internal use to identify this item</em></small>
      </Forms.FormGroup>
      <Forms.FormGroup label="Description" vertical={vertical}>
        <Core.CInput type="text" value={description || ''} onChange={onFieldChanged(e => setDescription(e.target.value))}/>
        <small><em>For internal use to describe this item</em></small>
      </Forms.FormGroup>
      {itemType?.parent_type_id && !parentItem &&
        <Forms.FormGroup label={itemType.getParentItemType().name} vertical={vertical}>
          <ItemInput websiteId={item.website_id} itemTypeId={itemType.parent_type_id} value={parentItemId} onChange={onFieldChanged(e => setParentItemId(e.target.value))}/>
          <small><em>{itemType.getParentItemType().description}</em></small>
        </Forms.FormGroup>
      }
      <ItemTypeFieldsEditor item={item} itemTypeFields={itemTypeFields} changedHandler={notifyChangedHandler} vertical={vertical}/>
      {children}
    </>
  )
}

export const ItemTypeFieldsEditor = function({ item, itemTypeFields, changedHandler, vertical, children }) {
  const user = useProperty(useAuthContext(), 'user')
  const converter = useInstanceOf(FieldValuesConverter)
  const [ fieldValues, setFieldValues ] = useBinding(item, 'content_item_values', converter)

  const notifyChangedHandler = () => {
    if (typeof changedHandler === 'function') {
      changedHandler()
    }
  }

  const onItemTypeFieldChanged = () => {
    notifyChangedHandler()
    setFieldValues({...fieldValues})
  }

  return (
    <>
      {itemTypeFields?.filter(each => !each.internal).map((itemTypeField) => {
        const visible = !itemTypeField.getUserRole() || user.getUserRole().precedence <= itemTypeField.getUserRole().precedence
        if (visible) {
          return (
            <Forms.FormGroup key={itemTypeField.item_type_field_id} label={itemTypeField.name} vertical={vertical}>
              <Fields.Field item={item} viewModel={fieldValues} visible={true} websiteId={item.website_id} field={itemTypeField} onChange={onItemTypeFieldChanged}/>
              <div><small><em>{itemTypeField.description}</em></small></div>
            </Forms.FormGroup>
          )
          } else {
            return (
              <Fields.Field item={item} viewModel={fieldValues} visible={false} websiteId={item.website_id} field={itemTypeField} onChange={onItemTypeFieldChanged}/>
            )
          }
      })}
      {children}
    </>
  )
}

// TODO: Where should this be? Probably Inputs.Item
export function ItemInput({ innerRef, websiteId, itemTypeId, required, value, onChange }) {
  const viewModel = ItemsViewModel.useInstance(itemTypeId)
  return (
    <Entities.EntityDropdown innerRef={innerRef} required={required} value={value} onChange={onChange} entityViewModel={viewModel} property="items"/>
  )
}
